import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/auth-context";

// Components
import CheckBox from "../CheckBox/CheckBox";
import Avatar from "../Avatar/Avatar";

// icons
import Avatar1 from "../../assets/images/avatar.svg";

// Styles
import styles from "./AttendanceListItem.module.scss";
import { TeacherContext } from "../../context/teacher-context";

const AttendanceListModule = (props) => {
    const auth = useContext(AuthContext);

    let attendanceLabel, attendanceStyle;
    const [isPresent, setIsPresent] = useState(props.attendance);

    const [delay, setDelay] = useState(props.delay);
    
    useEffect(() => {
        if (props.status == 'suspendu' || props.status == 'exclu') {
            setIsPresent(false);
            setDelay(false);
        } else {
            setIsPresent(props.attendance);
            setDelay(props.delay);
        }
    }, [props.attendance, props.delay]);

    useEffect(() => {
        if (props.delay && (props.status !== 'suspendu' && props.status !== 'exclu')) {
            setIsPresent(true);
        }
    }, [props.attendance, props.delay]);

    if (props.listStatus) {
        if (isPresent) {
            attendanceStyle = styles.present_label;
            attendanceLabel = "Présent.e";
        } else {
            attendanceStyle = styles.absent_label;
            attendanceLabel = "Absent.e";
        }
    } else {
        attendanceStyle = styles.neutral_label;
        attendanceLabel = "À renseigner";
    }

    return (
        <section className={(props.status == 'suspendu' || props.status == 'exclu') ? styles.disabledContainer : styles.container}>
            {props.listStatus && (
                <div className={styles.checkboxContainer}>
                    <CheckBox
                        id={props.id}
                        key={props.id}
                        name={props.name}
                        value={props.checkboxValue}
                        isChecked={isPresent}
                        onChange={props.onChange}
                    />
                </div>
            )}
            <Avatar
            src={props.photo && props.photo !== 'no-photo.png' ? `${process.env.REACT_APP_API_HOST}/profile/get-my-picture?token=${auth.token}` : Avatar1}                
            alt="Profil Picture"
            />
            <p className={styles.name}>{props.name}</p>
            {(props.status == 'suspendu' || props.status == 'exclu') && (
                <div className={styles.warningLabel}>
                    <p className={styles.warning} >{props.status == 'suspendu' ? 'Étudiant suspendu : ' : 'Étudiant exclu : '}Envoyer à la pédagogie`</p>
                </div>
            )}
            <div className={`${styles.label} ${attendanceStyle}`}>
                <p className={styles.label_text}>{attendanceLabel}</p>
            </div>
            <div className={styles.checkboxDelayContainer}>
                <CheckBox
                    id={props.id}
                    key={`${props.id}-delay`}
                    name={`${props.name}-delay`}
                    isChecked={delay}
                    onChange={props.onDelayChange}
                />
            </div>
        </section>
    );
};

export default AttendanceListModule;
