import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../context/auth-context";
import { useHttpRequest } from "../../hooks/httpRequest-hook";
import { campusData } from "../../utils/dictionaries";

// Components
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import HttpMessagePrompt from "../../components/HttpMessagePrompt/HttpMessagePrompt";
import ProfileTabs from "../../components/Tabs/Student/Profile/ProfileTabs/ProfileTabs";
import SideCard from "../../components/Tabs/Student/Profile/SideCard/SideCard";
import MyProfile from "../../components/Tabs/Student/Profile/MyProfile/MyProfile";
import CivilState from "../../components/Tabs/Student/Profile/CivilState/CivilState";
import ContactDetails from "../../components/Tabs/Student/Profile/ContactDetails/ContactDetails";
import CvecDetails from "../../components/Tabs/Student/Profile/CvecDetails/CvecDetails";

// Styles
import styles from "./Profile.module.scss";

const Profile = () => {

    // Authentication context
    const auth = useContext(AuthContext);

    // Http request hook
    const { isLoading, error, sendRequest } = useHttpRequest();

    // Student Data
    const [student, setStudent] = useState();

    // Departments Data
    const [departmentsTags, setDepartmentsTags] = useState();

    // TabViews State
    const [tabViews, SetTabViews] = useState();

    // Navs
    const profileNav = [
        { id: "my_profile", btnText: "Mon profil", btnType: "text" },
        { id: "civil_state", btnText: "État civil", btnType: "text" },
        { id: "contact_details", btnText: "Coordonnées", btnType: "text" },
        { id: "cvec_details", btnText: "CVEC", btnType: "text" },
    ];

    useEffect(() => {
        const fetchStudent = async () => {
            try {
                const studentUrl = `${process.env.REACT_APP_API_STUDENT}/profile/profile`;
                const studentLinks = await sendRequest(studentUrl, "GET", null, {
                    Authorization: "Bearer " + auth.token,
                });
                
                // Find labels and format date
                studentLinks[0].place_id = campusData[studentLinks[0].place_id].mainLabel;
                studentLinks[0].birth_date = studentLinks[0].birth_date ? studentLinks[0].birth_date.split("/").reverse().join("-") : "";
                // Set State
                setStudent(studentLinks[0]);
                setDepartmentsTags(studentLinks[0].allDepartmentsTags);
            } catch (err) {
                console.error(err);
            }
        };
        fetchStudent();
    }, []);

    useEffect(() => {
        if (student && departmentsTags) {
            SetTabViews([
                { id: "my_profile", view: <MyProfile student={student} setStudent={setStudent} departments={departmentsTags} /> },
                { id: "civil_state", view: <CivilState student={student} setStudent={setStudent} /> },
                { id: "contact_details", view: <ContactDetails student={student} setStudent={setStudent} /> },
                { id: "cvec_details", view: <CvecDetails student={student} setStudent={setStudent} /> },
            ]);
        }
    }, [student, departmentsTags]);

    return (
        <>
            {isLoading && (
                <div className="spinner">
                    <LoadingSpinner textColor={styles.spinner_text_color} />
                </div>
            )}
            {error && (
                <div className="error_wrapper">
                    <HttpMessagePrompt error={error} />
                </div>
            )}
            {!isLoading && tabViews && student && (
                <>
                <div className={styles.mainContainer}>
                    <ProfileTabs
                        menuConfig={profileNav}
                        tabViews={tabViews}
                        activeBtnColor={styles.activeBtnColor}
                    />
                    <SideCard student={student} setStudent={setStudent} />
                </div>
                </>
            )}
        </>
    );
};

export default Profile;
