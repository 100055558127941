/* eslint-disable eqeqeq */
import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import { StudentContext } from "../../context/student-context";
import { TeacherContext } from "../../context/teacher-context";
import { TuteurContext } from "../../context/tuteur-context";
import { ContractorContext } from "../../context/contractor-context";

// Components
import ActionBtn from "../../components/Buttons/ActionBtn/ActionBtn";
import Avatar from "../../components/Avatar/Avatar";

// Images
import logo from "../../assets/images/logo-my-gema.png";
import avatarImg from "../../assets/images/avatar.svg";

// Icons
import MenuIcon from "@mui/icons-material/Menu";
import closeIcon from "../../assets/images/close_icon.svg";
import logOutIcon from "../../assets/images/leaveIcon.svg";
import usersIcon from "../../assets/images/users-solid.svg";

// Styles
import styles from "./Nav.module.scss";

const Nav = () => {

    // Auth Context
    const auth = useContext(AuthContext);

    // History context
    const history = useHistory();

    // App Contexts
    const studentContext = useContext(StudentContext);
    const teacherContext = useContext(TeacherContext);
    const tuteurContext = useContext(TuteurContext);
    const contractorContext = useContext(ContractorContext);

    // Menu
    const [displayMenu, setDisplayMenu] = useState(false);

    const logout = () => {
        switch (auth.userRole) {
            case "student":
                studentContext.unsetStudentContext();
                break;

            case "teacher":
                teacherContext.unsetTeacherContext();
                break;

            case "contractor":
                contractorContext.unsetContractorContext();
                break;

            case "tuteur":
                tuteurContext.unsetTuteurContext();
                break;

            case "admin":
                studentContext.unsetStudentContext();
                teacherContext.unsetTeacherContext();
                contractorContext.unsetContractorContext();
                break;

            default:
                studentContext.unsetStudentContext();
                teacherContext.unsetTeacherContext();
                contractorContext.unsetContractorContext();
                tuteurContext.unsetTuteurContext();
                break;
        }
        auth.logout();
    };

    const changeAccountHandler = (e) => {
        const accademicYear = e.currentTarget.id;
        teacherContext.switchTeacherAccount(accademicYear);
        setDisplayMenu(!displayMenu);
        history.push("/");
    };

    let teacherMenu, studentMenu, contextBtn;

    if (teacherContext.teacherAccounts) {
        teacherMenu = (
            <div className={styles.menu_content}>
                <h4 className={styles.account_title}>Années académiques</h4>
                <div className={styles.accounts_wrapper}>
                    {Object.entries(teacherContext.teacherAccounts).map(([key, value]) => {
                        if (value === teacherContext.teacherSelectedAccount) {
                            return (
                                <ActionBtn
                                    id={key}
                                    btnType="contained"
                                    activeBtnStyle={styles.btn_active}
                                    btnText={key}
                                    textStyle={styles.btn_text_active}
                                />
                            );
                        } else {
                            return (
                                <ActionBtn
                                    id={key}
                                    btnType="contained"
                                    activeBtnStyle={styles.btn}
                                    btnText={key}
                                    textStyle={styles.btn_text}
                                    onClick={(e) => changeAccountHandler(e)}
                                />
                            );
                        }
                    })}
                </div>
            </div>
        );
    }

    if (auth.userRole === "admin") {
        
        const changeContextHandler = () => {

            // Fetch sessionStorage
            const storedUserData = JSON.parse(sessionStorage.getItem("userData"));
            const userType = "admin";
            
            // Stock new context data
            sessionStorage.setItem(
                "userData",
                JSON.stringify({
                    ...storedUserData,
                    userType,
                })
            );

            // Pass arguments to login action as admin
            auth.login(storedUserData.token, storedUserData.expiration, storedUserData.userRole, userType, storedUserData.userSituation);

            // Clear all contexts
            studentContext.unsetStudentContext();
            teacherContext.unsetTeacherContext();
            tuteurContext.unsetTuteurContext();
            contractorContext.unsetContractorContext();
            history.push("/fill-email");

        };

        contextBtn = (
            <ActionBtn
                id="logout_btn"
                btnType="contained"
                btnStyle={styles.adminBtn}
                btnText="Changer d'utilisateur"
                icon={usersIcon}
                iconColor={styles.whiteIcon}
                textStyle={styles.btn_text}
                onClick={changeContextHandler}
            />
        );
    }

    if (studentContext.studentName) {
        studentMenu = (
            <div className={styles.menu_content}>
                <Avatar
                    src={
                        studentContext.studentProfilePhoto &&
                        studentContext.studentProfilePhoto !== "no-photo.png"
                            ? `${process.env.REACT_APP_API_HOST}/profile/get-my-picture?token=${auth.token}`
                            : avatarImg
                    }
                    styles={styles.avatar}
                    stylesAvatar={styles.styleAvatar}
                />
                <p className={styles.studentName}>
                    Bonjour {studentContext.studentName.split(",")[1]} !
                </p>
                <div className={styles.linkContainer}>
                    <Link
                        to="/"
                        className={styles.nav_link}
                        onClick={() => setDisplayMenu(!displayMenu)}
                    >
                        Home
                    </Link>
                    <Link
                        to="/profile"
                        className={styles.nav_link}
                        onClick={() => setDisplayMenu(!displayMenu)}
                    >
                        Mon profil
                    </Link>
                    <Link
                        to="/student-faq"
                        className={styles.nav_link}
                        onClick={() => setDisplayMenu(!displayMenu)}
                    >
                        FAQ
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <header className={styles.wrapper}>
            <Link to="/" className={styles.logo_container} onClick={() => setDisplayMenu(false)}>
                <img src={logo} className={styles.img} alt="" />
            </Link>
            {contextBtn}
            <div className={styles.burgerContainer}>
                <MenuIcon
                    className={styles.burger}
                    style={{ marginRight: "0" }}
                    fontSize="large"
                    onClick={() => setDisplayMenu(!displayMenu)}
                />
            </div>
            {displayMenu && (
                <div className={styles.displayedMenu}>
                    <ActionBtn
                        id="close-btn"
                        btnType="text"
                        btnStyle={styles.closeBtn}
                        icon={closeIcon}
                        iconColor={styles.whiteIcon}
                        onClick={() => setDisplayMenu(!displayMenu)}
                    />
                    {auth.userType === "student" ? studentMenu : teacherMenu}
                    <ActionBtn
                        id="logout_btn"
                        btnType="contained"
                        activeBtnStyle={styles.logout_btn}
                        btnText="Me déconnecter"
                        icon={logOutIcon}
                        iconColor={styles.whiteIcon}
                        textStyle={styles.btn_text}
                        onClick={logout}
                    />
                </div>
            )}
        </header>
    );
};

export default Nav;
