import React, { useState, useContext, useEffect } from "react";

// Components
import Modal from "../../../../Modal/Modal";
import ListHeader from "../../../../ListHeader/ListHeader";
import ActionBtn from "../../../../Buttons/ActionBtn/ActionBtn";
import IbanModal from "../../../../Tabs/Teacher/InvoicingTabs/IbanModal/IbanModal";

// Icons

// Styles
import styles from "./Iban.module.scss";

const Iban = () => {
    // State of the modal
    const [modalStatus, setModalStatus] = useState(false);

    // Actual Modal View in use
    const [modalView, setModalView] = useState();

    const fixScrollPosition = (status) => {
        if (status == false) {
            const scrollPosition = `${window.scrollY}px`;
            const body = document.body;
            body.style.position = "fixed";
            body.style.top = `-${scrollPosition}`;
        } else {
            const body = document.body;
            const scrollY = body.style.top;
            body.style.position = "";
            body.style.top = "";
            window.scrollTo(0, parseInt(scrollY || "0") * -1);
        }
    };

    const ibanModalHandler = (event) => {
        const targetId = event.currentTarget.id;
        if (modalStatus === false) {
            fixScrollPosition(modalStatus);
            setModalView(targetId);
            setModalStatus(true);
        } else {
            fixScrollPosition(modalStatus);
            setModalStatus(false);
        }
    };

    let modalList = {
        modify_iban: (
            <IbanModal
                modalFunction={ibanModalHandler}
                texts={{
                    header: "MODIFIER MON IBAN",
                    title: "Mettre à jour mon IBAN",
                    message: "Veuillez renseigner les informations ci-dessous :",
                    okMessage: "Votre IBAN a été bien mis à jour.",
                }}
            />
        ),
    };

    return (
        <>
            <Modal
                show={modalStatus}
                onClickFunction={ibanModalHandler}
                overlay={modalList[modalView]}
            />
            <section className={styles.container}>
                <ListHeader
                    title="Modifier mon IBAN"
                    message="Pour mettre à jour votre IBAN, veuillez cliquer sur le bouton ci-dessous :"
                />
                <ActionBtn
                    id="modify_iban"
                    btnType="contained"
                    btnStyle={styles.btnStyles}
                    activeBtnStyle={styles.btn_active}
                    btnText="Modifier"
                    textStyle={styles.btn_text}
                    onClick={ibanModalHandler}
                />
            </section>
        </>
    );
};

export default Iban;
