import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";

// Components
import Nav from "../../components/Nav/Nav";
import Footer from "../../components/Footer/Footer";

const Layout = (props) => {
    
    // Authentication context
    const auth = useContext(AuthContext);

    // No Navigation and Footer
    let fullLayout = true;
    const noNavCases = ["/fill-email", "/contact-details", "/justify-absences"];
    if (auth.token && noNavCases.includes(props.location.pathname)) fullLayout = false;

    return (
        <div className="central">
            {auth.token && fullLayout && <Nav />}
            {props.children}
            {auth.token && fullLayout && <Footer />}
        </div>
    );
};

export default withRouter(Layout);
