import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from "react-router-dom";

// Import Styles 
import './index.scss';

// Import App 
import App from './App';


const app = (
  <HashRouter>
      <App />
  </HashRouter>
);

ReactDOM.render(app, document.getElementById("root"));



