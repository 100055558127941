import React from "react";
import { useHistory } from "react-router-dom";

// Components
import ActionBtn from "../../components/Buttons/ActionBtn/ActionBtn";

// Styles
import styles from "./GeneralTermsOfUse.module.scss";

const GeneralTermsOfUse = () => {
    
    // History context
    const history = useHistory();

    return (
        <main className={styles.container}>
            <article className={styles.wrapper}>
                <h2 className={styles.title}>Politique de Groupe GEMA pour la protection des données personnelles (RGPD)</h2>

                <h3 className={styles.subtitle}>Mentions légales</h3>
                <p className={styles.text}>Ce site est publié par Groupe GEMA</p>
                <p className={styles.text}>
                    18 rue du Dôme, 
                    <br />92100, 
                    <br />Boulogne-Billancourt
                    <br /><br />Représentant légal et Directeur de la publication : M. David DERKX
                    <br /><br />Hébergeur : OVH 59820 Gravelines
                </p>
                <p className={styles.text}>
                    Contact :&nbsp;
                    <a href="mailto:equipe@groupe-gema.com?subject=myGEMA - Demande d’information">
                         equipe@groupe-gema.com
                    </a>
                </p>

                <h3 className={styles.subtitle}>I – Collecte</h3>
                <p className={styles.text}>
                    Dans le cadre :<br />
                    - D’une demande d’inscription à une journée d’admission
                    <br />
                    - Une demande de téléchargement de la brochure
                    <br />
                    - Une demande de contact
                    <br />
                    - Sur les sites&nbsp;
                    <a href="https://www.esi-business-school.com" target="_blank">
                        https://www.esi-business-school.com
                    </a>
                    &nbsp;et&nbsp;
                    <a href="https://www.intelligence-artificielle-school.com" target="_blank">
                        https://www.intelligence-artificielle-school.com
                    </a>
                    , la SAS Groupe GEMA – ESI Business School/IA School est amenée à collecter des
                    données personnelles vous concernant.
                </p>

                <h3 className={styles.subtitle}>II – Utilisation</h3>
                <p className={styles.text}>
                    Le renseignement des données est facultatif, et les données collectées sont
                    appelées à être utilisées principalement pour mettre en relation les étudiants
                    et les deux établissements, ESI Business School et IA School. Les données
                    collectées font l’objet de traitements informatisés sur la base de l’intérêt
                    légitime du Groupe GEMA – ESI Business School / IA School.
                </p>

                <h3 className={styles.subtitle}>III – Destinataires</h3>
                <p className={styles.text}>
                    Les données sont destinées à l’administrateur des sites de l’ESI Business School
                    et de l’IA School. Les données enregistrées ne feront l’objet d’aucune
                    communication extérieure sauf aux tiers autorisés en vertu d’une disposition
                    légale ou réglementaire.
                </p>

                <h3 className={styles.subtitle}>IV - Durée de conservation</h3>
                <p className={styles.text}>
                    Les données sont destinées à l’administrateur de la plateforme. Les données
                    enregistrées sur la plateforme ne feront l’objet d’aucune communication
                    extérieure sauf aux tiers autorisés en vertu d’une disposition légale ou
                    réglementaire.
                </p>

                <h3 className={styles.subtitle}>V - Sécurité des traitements</h3>
                <p className={styles.text}>
                    Le Groupe GEMA – ESI Business School / IA School prend les mesures physiques,
                    techniques et organisationnelles appropriées pour assurer la sécurité et la
                    confidentialité des données à caractère personnel, en vue notamment de les
                    protéger contre toute perte, destruction accidentelle, altération, et accès non
                    autorisés.
                </p>
                <p className={styles.text}>
                    Toutes les solutions tierces proposées par le Groupe GEMA à ses utilisateurs
                    sont rigoureusement sélectionnées au regard du respect de la protection des
                    données personnelles.
                </p>

                <h3 className={styles.subtitle}>
                    VI - Application des réglementations locales en matière de transferts de données à
                    caractère personnel en dehors de l'Espace Économique propre à chaque
                    utilisateur.
                </h3>
                <p className={styles.text}>
                    Aucun transfert de données à caractères personnel en dehors de l’Espace
                    Economique dont relève l’utilisateur ne sera effectué.
                </p>

                <h3 className={styles.subtitle}>VII - Exercice des droits</h3>
                <p className={styles.text}>
                    Le collaborateur dispose, dans les conditions prévues par la réglementation
                    applicable, des droits suivants :
                </p>
                <ol>
                    <li>Accès aux données à caractère personnel collectées le concernant ;</li>
                    <li>
                        Rectification ou de mise à jour des données inexactes, incomplètes ou
                        périmées ;
                    </li>
                    <li>Effacement ;</li>
                    <li>Limitation ;</li>
                    <li>Opposition pour motifs légitimes ;</li>
                    <li>Portabilité.</li>
                </ol>
                <p className={styles.text}>
                    Ces droits peuvent être exercés par messagerie électronique à l’adresse mail
                    suivante :
                    <a href="mailto:equipe@groupe-gema.com?subject=myGEMA - Exercice des droits relatifs aux données personnelles">
                        equipe@groupe-gema.com
                    </a>
                </p>

                <h3 className={styles.subtitle}>VIII - Informations</h3>
                <p className={styles.text}>
                    Pour toute question relative à la protection de ses données personnelles,
                    l’utilisateur peut s’adresser au Délégué à la Protection des Données de 2pit.io
                    à l’adresse
                    <a href="mailto:equipe@groupe-gema.com?subject=myGEMA - Question relative aux données personnelles">
                        equipe@groupe-gema.com
                    </a>
                    .<br />
                    L’utilisateur a également la possibilité d’introduire une réclamation auprès de
                    l’autorité de contrôle en charge de la protection des données.
                </p>

                <h3 className={styles.subtitle}>IX - Modification des conditions d’utilisation</h3>
                <p className={styles.text}>
                    L’Utilisateur est invité à prendre régulièrement connaissance des présentes
                    conditions, qui peuvent être modifiées à tout moment.
                </p>

                <h3 className={styles.subtitle}>X - Loi applicable / litiges</h3>
                <p className={styles.text}>Les présentes conditions générales sont soumises au droit français.</p>

                <h3 className={styles.subtitle}>XI - Suspension ou Fermeture de l’accès à la Plateforme</h3>
                <p className={styles.text}>
                    Groupe GEMA SAS se réserve la possibilité de fermer l’accès à la plateforme
                    myGEMA à tout moment et pour quelque raison que ce soit sans avoir à le
                    justifier ou de suspendre l’accès d’un utilisateur à la plateforme, notamment en
                    cas de non-respect des conditions générales d’utilisation. Si un Utilisateur
                    interrompt sa relation avec Groupe GEMA, son compte sera désactivé. Son profil
                    sera conservé durant une période maximale de 1 (un) an et pourra être supprimé à
                    sa demande.
                </p>

                <h3 className={styles.subtitle}>XII - Divers</h3>
                <p className={styles.text}>
                    Les demandes de support ou d’informations complémentaires sur l’utilisation de
                    la plateforme, doivent être envoyées à l’adresse mail suivante :&nbsp;
                    <a href="mailto:equipe@groupe-gema.com?subject=myGEMA - Demande d’information">
                        equipe@groupe-gema.com
                    </a>
                    . Par ailleurs, si l’utilisateur constate un contenu qui ne devrait pas être
                    présent sur myGEMA pour quelque raison que ce soit, y compris parce qu’il n’est
                    pas conforme aux réglementations en vigueur, il est invité à contacter&nbsp;
                    <a href="mailto:equipe@groupe-gema.com?subject=myGEMA - CONTENU SUSPECT">
                        equipe@groupe-gema.com
                    </a> sans délai avec le sujet « CONTENU SUSPECT ».
                </p>
            </article>
            <div className={styles.button_container}>
                <ActionBtn 
                    id="return"
                    btnType="button"
                    btnStyle="contained"
                    btnColor="primary"
                    btnText="Retourner à l'accueil"
                    textStyle={styles.btn_text_outlined}
                    activeBtnStyle={styles.btn_active_outlined}
                    onClick={event => history.push("/")}        
                />
            </div>
            
        </main>
    );
};

export default GeneralTermsOfUse;
