import React from "react";

//Styles
import styles from "./ListHeader.module.scss";

const ListHeader = ({ title, message }) => {

    let paragraph;
    if (message) paragraph = <p className={styles.list_message}>{message}</p>;
    
    return (
        <header className={styles.container}>
            <h3 className={styles.list_title}>{title}</h3>
            {paragraph}
        </header>
    );
};

export default ListHeader;
