import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import { useHttpRequest } from "../../hooks/httpRequest-hook";

// Components
import HeaderBanner from "../../components/HeaderBanner/HeaderBanner";
import PostsList from "../../components/PostsList/PostsList";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import HttpMessagePrompt from "../../components/HttpMessagePrompt/HttpMessagePrompt";
import { structureConfigDocuments } from "../../utils/helperFunctions";

// Icons
import slifeIcon from "../../assets/images/vie_etudiante.svg";
import downloadIcon from "../../assets/images/download-solid.svg";
import editIcon from "../../assets/images/edit-solid.svg";

// Styles
import styles from "./StudentLife.module.scss";
import TabView from "../../components/TabView/TabView";
import DocumentsListDisplay from "../../components/GenericTabs/DocumentsListDisplay/DocumentsListDisplay";

const StudentLife = () => {

    // Authentication context
    const auth = useContext(AuthContext);

    // Request Hook
    const { isLoading, error, sendRequest } = useHttpRequest();

    const [documentLibrary, SetDocumentLibrary] = useState();

    const [tabViews, SetTabViews] = useState();

    // Posts State
    const [posts, setPosts] = useState();

    const [studentLifeNav] = useState([
        {
            id: "mygema_asso",
            btnText: "Associations",
            btnType: "text",
            blogs: true,
        },
        {
            id: "mygema_bons_plans",
            btnText: "Bons plans",
            btnType: "text",
            disabled: auth.userType === "tuteur" ? true : false
        },
        {
            id: "mygema_appartements",
            btnText: "Logements",
            btnType: "text",
            disabled: auth.userType === "tuteur" ? true : false
        },
        {
            id: "mygema_banques",
            btnText: "Banques",
            btnType: "text",
            disabled: auth.userType === "tuteur" ? true : false
        },
        {
            id: "mygema_mutuelles",
            btnText: "Mutuelles",
            btnType: "text",
            disabled: auth.userType === "tuteur" ? true : false
        },
    ]);

    const structureTabViews = (posts, editIcon, downloadIcon, iconColor) => {
        let newStructure = {};

        // Restructure server response
        Object.entries(posts).forEach(([key, value]) => {
            newStructure[key] = [];
            // Add new properties to object
            value.forEach(({ status, type, name, labels, locale_2, href, hashtag }) => {   
                // Initialize dynamic data variables
                let link = href,
                    icon = downloadIcon,
                    description = locale_2
    
                if (type === "structured") {
                    if (status === "active") {
                        icon = editIcon;
                        link = `/student-eval-form/${name}`;
                    } else link = `${process.env.REACT_APP_API_HOST}/documents/generate-document/${name}`;
                }
    
                // Push new object
                newStructure[key].push({
                    status,
                    type: type || "document",
                    icon,
                    link,
                    description,
                    title: labels.default,
                    iconColor,
                    hashtag: Object.values(hashtag),
                });
            });
        });
    
        return newStructure;
    }

    // Fetch Posts
    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                // const url = `${process.env.REACT_APP_API_STUDENT}/blogs/posts/mygema_VieEtudiante`;
                const url = `${process.env.REACT_APP_API_STUDENT}/documents/config/partenariat`;
                const postsData = await sendRequest(url, "GET", null, {
                    Authorization: "Bearer " + auth.token,
                });

                // Structure Data
                const structuredDocuments = await structureTabViews(postsData, editIcon, downloadIcon, styles.download_icon_color);

                // Create View Tabs
                let tabViewArray = [];
                Object.entries(structuredDocuments).forEach(([key, value]) =>
                    tabViewArray.push({
                        id: key,
                        view: <PostsList postList={value} />,
                    })
                );
                // Set States
                SetDocumentLibrary(structuredDocuments);
                SetTabViews(tabViewArray);
            } catch (err) {
                console.error(err);
            }
        };
        fetchDocuments();
    }, []);

    return (
        <>
            {isLoading && (
                <div className="spinner">
                    <LoadingSpinner />
                </div>
            )}
            {error && (
                <div className="error_wrapper">
                    <HttpMessagePrompt error={error} />
                </div>
            )}
            {!isLoading && documentLibrary && studentLifeNav && tabViews && (
                <main className="tab_container">
                    <HeaderBanner
                        title="Vie Associative & bons plans"
                        icon={slifeIcon}
                        iconColor={styles.icon_color}
                        bgColor={styles.banner_color}
                    />
                    <TabView
                        menuConfig={studentLifeNav}
                        tabViews={tabViews}
                        activeBtnColor={styles.btn_active}
                    />
                </main>
            )}
        </>
    );
};

export default StudentLife;
