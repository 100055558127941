import React, { useState } from "react";

// Components
import AbsencesListItem from "../AbsencesListItem/AbsencesListItem";
import { useWindowDimensions } from "../../../../hooks/window-dimensions-hook";

// Styles
import styles from "./AbsencesList.module.scss";

const AbsencesList = ({ absences, onClick, justifyAbsence }) => {
    
    // Window Size Hook
    const { width } = useWindowDimensions();

    let header;
    if (width > 667) {
        header = (
            <>
                <div className={styles.wrapper_subject}>
                    <h4 className={styles.header_item}>Matière</h4>
                </div>
                <div className={styles.wrapper_item}>
                    <h4 className={styles.header_item}>Date</h4>
                </div>
                <div className={styles.wrapper_item}>
                    <h4 className={styles.header_item}>Creneau</h4>
                </div>
                <div className={styles.wrapper_motif}>
                    <h4 className={styles.header_item}>Statut de l'absence</h4>
                </div>
            </>
        );
    } else {
        header = (
            <div className={styles.wrapper_subject_alt}>
                <h4 className={styles.header_item}>Matière</h4>
            </div>
        );
    }

    return (
        <section className={styles.container}>
            <div className={styles.header}>{header}</div>
            {absences.map(
                ({ id, place_id, type, begin_date, begin_time, end_time, course, reason }) => {
                    return (
                        <AbsencesListItem
                            key={id}
                            id={id}
                            place_id={place_id}
                            type={type}
                            begin_date={begin_date}
                            begin_time={begin_time}
                            end_time={end_time}
                            course={course}
                            reason={reason}
                            onClick={onClick}
                        />
                    );
                }
            )}
                {justifyAbsence && (
                    <div className={styles.containerMotiv}>
                      <div className={styles.legend}>
                            <div>
                              <p className={styles.legend_titles}>À JUSTIFIER :</p>
                              <p className={styles.legend_defenition}>  
                                  <b className={styles.legend_littletitle}>Je dois justifier mon absence sous 48h.</b>
                                  <p>Passé ce délai, elle sera considérée comme non recevable.</p>
                              </p>
                            </div>
      
                            <div>
                                <p className={styles.legend_titles}>TRAITEMENT EN COURS :</p>
                                <p className={styles.legend_defenition}>  
                                    <b className={styles.legend_littletitle}>J'ai justifié mon absence qui est en cours de traitement auprès du pôle pédagogique.</b>      
                                </p>                   
                            </div>
        
                            <div>
                                <p className={styles.legend_titles}>JUSTIFIÉE :</p>
                                <p className={styles.legend_defenition}>  
                                    <b className={styles.legend_littletitle}>J'ai justifié mon absence.</b>   
                                </p>                   
                            </div>
        
                            <div>
                                <p className={styles.legend_titles}>NON-RECEVABLE :</p>
                                <p className={styles.legend_defenition}>  
                                    <b className={styles.legend_littletitle}>J'ai justifié mon absence, mais le justificatif n'est pas recevable et/ou en accord avec le règlement de l'école.</b>
                                </p>                   
                            </div>
                      </div>         
                  </div>
                )}
        </section>
    );
};

export default AbsencesList;
