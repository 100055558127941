import React from "react";

// Styles
import styles from "./LoadingSpinner.module.scss";

const LoadingSpinner = (props) => {
    return (
        <div className={`${(props.asOverlay) ? props.asOverlay : ''} ${styles.loading_spinner__overlay}`}>
            <div className={styles.spinner_container}>
                <div className={styles.lds_dual_ring}></div>
                <p className={props.textColor || styles.spinner_text}>Veuillez patienter...</p>
            </div>
        </div>
    );
};

export default LoadingSpinner;
