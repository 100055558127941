import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import { TeacherContext } from "../../context/teacher-context";
import { TuteurContext } from "../../context/tuteur-context";
import { StudentContext } from "../../context/student-context";
import { filterDocument } from "../../utils/helperFunctions";

// Components
import NotificationBanner from "../NotificationBanner/NotificationBanner";
import ExtLinkBanner from "./ExtLinkBanner";
import LinkBanner from "./LinkBanner";
import DownloadDocBtn from "../Buttons/DownloadDocBtn/DownloadDocBtn";

// Icons
import warningIcon from "../../assets/images/warning.svg";
import infoIcon from "../../assets/images/info.svg";

// Styles
import styles from "./DocLinkBanner.module.scss";
const DocLinkBanner = ({ docType, status, config, title, link, iconColor, icon, hashtag, bgColor,archive}) => {
    // Authentication context
    const auth = useContext(AuthContext);


    // App context
    let contextForDoc;
    const context = useContext(auth.userType === "student" ? StudentContext : TeacherContext);

    const tuteurContext = useContext(TuteurContext);

    // Changing context to give in filterDoc on which userType you are.
    if (auth.userType === 'tuteur') {
        contextForDoc = tuteurContext;
    } else {
        contextForDoc = context;
    }

    // History context
    const history = useHistory();

    if (auth.userType === "student" && ["mygema_certificates", "mygema_attestations"].includes(config)) {
        if (context.studentFinStatus === "overdue" && 
            ((config === "mygema_attestations" && context.studentAttestation === "blocked" ) || (config === "mygema_certificates" && context.studentCertificate === "blocked"))) 
            return (
                <NotificationBanner
                    bannerIcon={warningIcon}
                    bannerIconColor={styles.warning_icon_color}
                    message={"-- Accès restreint -- Veuillez régulariser votre situation en cliquant ici >>>"}
                    hasBtn={true}
                    btnText={"Dossier Financier"}
                    btnFnc={() => history.push("/dossier-financier")}
                />
            );

        if (((config === "mygema_certificates" && context.studentCertificate === "blocked") || (config === "mygema_attestations" && context.studentAttestation === "blocked")))
            return (
                <NotificationBanner
                    bannerIcon={infoIcon}
                    bannerIconColor={styles.info_icon_color}
                    message={config === "mygema_attestations" ? "Attestation d'inscription bientôt disponible." : "Certificat de scolarité bientôt disponible."}
                    hasBtn={false}
                />
            );
    }

    // Differentiate between document and structured document
    switch (docType) {

        case "document":
            if (filterDocument(hashtag, contextForDoc)) {
                return (
                    <ExtLinkBanner
                        config={config}
                        title={title}
                        link={link}
                        iconColor={iconColor}
                        icon={icon}
                        bgColor={bgColor}
                        archive={archive}
                    />
                );
            } else return null;

        case "structured":
            if (status !== "completed")
                return (
                    <LinkBanner
                        title={title}
                        link={link}
                        iconColor={iconColor}
                        icon={icon}
                        bgColor={bgColor}
                    />
                );
            else
                return (
                    <DownloadDocBtn
                        type="banner"
                        text={title}
                        link={link}
                        iconColor={iconColor}
                        icon={icon}
                        bgColor={bgColor}
                    />
                );

        default:
            if (filterDocument(hashtag, context)) {
                return (
                    <ExtLinkBanner
                        config={config}
                        title={title}
                        link={link}
                        iconColor={iconColor}
                        icon={icon}
                        bgColor={bgColor}
                    />
                );
            } else 

            return null;
        }
};

export default DocLinkBanner;
