import React from "react";

// Components
import GradesByYearBlock from "../GradesByYearBlock/GradesByYearBlock";

// Styles
import styles from "./GradesBlock.module.scss";

const GradesBlock = ({ grades }) => {
    return (
        <article className={styles.container}>
            {grades.map(({ year, grades }, index) => {
                return <GradesByYearBlock key={index} year={year} grades={grades} />;
            })}
        </article>
    );
};

export default GradesBlock;
