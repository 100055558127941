import React from "react";
import { Link } from "react-router-dom";
import HardLink from "../Buttons/HardLink/HardLink";

// Styles
import styles from "./Footer.module.scss";

const Footer = () => {
    const year = new Date().getFullYear();

    return (
        <footer className={styles.container}>
            <div className={styles.links_wrapper}>
                <Link to="/general-terms-of-use" className={styles.link}>
                    Conditions d’utilisation et protection des données
                </Link>
            </div>
            <div className={styles.copyright_container}>
                <p className={styles.text}>Copyright Groupe GEMA {year}</p>
            </div>
        </footer>
    );
};

export default Footer;
