import React from "react";
import { Link } from "react-router-dom";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
// import { AuthContext } from "../../context/auth-context";

// Styles
import styles from "./HomeCard.module.scss";
import { red } from "@mui/material/colors";

/*
Expects ==>
<HomeCard 
    linkTo="link string"
    hardLink=false
    cardColor={style.card_title}
    icon={CalendarIcon}
    iconColor={style.card_title}
    title={style.card_title} 
/>
*/

const Card = (props) => {
    let linkType;
    const cardBody = (
        <>
            <div className={styles.cardWrapper}>
                {props.notificationAlert == "true" && (
                    <NewReleasesIcon
                        className={styles.notificationAlert}
                        fontSize="large"
                        sx={{ color: red[500] }}
                    />
                )}
                <div className={styles.img_wrapper}>
                    <img src={props.icon} className={`${styles.icon} ${props.iconColor}`} alt="" />
                </div>
                <div className={styles.title_wrapper}>
                    <h4 className={styles.title}>{props.title}</h4>
                </div>
            </div>
        </>
    );

    if (props.isDisabled) {
        return (
            <div className={`${styles.card} ${props.cardColor} ${styles.tooltip}`}>
                {cardBody}
                <span className={styles.tooltiptext}>Fonctionnalité bloquée</span>
            </div>
        );
    }

    if (props.isEmpty) {
        return (
            <div className={`${styles.card} ${props.cardColor}`}></div>
        );
    }
        
    if (props.hardLink === false) {
        linkType = (
            <Link className={`${styles.card} ${props.cardColor}`} to={props.linkTo}>
                {cardBody}
            </Link>
        );
    } else {
        linkType = (
            <a
                className={`${styles.card} ${props.cardColor}`}
                href={props.linkTo}
                target="_blank"
                rel="noreferrer"
            >
                {cardBody}
            </a>
        );
    }

    return linkType;
};

export default Card;
