import React from "react";

// Static Images


// Styles


const NotFound = () => {
    return (
        <div className="">
            <img src="" className="" alt="" />
            <div className="">
                <h3 className="">404 Page</h3>
            </div>
        </div>
    );
};

export default NotFound;