import React, { useState } from "react";

// Components
import HeaderBanner from "../../components/HeaderBanner/HeaderBanner";
import TabView from "../../components/TabView/TabView";
import HardLink from "../../components/Buttons/HardLink/HardLink";

// Icons
import tutorialsIcon from "../../assets/images/tutorial.svg";

// Styles
import styles from "./Tutorials.module.scss";

const Tutorials = () => {
    const [tutorialsNav] = useState([
        {
            id: "tutoriels",
            btnText: "Tutoriels",
            btnType: "text",
        },
    ]);

    let tabViews = [
        {
            id: "tutoriels",
            view: (
                <div className={styles.videoContainer}>
                    <div className={styles.video}>
                        <h3 className={styles.video_title} >Classroom</h3>
                        <iframe
                            className={styles.iframe}
                            width="240"
                            height="120"
                            src="https://www.youtube.com/embed/Q_bWeEdZE-Y"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                        <HardLink
                            linkTo="https://app.mygema.com/ressources/TUTO_Classroom.pdf"
                            title="Version en PDF"
                        />
                    </div>
                    <div className={styles.video}>
                        <h3 className={styles.video_title} >Facturation</h3>
                        <iframe
                            className={styles.iframe}
                            width="240"
                            height="120"
                            src="https://www.youtube.com/embed/J8sZ5MaqnM8"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                        <HardLink
                            linkTo="https://app.mygema.com/ressources/TUTO_facturation.pdf"
                            title="Version en PDF"
                        />
                    </div>
                    <div className={styles.video}>
                        <h3 className={styles.video_title} >Évaluation</h3>
                        <iframe
                            className={styles.iframe}
                            width="240"
                            height="120"
                            src="https://www.youtube.com/embed/liCPN2lD6FI"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                            <HardLink
                            linkTo="https://app.mygema.com/ressources/TUTO_evaluation.pdf"
                            title="Version en PDF"
                        />
                    </div>
                    <div className={styles.video}>
                        <h3 className={styles.video_title} >Émargement</h3>
                        <iframe
                            className={styles.iframe}
                            width="240"
                            height="120"
                            src="https://www.youtube.com/embed/I_gqfYTHXP0"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                        <HardLink
                            linkTo="https://app.mygema.com/ressources/TUTO_emargement.pdf"
                            title="Version en PDF"
                        />
                    </div>
                </div>
            ),
        },
    ];

    return (
        <main className={styles.container}>
            <HeaderBanner
                title="Tutos"
                icon={tutorialsIcon}
                iconColor={styles.icon_color}
                bgColor={styles.banner_color}
            />
            <TabView
                menuConfig={tutorialsNav}
                tabViews={tabViews}
                activeBtnColor={styles.btn_active}
            />
        </main>
    );
};

export default Tutorials;
