import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useHttpRequest } from "../../hooks/httpRequest-hook";
import { AuthContext } from "../../context/auth-context";
import { StudentContext } from "../../context/student-context";
import { TuteurContext } from "../../context/tuteur-context";
import { TeacherContext } from "../../context/teacher-context";
import { ContractorContext } from "../../context/contractor-context";
import { loginEndpoint } from "../../utils/helperFunctions";

// Components
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import ActionBtn from "../../components/Buttons/ActionBtn/ActionBtn";

// Static Assets
import logo from "../../assets/images/logo-my-gema.png";

// Styles
import styles from "./Login.module.scss";
    
const Login = () => {
    
    // Authentication context
    const auth = useContext(AuthContext);

    // Student App Context
    const studentAppContext = useContext(StudentContext);

    // Teacher App Context
    const teacherAppContext = useContext(TeacherContext);

    const tuteurAppContext = useContext(TuteurContext);

    // Contractor App Context
    const contractorAppContext = useContext(ContractorContext);

    // History context
    const history = useHistory();

    // Request Hook
    const { isLoading, error, sendRequest } = useHttpRequest();

    // Front-end Error State 
    const [err, setErr] = useState();

    const loginHandler = async (googleData) => {

        // Debugging only
        // console.log("googleData", googleData);
        
        // Email received by Google
        const decoded = jwt_decode(googleData.credential);
        const email = decoded.email;
        const requestBody = {
            token: googleData.credential,
            email: email
        };

        // const email = "akigora.presta@groupe-gema.com";
        // const email = "test.student.edu@groupe-gema.com";  

        // const decoded = { email, tokenId };
        /*
            Ref. GOOGLE DATA from google login
            ======================================================================================
            googleData = {
                accessToken: "ya29.a0ARrdaM_0VbKOYicCEibTj1V24....",
                googleId: "104371076062427109525",
                profileObj: {
                    email: "email...@groupe-gema.com",
                    familyName: "NOM",
                    givenName: "Prénom",
                    googleId: "5678987654...",
                    imageUrl: "https://lh3.googleusercontent.com/a/AATXAJxBcBogEc...",
                    name: "Prénom NOM",
                },
                tokenId: "eyJhbGciOiJSUzI1NiIsImtpZCI6IjhkOTI5YzYzZmYx...",
                tokenObj: {
                    access_token: "ya29.a0ARrdaM_0VbKOYicCEibTj1V24....", // Same as accessToken
                    expires_at: 1633269416533,
                    expires_in: 3599,
                    first_issued_at: 1633265817533,
                    id_token: "eyJhbGciOiJSUzI1NiIsImtpZCI6IjhkOTI5YzYzZmYx...", // Same as tokenId
                    idpId: "google",
                    login_hint: "AJDLj6IfBht_rn47TQN9-RspBps_s0SsKj9...",
                    scope: "email profile openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
                    token_type: "Bearer",
                },
            };
            ======================================================================================
        */

        // Avoid API call if domain is not correct
        if (!email.includes("@groupe-gema.com")) { setErr("email incorrect"); return; }

        if (email.includes("tuteur@groupe-gema.com") && window.location.href !== `${process.env.REACT_APP_TUTORS_DOMAIN}`) {
            window.location.href=`${process.env.REACT_APP_TUTORS_DOMAIN}`;
        }

        try {
            const loginData = await sendRequest(
                loginEndpoint(email), 
                "POST", 
                JSON.stringify(requestBody),
                { "Content-Type": "application/json" }
            );

            // Pass data to the login Auth Hook
            auth.login(loginData.token, loginData.expires, loginData.role, loginData.type, loginData.situation, loginData.backToken);

            if (loginData.role === "admin") history.push("/fill-email");
            else {
                switch (loginData.role) {
                    case "student":
                        // Pass data to the Student Context Hook
                        studentAppContext.setStudentContext(
                            loginData.status,
                            loginData.restricted,
                            loginData.campus,
                            loginData.accademicPeriod,
                            loginData.profile_photo,
                            loginData.name,
                            loginData.finStatus,
                            loginData.accademicLevel,
                            loginData.school,
                            loginData.attestation,
                            loginData.certificate,
                            loginData.nextYearLevel,
                            loginData.nextYearCampus,
                            loginData.rythme,
                            loginData.offresRestriction,
                            loginData.notificationOfferHome
                        );

                        if (loginData.situation === "justify-absences") history.push("/justify-absences");
                        if (loginData.situation === "first-login") history.push("/contact-details");
                        else history.push("/");

                        break;

                        case "tuteur":
                            // Pass data to the Student Context Hook
                            tuteurAppContext.setTuteurContext(
                              loginData.status,
                              loginData.restricted,
                              loginData.campus,
                              loginData.accademicPeriod,
                              loginData.profile_photo,
                              loginData.name,
                              loginData.finStatus,
                              loginData.accademicLevel,
                              loginData.school,
                              loginData.attestation,
                              loginData.certificate,
                              loginData.nextYearLevel,
                              loginData.nextYearCampus,
                              loginData.rythme
                            );
                            history.push("/");
                            break;
                

                    case "teacher":
                        // Pass data to the Teacher Context Hook
                        teacherAppContext.setTeacherContext(
                            loginData.accounts,
                            loginData.mainAccount,
                            loginData.placeId,
                            loginData.mainCampus,
                            loginData.canInvoice,
                            loginData.isFreelancer,
                            loginData.vcardId,
                            loginData.currentAccountYear,
                            loginData.campuses,
                            loginData.multipleCampuses,
                            loginData.currentMultipleCampuses,
                            loginData.name,
                        );
                        history.push("/");
                        break;

                    case "contractor":
                        // Pass data to the Contractor Context Hook
                        contractorAppContext.setContractorContext(
                            loginData.contractorSelectedId,
                            loginData.campus,
                            loginData.teachersIds,
                            loginData.teachers,
                            loginData.currentAccountYear,
                            loginData.contractorIds,
                            loginData.campuses,
                        );
                        history.push("/");
                        break;

                    default:
                        break;
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            {isLoading && (
                <div className="spinner">
                    <LoadingSpinner />
                </div>
            )}
            {!isLoading && (
                <div className={styles.wrapper}>
                    <div className={styles.container}>
                        <header className={styles.header}>
                            <div className={styles.logo}>
                                <img src={logo} className={styles.logo_img} alt="" />
                            </div>
                            <h1 className={styles.title}>Bienvenue</h1>
                            {/* Message de redirection temporaire pour les tuteurs */}
                            {window.location.hash.split("?")[1] && (
                                <p className="error_message">Vous avez été redirigé sur votre application MyGema dédiée. Merci de vous reconnecter.</p>
                            )}
                        </header>
                        <div style={{margin:"0 auto"}}>
                            <GoogleLogin
                                // buttonText="S'authentifier avec Google"
                                onSuccess={loginHandler}
                                onError={loginHandler}
                                logo_alignment="center"
                                // width="290"
                                // cookiePolicy={"single_host_origin"}
                                // hostedDomain="groupe-gema.com"
                            />
                        </div>
                        {/*<ActionBtn
                            id="log-in-btn"
                            btnType="button"
                            form="login-form"
                            name="S'Identifier"
                            btnStyle="contained"
                            btnColor="primary"
                            margins={styles.btn_margins}
                            onClick={loginHandler}
                        /> */}
                        <p className="error_message">{error || err}</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default Login;
