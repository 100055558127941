/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import ActionBtn from "../../../../Buttons/ActionBtn/ActionBtn";
import NoteLine from "../NoteLine/NoteLine";

import styles from "./ReportBlock.module.scss";

const ReportLine = (props) => {
    const [newNoteArray, setNewNoteArray] = useState();

    let noteBlock = (
        <div style={{ display: "flex", marginBottom: "1rem" }}>
            <h3 className={styles.noNotes}>Aucune évaluation renseignée</h3>
        </div>
    );

    // Fonction qui prend l'array de notes et qui remplace la catégorie par le bon libélé du référentiel
    const arrayConstructor = async (array) => {
        let newArray = [];
        await array.forEach((element) => {
            if (element.group_id == props.repGroupId && element.subject == props.repSubject) {
                props.category.forEach((elementCate) => {
                    if (elementCate.label === element.category) {
                        element.category = elementCate.default;
                    }
                });
                newArray.push(element);
            }
        });
        return newArray;
    };

    useEffect(() => {
        const arrayFctn = async () => {
            setNewNoteArray(await arrayConstructor(props.notes));
        };
        arrayFctn();
    }, [props.notes]);

    if (newNoteArray && newNoteArray.length > 0) {
        noteBlock = newNoteArray.map((note) => (
            <NoteLine
                reportId={props.repId}
                key={note.id}
                noteId={note.id}
                link={styles.link}
                span={styles.span}
                noteDate={note.date}
                noteCategory={note.category}
                LinkbtnStyles={styles.LinkbtnStyles}
                SearchIcon={props.SearchIcon}
                loupe={styles.loupe}
                btn_text={props.btn_text}
                EvalUpdateModalHandler={props.EvalUpdateModalHandler}
                EvalModalHandler={props.EvalModalHandler}
            />
        ));
    }

    return (
        <>
            {newNoteArray && (
                <div className={props.groupsAndLinks}>
                    <div id={props.repId} className={props.groupContainer}>
                        <div className={styles.groupCaption}>
                            {props.repGroupName == undefined ? (
                                <span className={styles.spanUndefined}>Groupe non renseigné</span>
                            ) : (
                                props.repGroupName
                            )}
                            <span className={styles.caption}>{props.repCaption}</span>
                        </div>
                        <span className={styles.spanSubject}>
                            {props.repSubjectName == undefined
                                ? props.repSubject
                                : props.repSubjectName}
                        </span>
                        <ActionBtn
                            id="evalModal"
                            btnType="contained"
                            // btnStyle={styles.btnStyles}
                            activeBtnStyle={styles.btn_active}
                            btnText="Créer une évaluation"
                            textStyle={styles.btn_text}
                            onClick={props.EvalModalHandler}
                        />
                    </div>
                    {noteBlock}
                </div>
            )}
        </>
    );
};
export default ReportLine;
