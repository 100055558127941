import React from "react";

// Components
import Post from "../../components/Post/Post";

// Styles
import styles from "./PostsList.module.scss";

const PostsList = ({ postList }) => {
    return (
        <div className={styles.container}>
            {postList.map((post, index) => {
                return (
                    <Post
                        key={index}
                        title={post.title}
                        image={post.link}
                        content={post.description}
                    />
                );
            })}
        </div>
    );
};

export default PostsList;
