import React from "react";
import { gradeLabels } from "../../../../utils/dictionaries";

// Components

// Styles
import styles from "./GradesListItem.module.scss";

const GradesListItem = ({ course, grade, weight, evaluation_type }) => {
    if (grade === null) grade = "null";
    return (
        <section className={`${styles.container}`}>
            <div className={styles.wrapper_title}>
                <h4 className={styles.title}>{course}</h4>
            </div>
            <div className={styles.wrapper_eval}>
                <p className={styles.text}>{evaluation_type}</p>
            </div>
            <div className={styles.wrapper_item}>
                <p className={styles.text}>{gradeLabels[grade] && gradeLabels[grade].notes_label ? gradeLabels[grade].notes_label : grade}</p>
            </div>
            <div className={styles.wrapper_item}>
                <p className={styles.text}>{parseFloat(weight).toFixed(1)}</p>
            </div>
        </section>
    );
};

export default GradesListItem;
