import React from "react";

// Components
// import ActionBtn from "../Buttons/ActionBtn/ActionBtn";

// Static Assets
import errorIcon from "../../assets/images/times-solid.svg";
import okIcon from "../../assets/images/check-solid.svg";

// Styles
import styles from "./HttpMessagePrompt.module.scss";

const HttpMessagePrompt = (props) => {

    let message = (props.message) ? <p className={styles.error_message}>{props.message}</p> : null;
    let secondaryMessage = (props.secondaryMessage) ? <div className={styles.alt_message}>{props.secondaryMessage}</div> : null;
    let margin = (!message) ? styles.margin : '';
    if (props.error) {
        message = (
            <>
                <div className={styles.icon_container}>
                    <img className={styles.icon_error} src={errorIcon} alt="" />
                </div>
                <p className={`${styles.error_title} ${margin}`}>{props.error}</p>
                {message}
                <div className={styles.btn}>{props.btn}</div>
            </>
        );
    } else {
        message = (
            <>
                <div className={styles.ok_icon_container}>
                    <img className={styles.icon_ok} src={okIcon} alt="" />
                </div>
                <p className={styles.ok_title}>{props.message}</p>
                {secondaryMessage}
                <div className={styles.btn}>{props.btn}</div>
            </>
        );
    }

    return <div className={styles.container}>{message}</div>;
};

export default HttpMessagePrompt;
