import React from "react";

// Styles
import styles from "./DocLinkBanner.module.scss";

const ExtLinkBanner = ({ config, title, link, iconColor, icon, bgColor,archive }) => {
    return (
        /* if "archive" property is declared and true ==> style.disabled_link is applied */
        <div>
            <a
            href={link}
            className={!archive ? styles.link : styles.disabled_link}
            target="_blank"
            rel="noreferrer"
        >
            <div className={`${styles.container} ${bgColor}`}>
                <img className={`${styles.icon} ${iconColor}`} src={icon} alt="" />
                <h4 className={styles.text}>{title}</h4>
            </div>
        </a>

        
        </div>

    );
};

export default ExtLinkBanner;
