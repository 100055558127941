import React from "react";
import { useWindowDimensions } from "../../../../../hooks/window-dimensions-hook";

// Components
import InvoiceListItem from "../InvoiceListItem/InvoiceListItem";

// Styles
import styles from "./InvoicesTable.module.scss";

const InvoicesTable = (props) => {
    
    const { width } = useWindowDimensions();

    // let courses;
    // if (props.campus) {
    //     courses = props.realizedCourses.filter(course => course.place_id == props.campus);
    // } else courses = props.realizedCourses

    let selfEmployedItems;
    if (props.selfEmployed) {
        selfEmployedItems = (
            <>
                <p style={{ width: "7rem" }} className={styles.menu}>
                    {props.headerLabels.rate}
                </p>
                <p style={{ width: "5rem" }} className={styles.menu}>
                    {props.headerLabels.total}
                </p>
            </>
        );
    }

    let tableType;

    if (width <= 768) {
        tableType = (
            <>
                <div className={styles.table_header}>
                    <p style={{ margin: "0.8rem auto" }}>Cours</p>
                </div>
                {props.realizedCourses &&
                    props.realizedCourses.map((input, index) => {
                        if (!input.status === props.filter) return;
                        else
                            return (
                                <InvoiceListItem
                                    key={index}
                                    id={index}
                                    item={input}
                                    selfEmployed={props.selfEmployed}
                                    onChange={props.onChange}
                                />
                            );
                    })}
            </>
        );
    } else
        tableType = (
            <>
                <div className={styles.table_header}>
                    <p style={{ paddingLeft: "5rem", marginRight:"10%" }}>{props.headerLabels.assignment}</p>
                        <p style={{ paddingLeft: "5%" }} className={styles.menu}>
                            {props.headerLabels.teacherName}
                        </p>
                    <div className={styles.menu_div}>
                        <p className={styles.menu}>
                            {props.headerLabels.date}
                        </p>
                        <p className={styles.menu}>
                            {props.headerLabels.length}
                        </p>
                        <p className={styles.menu}>
                            {props.headerLabels.duration}
                        </p>
                        {selfEmployedItems}
                    </div>
                </div>
                {props.realizedCourses &&
                    props.realizedCourses.map((input, index) => {
                        return (
                            <InvoiceListItem
                                key={index}
                                id={index}
                                item={input}
                                selfEmployed={props.selfEmployed}
                                onChange={props.onChange}
                            />
                        );
                    })
                }
                {props.realizedCourses.length == 0 && (
                    <p style={{ margin:"0 auto", padding:"4rem", color:"black" }}>Aucun créneau facturable pour le campus séléctionné.</p>
                )}
            </>
        );

    return tableType;
};

export default InvoicesTable;
