/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Avatar from "../../components/Avatar/Avatar";


import Avatar1 from "../../assets/images/avatar.svg";


import styles from "./MyGemaContact.module.scss";

const MyGemaContact = (props) => {

    const [content] = useState(props.content);

    const [poles] = useState(props.poles[0]);

    return (
        // MAIN SECTION
        <div className={styles.mainSection}>
            {/* Map sur le content pour afficher chaque pôle */}
            {content &&
                poles &&
                poles.map((item) => (
                    <div key={item.label} className={styles.poleContainer}>
                        <p className={styles.title}>{item.default}</p>
                        <div className={styles.itemContainer}>
                            {content.map((contact) =>
                                //  ITEM SECTION

                                // Si le pole du contact parcouru est égal au label du pôle parcouru, alors on affiche le contact, sinon rien
                                contact.chapter === item.default ? (
                                    <div key={contact.img} className={styles.item}>
                                        <Avatar
                                            src={contact.img ? `${process.env.REACT_APP_API_HOST}/documents/get-image-contact/${contact.img}` : Avatar1}
                                            styles={styles.avatar}
                                            alt=""
                                        />
                                        <p className={styles.name}>{contact.labels.default}</p>
                                        <a href={`mailto:${contact.link_label}`} target="_blank" rel="noreferrer" className={styles.mail}>{contact.link_label.replace(/<[^>]*>?/gm, '')}</a>
                                    </div>
                                ) : (
                                    <></>
                                )
                            )}
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default MyGemaContact;
