import { createContext } from "react";

export const ContractorContext = createContext({
    contractorMainAccount: null,
    contractorCampus: null,
    contractorTeachersIds: null,
    contractorTeachers: null,
    contractorCurrentAccountYear: null,
    contractorIds: null,
    setContractorContext: () => {},
    unsetContractorContext: () => {},
});