import React from 'react';

import styles from "./Avatar.module.scss";

const Avatar = (props) => {
    return (
        <div className={props.styles ? props.styles : styles.avatar}>
            <img className={props.stylesAvatar ? props.stylesAvatar : styles.avatar_img} src={props.src} alt={props.alt}/>
        </div>
    )
} 

export default Avatar;