/****************************
 * COMMON DATA DICTIONARIES *
 ****************************/

export const gemaSchools = ["esi", "ia-school", "cyber"];

export const studentRythme = ["part_time", "full_time"];

export const AcademicLevels = ["b1", "b2", "b3", "m1", "m2", "mba_1", "cesur_y"];

export const AcademicLabelsForLevels = {
    '1st': "Bachelor 1",
    '2nd': "Bachelor 2",
    '3rd': "Bachelor 3",
    '4th': "Mastère 1",
    '5th': "Mastère 2",
    'mba_1': "MBA",
    'cesur_y': "Césure"
}

export const campusIds = ["74", "1_paris", "75", "2_lyon", "76", "3_online", "77", "4_bordeaux", "78", "5_aix_marseille", "79", "6_lille", "80", "7_nantes", "82", "8_montpellier", "83", "9_toulouse", "84", "10_rennnes", "85", "11_strasbourg", "86", "12_paris_est"];

export const monthsLabel = [
    { month: "01", value: "jan" },
    { month: "02", value: "fév" },
    { month: "03", value: "mar" },
    { month: "04", value: "avr" },
    { month: "05", value: "mai" },
    { month: "06", value: "jun" },
    { month: "07", value: "jui" },
    { month: "08", value: "août" },
    { month: "09", value: "sep" },
    { month: "10", value: "oct" },
    { month: "11", value: "nov" },
    { month: "12", value: "déc" },
];

export const gradeLabels = {
    "4.00": {
        eval_label: "A (Excellent)",
        notes_label: "A (4)",
    },
    "3.50": {
        eval_label: "B (Très Bien)",
        notes_label: "B (3.5)",
    },
    "3.00": {
        eval_label: "C (Bien)",
        notes_label: "C (3)",
    },
    "2.50": {
        eval_label: "D (Assez bien)",
        notes_label: "D (2.5)",
    },
    "2.00": {
        eval_label: "E (Passable)",
        notes_label: "E (2)",
    },
    "1.00": {
        eval_label: "F (Insuffisant)",
        notes_label: "F (1)",
    },
    "0.00": {
        eval_label: "FX (Très insuffisant)",
        notes_label: "FX (0)",
    },
    null: {
        eval_label: "NE (Non-évalué)",
        notes_label: "Non-évalué",
    },
};

export const campusData = {
    0: {
        city: "",
        mainLabel: "",
        planningLabel: "",
    },
    74: {
        city: "Paris Ouest",
        mainLabel: "Campus de Paris Ouest",
        planningLabel: "(Campus de Paris Ouest)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    75: {
        city: "Lyon",
        mainLabel: "Campus de Lyon",
        planningLabel: "(Campus de Lyon)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    76: {
        city: "E-Learning",
        mainLabel: "Campus E-Learning",
        planningLabel: "(Campus E-Learning)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    77: {
        city: "Bordeaux",
        mainLabel: "Campus de Bordeaux",
        planningLabel: "(Campus de Bordeaux)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                 "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                 "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    78: {
        city: "Aix-Marseille",
        mainLabel: "Campus de Aix-Marseille",
        planningLabel: "(Campus de Aix-Marseille)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    79: {
        city: "Lille",
        mainLabel: "Campus de Lille",
        planningLabel: "(Campus de Lille)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    80: {
        city: "Nantes",
        mainLabel: "Campus de Nantes",
        planningLabel: "(Campus de Nantes)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    82: {
        city: "Montpellier",
        mainLabel: "Campus de Montpellier",
        planningLabel: "(Campus de Montpellier)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    83: {
        city: "Toulouse",
        mainLabel: "Campus de Toulouse",
        planningLabel: "(Campus de Toulouse)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    84: {
        city: "Rennes",
        mainLabel: "Campus de Rennes",
        planningLabel: "(Campus de Rennes)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    85: {
        city: "Strasbourg",
        mainLabel: "Campus de Strasbourg",
        planningLabel: "(Campus de Strasbourg)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    86: {
        city: "Paris Est",
        mainLabel: "Campus de Paris Est",
        planningLabel: "(Campus de Paris Est)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
};