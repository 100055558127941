/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import { useHttpRequest } from "../../hooks/httpRequest-hook";

import { StudentContext } from "../../context/student-context";

import FinancialStatus from "../../components/DossierFinancier/FinancialStatus/FinancialStatus";
import Timeline from "../../components/DossierFinancier/Timeline/Timeline";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

import styles from "./DossierFinancier.module.scss";

const DossierFinancier = () => {
  // Authentication context
  const auth = useContext(AuthContext);

  const { isLoading, sendRequest } = useHttpRequest();

  // App context
  const context = useContext(StudentContext);

  const [financialStatus, setFinancialStatus] = useState(false);
  const [timeline, setTimeline] = useState(true);
  const [commitments, setCommitments] = useState(false);

  useEffect(() => {
    const fetchCommitment = async () => {
      try {
        const commitmentUrl = `${process.env.REACT_APP_API_HOST}/df/dossier-financier`;
        const commitmentsFetch = await sendRequest(commitmentUrl, "GET", null, {
          Authorization: "Bearer " + auth.token,
        });
        setCommitments(commitmentsFetch);
      } catch (err) {
        console.error(err);
      }
    };
    fetchCommitment();
  }, []);

  return (
    <div className={styles.mainContainer}>
      {isLoading && (
        <div className="spinner">
          <LoadingSpinner textColor={styles.spinner_text_color} />
        </div>
      )}
      {!isLoading && commitments && (
        <div className={styles.dfContainer}>
              <div className={styles.navContainer}>
            <p
              className={financialStatus ? styles.navActivate : styles.nav}
              onClick={() => {
                setFinancialStatus(true);
                setTimeline(false);
              }}
            >
              STATUT FINANCIER
            </p>
            <p
              className={timeline ? styles.navActivate : styles.nav}
              onClick={() => {
                setFinancialStatus(false);
                setTimeline(true);
              }}
            >
              MON ECHEANCIER
            </p>
          </div>
          {financialStatus && commitments && <FinancialStatus commitments={commitments} />}
          {timeline && commitments && (
            <Timeline commitments={commitments} />
          )}
        </div>
      )}
    </div>
  );
};

export default DossierFinancier;
