import { createContext } from "react";

export const AuthContext = createContext({
    isLoggedIn: false,
    token: null,
    userRole: null,
    userType: null,
    userSituation: null,
    backToken: null,
    updateUserSituation: () => {},
    login: () => {},
    logout: () => {},
});
