import { useState, useCallback, useEffect } from "react";

export const useTeacherContext = () => {
    // UseState variables of the TeacherContext
    const [teacherAccounts, setTeacherAccounts] = useState(false);
    const [teacherSelectedAccount, setTeacherSelectedAccount] = useState(false);
    const [teacherPlaceId, setTeacherPlaceId] = useState(false);
    const [teacherCampusId, setTeacherCampusId] = useState(false);
    const [teacherCanInvoice, setTeacherCanInvoice] = useState(false);
    const [teacherIsFreelancer, setTeacherIsFreelancer] = useState(false);
    const [teacherVcard, setTeacherVcard] = useState(false);
    const [teacherCurrentAccountYear, setTeacherCurrentAccountYear] = useState(false);
    const [teacherCampuses, setTeacherCampuses] = useState(false);
    const [teacherMultipleCampuses, setTeacherMultipleCampuses] = useState(false);
    const [teacherCurrentMultipleCampuses, setTeacherCurrentMultipleCampuses] = useState(false);
    const [teacherName, setTeacherName] = useState(false);

    // teacherSetContext use of useCallback for avoiding infinite loops
    const setTeacherContext = useCallback(
        (teacherAccounts, teacherSelectedAccount, teacherPlaceId, teacherCampusId, teacherCanInvoice, teacherIsFreelancer, teacherVcard, teacherCurrentAccountYear, teacherCampuses, teacherMultipleCampuses, teacherCurrentMultipleCampuses, teacherName) => {
        
            // Assign Context State Variables
            setTeacherAccounts(teacherAccounts);
            setTeacherSelectedAccount(teacherSelectedAccount);
            setTeacherPlaceId(teacherPlaceId);
            setTeacherCampusId(teacherCampusId);
            setTeacherCanInvoice(teacherCanInvoice);
            setTeacherIsFreelancer(teacherIsFreelancer);
            setTeacherVcard(teacherVcard);
            setTeacherCurrentAccountYear(teacherCurrentAccountYear);
            setTeacherCampuses(teacherCampuses);
            setTeacherMultipleCampuses(teacherMultipleCampuses);
            setTeacherCurrentMultipleCampuses(teacherCurrentMultipleCampuses);
            setTeacherName(teacherName);

            // Verify Stock context data in the browser's sessionStorage
            const storedContext = JSON.parse(sessionStorage.getItem("teacherContext"));

            // Stock context data in the browser's sessionStorage
            if (!storedContext) {
                sessionStorage.setItem(
                    "teacherContext",
                    JSON.stringify({
                        teacherAccounts,
                        teacherSelectedAccount,
                        teacherPlaceId,
                        teacherCampusId,
                        teacherCanInvoice,
                        teacherIsFreelancer,
                        teacherVcard,
                        teacherCurrentAccountYear,
                        teacherCampuses,
                        teacherMultipleCampuses,
                        teacherCurrentMultipleCampuses,
                        teacherName,
                    })
                );
            }

        }, []);

    // Switch the teacher account to be used across the app
    const switchTeacherAccount = useCallback((teacherCurrentAccountYear) => {

        // Fetch sessionStorage
        const storedContext = JSON.parse(sessionStorage.getItem("teacherContext"));

        // Find Account Id by Accademic Year
        const teacherSelectedAccount = storedContext.teacherAccounts[teacherCurrentAccountYear];
        const teacherCurrentMultipleCampuses = storedContext.teacherMultipleCampuses[teacherCurrentAccountYear];
        
        // Set States
        setTeacherSelectedAccount(teacherSelectedAccount);
        setTeacherCurrentAccountYear(teacherCurrentAccountYear);
        setTeacherCurrentMultipleCampuses(teacherCurrentMultipleCampuses);

        // Stock Modified context Data
        sessionStorage.setItem(
            "teacherContext",
            JSON.stringify({
                ...storedContext,
                teacherSelectedAccount,
                teacherCurrentAccountYear,
                teacherCurrentMultipleCampuses,
            })
        );
    }, []);

    // setTeacherInvoiceDetails sets the user invoice account details for invoicing on the app
    const setTeacherInvoiceDetails = useCallback((teacherCanInvoice, teacherIsFreelancer) => {
        // Set States
        setTeacherCanInvoice(teacherCanInvoice);
        setTeacherIsFreelancer(teacherIsFreelancer);

        // Fetch sessionStorage
        const storedContext = JSON.parse(sessionStorage.getItem("teacherContext"));

        // Stock new context data
        sessionStorage.setItem(
            "teacherContext",
            JSON.stringify({
                ...storedContext,
                teacherCanInvoice,
                teacherIsFreelancer,
            })
        );
    }, []);

    // unsetTeacherContext remets tout à null et supprime l'objet du sessionStorage
    const unsetTeacherContext = useCallback(() => {
        setTeacherAccounts(null);
        setTeacherSelectedAccount(null);
        setTeacherPlaceId(null);
        setTeacherCampusId(null);
        setTeacherCanInvoice(null);
        setTeacherIsFreelancer(null);
        setTeacherVcard(null);
        setTeacherCurrentAccountYear(null);
        setTeacherCampuses(null);
        setTeacherMultipleCampuses(null);
        setTeacherCurrentMultipleCampuses(null);
        setTeacherName(null);
        sessionStorage.removeItem("teacherContext");
    }, []);

    // Auto-set context to the app using sessionStorage
    useEffect(() => {

        // autofetch user context in the sessionStorage
        const storedContext = JSON.parse(sessionStorage.getItem("teacherContext"));

        if (
            storedContext &&
            storedContext.teacherAccounts &&
            storedContext.teacherSelectedAccount &&
            storedContext.teacherPlaceId &&
            storedContext.teacherCampusId &&
            typeof storedContext.teacherCanInvoice !== null &&
            typeof storedContext.teacherIsFreelancer !== null &&
            storedContext.teacherVcard &&
            storedContext.teacherCurrentAccountYear &&
            storedContext.teacherCampuses &&
            storedContext.teacherMultipleCampuses &&
            storedContext.teacherCurrentMultipleCampuses &&
            storedContext.teacherName
        ) {
            setTeacherContext(
                storedContext.teacherAccounts,
                storedContext.teacherSelectedAccount,
                storedContext.teacherPlaceId,
                storedContext.teacherCampusId,
                storedContext.teacherCanInvoice,
                storedContext.teacherIsFreelancer,
                storedContext.teacherVcard,
                storedContext.teacherCurrentAccountYear,
                storedContext.teacherCampuses,
                storedContext.teacherMultipleCampuses,
                storedContext.teacherCurrentMultipleCampuses,
                storedContext.teacherName,
            );
        }
    }, [setTeacherContext]);

    return {
        teacherAccounts,
        teacherSelectedAccount,
        teacherPlaceId,
        teacherCampusId,
        teacherCanInvoice,
        teacherIsFreelancer,
        teacherVcard,
        teacherCurrentAccountYear,
        teacherCampuses,
        teacherMultipleCampuses,
        teacherCurrentMultipleCampuses,
        teacherName,
        switchTeacherAccount,
        setTeacherContext,
        setTeacherInvoiceDetails,
        unsetTeacherContext,
    };
};
