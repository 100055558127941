import { createContext } from "react";

export const TuteurContext = createContext({
    tuteurStatus: null,
    tuteurRestricted: null,
    tuteurCampusId: null,
    tuteurCurrentPeriod: null,
    tuteurProfilePhoto: null,
    tuteurName: null,
    tuteurFinStatus: null,
    tuteurLevel: null,
    tuteurSchool: null,
    tuteurAttestation: null,
    tuteurCertificate: null,
    tuteurNextYearLevel: null,
    tuteurNextYearCampus: null,
    tuteurRythme: null,
    setTuteurContext: () => {},
    unsetTuteurContext: () => {},
});
