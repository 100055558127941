import React from "react";

// Styles
import styles from "./LoadingSpinner.module.scss";

const InlineLoadingSpinner = (props) => {
    return (
        <div className={styles.in_line_loading_spinner__overlay}>
            <div className={styles.in_line_lds_dual_ring}></div>
        </div>
    );
};

export default InlineLoadingSpinner;
