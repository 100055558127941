/* eslint-disable eqeqeq */
import React, { useEffect } from "react";

import CheckBox from "../../../../CheckBox/CheckBox";
import Avatar from "../../../../Avatar/Avatar";
import Input from "../../../../Input/Input";
import styles from "./StudentEval.module.scss";

const StudentEval = (props) => {
    const toggleNoteHandler = (event) => {
        const targetId = event.currentTarget.id;

        props.setStudents((prevState) => {
            return prevState.map((student) => {
                if (targetId == student.id) {
                    if (student.isChecked == true) {
                        return {
                            ...student,
                            isChecked: false,
                        };
                    } else {
                        if (props.noteFormValue !== "" || props.commentFormValue !== "") {
                            props.inputHandler(`${props.id}_evalNote`, "", false);
                            props.inputHandler(`${props.id}_evalComment`, "", false);
                        }
                        return {
                            ...student,
                            isChecked: true,
                        };
                    }
                } else {
                    return student;
                }
            });
        });
    };

    // UseEffect qui permet de décocher ou cocher si on remplis un champ ou si on le vide

    useEffect(() => {
        if (props.isChecked == false) {
            if (props.noteFormValue !== "" || props.commentFormValue !== "") {
                props.setStudents((prevState) => {
                    return prevState.map((student) => {
                        if (student.id == props.id) {
                            return {
                                ...student,
                                isChecked: true,
                            };
                        } else {
                            return {
                                ...student,
                            };
                        }
                    });
                });
            } else {
                return;
            }
            // SI CASE COCHEE
        } else {
            if (props.noteFormValue == "" && props.commentFormValue == "") {
                props.setStudents((prevState) => {
                    return prevState.map((student) => {
                        if (student.id == props.id) {
                            return {
                                ...student,
                                isChecked: false,
                            };
                        } else {
                            return {
                                ...student,
                            };
                        }
                    });
                });
            } else {
                return;
            }
        }
        if (`${props.id}_evalNote` == "" || `${props.id}_evalComment` == "") {
            props.noteFormValue = "";
            props.commentFormValue = "";
        }
    }, [props.noteFormValue, props.commentFormValue]);

    return (
        <>
            <div className={styles.studentContainer} id={props.id}>
                <CheckBox
                    id={props.id}
                    key={props.id}
                    name={props.name}
                    isChecked={props.isChecked}
                    onChange={toggleNoteHandler}
                    value={props.isChecked}
                />
                <Avatar 
                    src={props.photo && props.photo !== 'no-photo.png' ? `${process.env.REACT_APP_DB_IMG_HOST}/photos/${props.photo}` : props.avatarSrc}
                    alt="ProfilPicture"
                    stylesAvatar={styles.avatar}
                />
                <span className={styles.studentName}>{props.studentName}</span>
            </div>
            <Input
                id={props.id + "_evalNote"}
                name={props.id + "_evalNote"}
                typeOfInput="select"
                type="text"
                styles={styles.noteInput}
                content={props.content}
                validators={props.validators}
                initialValue={props.formState.inputs[props.id + "_evalNote"].value}
                initialValid={props.formState.inputs[props.id + "_evalNote"].isValid}
                onInput={props.inputHandler}
            />
            <Input
                id={props.id + "_evalComment"}
                name={props.id + "_evalComment"}
                type="text"
                typeOfInput="textArea"
                placeholder="Commentaire..."
                styles={styles.commentInput}
                rows="1"
                cols="50"
                validators={props.validators}
                initialValue={props.formState.inputs[props.id + "_evalComment"].value}
                initialValid={props.formState.inputs[props.id + "_evalComment"].isValid}
                onInput={props.inputHandler}
            />
        </>
    );
};

export default StudentEval;
