import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useHttpRequest } from "../../../../hooks/httpRequest-hook";
import { AuthContext } from "../../../../context/auth-context";
import { StudentContext } from "../../../../context/student-context";

// Components
import LoadingSpinner from "../../../LoadingSpinner/LoadingSpinner";
import NotificationBanner from "../../../NotificationBanner/NotificationBanner";
import HttpMessagePrompt from "../../../HttpMessagePrompt/HttpMessagePrompt";
import ListHeader from "../../../ListHeader/ListHeader";
import GradesBlock from "../GradesBlock/GradesBlock";

// Icons
import warningIcon from "../../../../assets/images/warning.svg";
import qrCode from "../../../../assets/images/qr_code.png";

// Styles
import styles from "./Grades.module.scss";

const Grades = () => {

    // Authentication context
    const auth = useContext(AuthContext);

    // History context
    const history = useHistory();

    // Student context
    const { studentStatus, studentCurrentPeriod, studentRestricted } = useContext(StudentContext);

    // HTTP Request Hook
    const { isLoading, error, sendRequest } = useHttpRequest();

    // Fetched Student Absences
    const [studentGrades, setStudentGrades] = useState();

    const gradesConstructor = async (data = []) => {
        let gradesByYear = [];

        data.forEach((grade) => {
            let blockGradeIndex;
            gradesByYear.find((blockGrade, index) => {
                if (blockGrade.year === grade.school_year) blockGradeIndex = index;
            });

            if (blockGradeIndex !== undefined) gradesByYear[blockGradeIndex].grades.push({ ...grade });
            else {
                let gradeBlock = { year: grade.school_year, grades: [] };
                gradeBlock.grades.push({ ...grade });
                gradesByYear.push(gradeBlock);
            }
        });

        return gradesByYear;
    };

    // Fetch Absences
    useEffect(() => {
        const fetchGrades = async () => {
            try {
                const url = `${process.env.REACT_APP_API_STUDENT}/evaluation/grades/${studentCurrentPeriod}`;
                const rawGrades = await sendRequest(url, "GET", null, {
                    Authorization: "Bearer " + auth.token,
                });
                const grades = await gradesConstructor(rawGrades);
                setStudentGrades(grades);
            } catch (err) {
                console.error(err);
            }
        };
        if (studentRestricted == false) fetchGrades();
    }, []);

    if (studentRestricted == true)
        return (
            <NotificationBanner
                bannerIcon={warningIcon}
                bannerIconColor={styles.warning_icon_color}
                message={"-- Accès restreint -- Veuillez régulariser votre situation en cliquant ici >>>"}
                hasBtn={true}
                btnText={"Dossier Financier"}
                btnFnc={() => history.push("/dossier-financier")}
            />
        );

    return (
        <>
            {isLoading && (
                <div className="spinner">
                    <LoadingSpinner />
                </div>
            )}
            
            <div className={styles.container}>
                {/* <div className={styles.qrCodeContainer}>
                    <p className={styles.important}>⚠️ IMPORTANT ⚠️</p>
                    <p>Votre avis compte ! Merci de prendre 5 minutes de votre temps pour remplir ce questionnaire de satisfaction sur l'année écoulée :</p>
                    <p>Via le lien suivant : <a target="_blank" rel="noreferrer" href="https://satisfaction-formation.typeform.com/to/qfNiL4I7">https://satisfaction-formation.typeform.com/to/qfNiL4I7</a></p>
                    <div className={styles.qrCode_container}>
                        <p>Ou en scannant le QR Code : </p>
                        <img alt="qrCode" className={styles.qrCode} src={qrCode}></img>
                    </div>
                </div> */}
                    {!isLoading && error && <HttpMessagePrompt error={error} />}
                {!isLoading && studentGrades && studentGrades.length === 0 && (
                    <ListHeader
                        title="Vous n'avez pas encore de notes"
                        message="Elles seront affichées lors de la première evaluation."
                    />
                )}
                {!isLoading && studentGrades && studentGrades.length !== 0 && <GradesBlock grades={studentGrades} />}
    

                    
            </div>
           
        </>
    );
};

export default Grades;
