import React, { useContext } from "react";
import { AuthContext } from "../../../../../context/auth-context";
import { TeacherContext } from "../../../../../context/teacher-context";
import { useForm } from "../../../../../hooks/form-hook";
import { isRequired, MinLength, isIban, isAlphanumeric } from "../../../../../utils/validators";
import { useHttpRequest } from "../../../../../hooks/httpRequest-hook";

// Components
import ModalHeader from "../../../../ModalHeader/ModalHeader";
import ListHeader from "../../../../ListHeader/ListHeader";
import Input from "../../../../Input/Input";
import ActionBtn from "../../../../Buttons/ActionBtn/ActionBtn";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import HttpMessagePrompt from "../../../../HttpMessagePrompt/HttpMessagePrompt";

//Styles
import styles from "./IbanModal.module.scss";

const Iban = (props) => {
    
    // Authentication context
    const auth = useContext(AuthContext);
    
    // App context
    const context = useContext(TeacherContext);
    
    // Backend Request Hook
    const { isLoading, error, okHttp, sendRequest } = useHttpRequest();

    // Form State
    const [formState, inputHandler, multipleInputHandler] = useForm(
        {
            ibanNumber: {
                value: "", // Test value: FR7616958000017797393663682
                isValid: false,
            },
            bicIdentifier: {
                value: "",
                isValid: false,
            },
        },
        false
    );

    const postIbanHandler = async () => {
        
        // If form not valid
        if (!formState.isValid) return;

        // Send Data
        try {
            const data = {
                account_id: context.teacherSelectedAccount,
                campus: context.teacherCampusId,
                ibanNumber: formState.inputs.ibanNumber.value,
                bicIdentifier: formState.inputs.bicIdentifier.value,
            };
            const url = `${process.env.REACT_APP_API_HOST}/invoicing/bank-details`;
            await sendRequest(url, "PUT", JSON.stringify(data), {
                "Content-Type": "application/json",
                Authorization: "Bearer " + auth.token,
            });

            if (!context.teacherCanInvoice) context.setTeacherInvoiceDetails(true, true);
        } catch (err) {
            console.error(err);
        }
    };

    let closeBtn = (
        <ActionBtn
            id="close_attendance_list"
            btnType="contained"
            btnStyle={styles.btnStyles}
            activeBtnStyle={styles.btn_active}
            btnText="Fermer la fenêtre"
            textStyle={styles.btn_text}
            onClick={props.modalFunction}
        />
    );

    return (
        <article className={styles.container}>
            <ModalHeader
                headerColor={styles.banner_color}
                headerTitle={props.texts.header}
                closeBtn={props.modalFunction}
            />
            {isLoading && (
                <div className="spinner">
                    <LoadingSpinner textColor={styles.spinner_text_color} />
                </div>
            )}
            {error && (
                <HttpMessagePrompt
                    error={error}
                    btn={closeBtn}
                />
            )}
            {okHttp && (
                <HttpMessagePrompt
                    error={false}
                    btn={closeBtn}
                    message={props.texts.okMessage}
                />
            )}
            {!isLoading && !error && !okHttp && (
                <section className={styles.wrapper}>
                    <ListHeader title={props.texts.title} message={props.texts.message} />
                    <form style={{ display: "flex" }}>
                        <div style={{ width: "55%", marginRight: "4.8rem" }}>
                            <Input
                                typeOfInput="input"
                                id="ibanNumber"
                                name="ibanNumber"
                                type="text"
                                label="IBAN * :"
                                placeholder="FR7601010202030304040505123"
                                validators={[
                                    isRequired(),
                                    isAlphanumeric(),
                                    isIban(),
                                    MinLength(5),
                                ]}
                                errorText="Numéro d'IBAN incorrect"
                                initialValue={formState.inputs.ibanNumber.value}
                                initialValid={formState.inputs.ibanNumber.isValid}
                                onInput={inputHandler}
                            />
                        </div>
                        <div style={{ width: "38%" }}>
                            <Input
                                typeOfInput="input"
                                style={{ width: "70%" }}
                                id="bicIdentifier"
                                name="bicIdentifier"
                                type="text"
                                label="BIC * :"
                                placeholder="CAXXBBPP"
                                maxLength="11"
                                validators={[isRequired(), isAlphanumeric(), MinLength(8)]}
                                errorText="BIC invalide"
                                initialValue={formState.inputs.bicIdentifier.value}
                                initialValid={formState.inputs.bicIdentifier.isValid}
                                onInput={inputHandler}
                            />
                        </div>
                    </form>
                    <ActionBtn
                        id="post_iban"
                        btnType="contained"
                        btnStyle={styles.btnStyles}
                        activeBtnStyle={styles.btn_active}
                        btnText="Enregistrer mon IBAN"
                        textStyle={styles.btn_text}
                        onClick={postIbanHandler}
                    />
                </section>
            )}
        </article>
    );
};

export default Iban;
