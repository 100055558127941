/* eslint-disable eqeqeq */
import React, { useState, useEffect, useContext } from "react";
import { useHttpRequest } from "../../../../../hooks/httpRequest-hook";
import { AuthContext } from "../../../../../context/auth-context";
import { TeacherContext } from "../../../../../context/teacher-context";
import { fixWindowPosition } from "../../../../../utils/helperFunctions";
import { addMinutes, addDays } from "date-fns";

import ModalHeader from "../../../../ModalHeader/ModalHeader";
import AttendanceListHeader from "../AttendanceListHeader/AttendanceListHeader";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import ListHeader from "../../../../ListHeader/ListHeader";
import AttendanceListItem from "../../../../AttendanceListItem/AttendanceListItem";
import HttpMessagePrompt from "../../../../HttpMessagePrompt/HttpMessagePrompt";
import ActionBtn from "../../../../Buttons/ActionBtn/ActionBtn";


// Icons
import lockIcon from "../../../../../assets/images/lock-solid.svg";
import styles from "./AttendanceListToClose.module.scss";

const AttendanceListToClose = (props) => {
    // Authentication context
    const auth = useContext(AuthContext);

    // App context
    const context = useContext(TeacherContext);

    // Backend Request Hook
    const { isLoading, error, okHttp, sendRequest, resetHttpRequest } = useHttpRequest();

    // Course information
    const [courseSlot] = useState(props.props[0]);

    // Course Labels
    const [courseLabels, setCourseLabels] = useState();

    // Student Attendace list for respective Course
    const [attendanceList, setAttendanceList] = useState();

    const [modalView, setModalView] = useState();

    const [modalStatus, setModalStatus] = useState(false);


    // Attendance list saved locally
    const [attendanceListIsSaved, setAttendanceListIsSaved] = useState(false);

    // Function Helper
    const sortAlphabetically = async (array, param) => {
        return await array.sort((a, b) =>
            a[param].localeCompare(b[param], "fr", { ignorePunctuation: true })
        );
    };

    // Function Helper
    const formatStudentList = async (array) => {
        let labels = array[0].join(", ");
        let studentOldList = [];

        // Filter Part Time students only if the course is Full Time
        if (courseSlot.subcategory === "full_time") {
            array[1].forEach((student) => {
                if (student.rythme === "full_time") studentOldList.push(student);
            });
        } else studentOldList = array[1];

        const studentList = studentOldList;
        const list = await sortAlphabetically(studentList, "n_last");

        return { labels, list };
    };

    // Function Helper
    const findId = async (courseSlot, groupId) => {
        let courseId;
        courseSlot.groups.forEach((course) => {
            if (groupId == course.groupId) courseId = course.courseId;
        });
        
        return courseId;
    };

    useEffect(() => {
        const fetchStudentLists = async () => {
            // Check if there's a saved attendance list in localStorage
            const savedAttendance = JSON.parse(
                localStorage.getItem(
                    `${context.teacherSelectedAccount}-${courseSlot.date}-${courseSlot.begin_time}-${courseSlot.dbKey}`
                )
            );
            if (savedAttendance) {
                setCourseLabels(savedAttendance.labels);
                setAttendanceList(savedAttendance.list);
            } else {
                try {
                    // Fetch Attendance List

                    // Structure id's & URL
                    const group_ids = courseSlot.groups.map((data) => data.groupId).join();

                    const url = `${process.env.REACT_APP_API_HOST}/planning/attendance-list/${group_ids}`;

                    const fetchedList = await sendRequest(url, "GET", null, {
                        Authorization: "Bearer " + auth.token,
                    });

                    const course = await formatStudentList(fetchedList);

                    // Reset HTTP Request Hook
                    resetHttpRequest();

                    // Load states
                    setCourseLabels(course.labels);
                    setAttendanceList(course.list);
                } catch (err) {
                    console.log(err);
                }
            }
        };
        fetchStudentLists();
    }, []);

    const attendanceListModalHandler = (event) => {
        if (modalStatus === false) {
            fixWindowPosition(modalStatus);
            setModalView("attendanceList");
            // setCalendarSlotData(event);
            setModalStatus(true);
        } else {
            fixWindowPosition(modalStatus);
            // setCalendarSlotData();
            setModalStatus(false);
        }
    };


    const toggleAttendanceHandler = (event) => {
        const targetId = event.currentTarget.id;
        setAttendanceList((currentList) => {
            return currentList.map((student) => {
                if (targetId == student.id) {
                    return { ...student, hasAttended: !student.hasAttended };
                }
                return student;
            });
        });
    };

    const toggleDelayHandler = (event) => {
        const targetId = event.currentTarget.id;
        setAttendanceList((currentList) => {
            return currentList.map((student) => {
                if (targetId == student.id) {
                    return { ...student, hasDelay: !student.hasDelay };
                }
                return student;
            });
        });
    };

    const saveOnLocalStorageHandler = () => {
        localStorage.setItem(
            `${context.teacherSelectedAccount}-${courseSlot.date}-${courseSlot.begin_time}-${courseSlot.dbKey}`,
            JSON.stringify({ labels: courseLabels, list: attendanceList })
        );
        setAttendanceListIsSaved(true);
        props.modalFunction();
    };

    const structureData = async (array) => {
        let absentStudents = [];
        array.forEach(async (studentData) => {
            let courseId = await findId(courseSlot, studentData.groupId);
            if (studentData.status == 'suspendu' || studentData.status == 'exclu') {
                absentStudents.push({
                    studentId: studentData.id,
                    courseId: courseId,
                    groupId: studentData.groupId,
                    placeId: studentData.place_id,
                    status: studentData.status,
                    begin_date: courseSlot.date,
                    end_date: courseSlot.date,
                    begin_time: courseSlot.begin_time,
                    end_time: courseSlot.end_time,
                    property_3: courseSlot.dbKey,
                    hasAttended: false,
                    hasDelay: false,
                });
            } else {
                absentStudents.push({
                    studentId: studentData.id,
                    courseId: courseId,
                    groupId: studentData.groupId,
                    status: studentData.status,
                    placeId: studentData.place_id,
                    begin_date: courseSlot.date,
                    end_date: courseSlot.date,
                    begin_time: courseSlot.begin_time,
                    end_time: courseSlot.end_time,
                    property_3: courseSlot.dbKey,
                    hasAttended: studentData.hasDelay ? true : studentData.hasAttended,
                    hasDelay: studentData.hasDelay
                });
            }
        });
        return absentStudents;
    };

    const postAbsentsHandler = async (event) => {
        event.preventDefault();
        try {
            const data = [courseSlot.groups, await structureData(attendanceList)];
            const url = `${process.env.REACT_APP_API_HOST}/planning/absences`;
            await sendRequest(url, "POST", JSON.stringify(data), {
                "Content-Type": "application/json",
                Authorization: "Bearer " + auth.token,
            });

            // Update Course State
            props.updateEvents((prevEvents) => {
                return {
                    fetchedSpan: { ...prevEvents.fetchedSpan },
                    ...courseSlot, status: "realized",
                }
            });
            props.updateReady(true);
            if (okHttp) {
                const savedAttendance = JSON.parse(
                    localStorage.getItem(
                        `${context.teacherSelectedAccount}-${courseSlot.date}-${courseSlot.begin_time}-${courseSlot.dbKey}`
                    )
                );

                if (savedAttendance)
                    localStorage.removeItem(
                        `${context.teacherSelectedAccount}-${courseSlot.date}-${courseSlot.begin_time}-${courseSlot.dbKey}`
                    );
            }
        } catch (err) {
            console.error(err);
            localStorage.setItem(
                `${context.teacherSelectedAccount}-${courseSlot.date}-${courseSlot.begin_time}-${courseSlot.dbKey}`,
                JSON.stringify({ labels: courseLabels, list: attendanceList })
            );
        }
    };

    let closeBtn = (
        <ActionBtn
            id="close_attendance_list"
            btnType="contained"
            btnStyle={styles.btnStyles}
            activeBtnStyle={styles.btn_active}
            btnText="Fermer la fenêtre"
            textStyle={styles.btn_text}
            onClick={props.modalFunction}
        />
    );

    let btn;
    let instruction = "Veuillez décocher les élèves absents";
    let listStatus = true;

    const today = new Date();
    if (courseSlot.end > addDays(today, 1)) {
        instruction = "Cette liste sera ouverte le même jour du cours.";
        listStatus = false;
        btn = closeBtn;
    } else {
        if (addMinutes(courseSlot.end, -15) >= today) {
            btn = (
                <ActionBtn
                    id="save_attendance_list"
                    btnType="contained"
                    btnStyle={styles.btnStyles}
                    activeBtnStyle={styles.btn_active}
                    btnText="Fermer la fenêtre"
                    textStyle={styles.btn_text}
                    onClick={saveOnLocalStorageHandler}
                />
            );
        } else {
            btn = (
                <ActionBtn
                    id="post_attendance_list"
                    btnType="contained"
                    btnStyle={styles.btnStyles}
                    activeBtnStyle={styles.btn_active_alt}
                    icon={lockIcon}
                    iconColor={styles.btn_icon}
                    btnText="Clôturer l'émargement"
                    textStyle={styles.btn_text}
                    onClick={postAbsentsHandler}
                />
            );
        }
    }

    return (
        <article className={styles.modal_container}>
            {courseSlot && (
                <>
                    <ModalHeader
                        headerColor={styles.banner_color}
                        headerTitle="Liste d'émargement"
                        closeBtn={props.modalFunction}
                        furtherInfo={"Attention, il vous manque au moins un émargement à clôturer. Afin de le finaliser, merci de le faire depuis l'outils utilisé lors de la séance de cours"}
                    />
                    <AttendanceListHeader
                        dayLabel={courseSlot.dayLabel}
                        monthLabel={courseSlot.monthLabel}
                        title={courseSlot.title}
                        campus={courseSlot.campus}
                        hourLabel={courseSlot.hourLabel}
                        courseLabel={courseSlot.course_label}
                        location={courseSlot.location}
                        groups={courseLabels}
                    />
                </>
            )}
            {isLoading && (
                <div className="spinner">
                    <LoadingSpinner textColor={styles.spinner_text_color} />
                </div>
            )}
            {error && (
                <HttpMessagePrompt
                    error={error}
                    btn={closeBtn}
                    message="Ne vous inquietez pas, votre liste d’émargement a été sauvegardée. Elle sera affichée automatiquement lorsque vous cliquez sur le même créneau."
                />
            )}
            {okHttp && (
                <HttpMessagePrompt
                    error={false}
                    btn={closeBtn}
                    message="Votre liste d’émargement a été bien enregistrée."
                />
            )}
            {!isLoading &&
                !error &&
                !okHttp &&
                attendanceList &&
                courseSlot &&
                !attendanceListIsSaved && (
                    <section className={styles.list_header}>
                        <ListHeader
                            title={`Liste des élèves | (${attendanceList.length})`}
                            message={instruction}
                        />
                        <form className={styles.form}>
                            <div className={styles.attedanceDelayP}>
                                <p className={styles.attendanceColumn}>Présent(e)</p>
                                <p className={styles.delayColumn}>Retard</p>
                            </div>
                            {attendanceList &&
                                attendanceList.map((input) => {
                                    return (
                                        <AttendanceListItem
                                            key={input.id}
                                            id={input.id}
                                            value="ok"
                                            name={`${input.n_last}, ${input.n_first}`}
                                            attendance={input.hasAttended}
                                            onChange={toggleAttendanceHandler}
                                            delay={input.hasDelay}
                                            onDelayChange={toggleDelayHandler}
                                            listStatus={listStatus}
                                            status={input.status}
                                            photo={input.photo}
                                        />
                                    );
                                })}
                        </form>
                        <div className={styles.footer}>{btn}</div>
                    </section>
                )}
        </article>
    );
};

export default AttendanceListToClose;