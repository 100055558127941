import React from "react";

// Styles
import styles from "./TagLabel.module.scss";

/*
Expects ==>

    <TagLabel 
        label="text label"
        bgColor={styles.class_name}
        textColor={styles.class_name}
    />

*/

const TagLabel = (props) => {

    let label, bgStyle;
    switch (props.label) {
        case "realized":
            label = "Réalisé";
            bgStyle = styles.bg_realized;

            break;

        case "invoiced":
            label = "Facturé";
            bgStyle = styles.bg_invoiced;

            break;
    
        default:
            label = "À définir";
            bgStyle = null;

            break;
    }

    return (
        <div className={`${styles.label} ${bgStyle}`}>
            <p className={styles.text}>{label}</p>
        </div>
    );
};

export default TagLabel;
