import React from "react";
import { useWindowDimensions } from "../../../../../hooks/window-dimensions-hook";

// Icons
import linkIcon from "../../../../../assets/images/external-link-alt-solid.svg";

//Styles
import styles from "./AttendanceListHeader.module.scss";

const AttendanceList = (props) => {
    
    // Window Size Hook
    const { width } = useWindowDimensions();

    // link if campus e-learning
    let location, linkText;
    if (width <= 666) linkText = "Visio";
    else linkText = "Ouvrir la visioconférence";

    if (props.location) {
        if (props.location.search("http") !== -1) {
            location = (
                <a href={props.location} className={styles.link} target="_blank" rel="noreferrer">
                    <div className={styles.link_container}>
                        <p className={styles.link_text}>{linkText}</p>
                        <div className={styles.link_icon_container}>
                            <img src={linkIcon} className={styles.link_icon} alt="" />
                        </div>
                    </div>
                </a>
            );
        } else location = <div className={styles.classroom}>{`${props.location}`}</div>;
    }

    // Default view based on window width
    let header;
    if (width <= 767) {
        header = (
            <header className={styles.header}>
                <div className={styles.mobile_title_block}>
                    <div className={styles.date}>
                        <div className={styles.date_day}>{props.dayLabel}</div>
                        <div className={styles.date_month}>{props.monthLabel}</div>
                    </div>
                    <div className={styles.head}>
                        <h2 className={styles.head_title}>{props.courseLabel}</h2>
                    </div>
                </div>
                <div className={styles.mobile_info_block}>
                    <p className={styles.head_slot}>
                        {props.campus} {props.hourLabel}
                    </p>
                    {location}
                </div>
            </header>
        );
    } else {
        header = (
            <header className={styles.header}>
                <div className={styles.date}>
                    <div className={styles.date_day}>{props.dayLabel}</div>
                    <div className={styles.date_month}>{props.monthLabel}</div>
                </div>
                <div className={styles.head}>
                    <h2 className={styles.head_title}>{props.courseLabel}</h2>
                    <p className={styles.head_slot}>
                        {props.campus} {props.hourLabel}
                    </p>
                    {location}
                </div>
                <div className={styles.groups}>
                    <p className={styles.group_label}>Groupes :</p>
                    <p className={styles.group_names}>{props.groups}</p>
                </div>
            </header>
        );
    }

    return header;
};

export default AttendanceList;
