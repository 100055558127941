import React from "react";

// Styles
import styles from "./CollapsibleItem.module.scss";

const CollapsibleItem = ({ title, description }) => {
  return (
    <details className={styles.details}>
      <summary className={styles.summary}>{title}</summary>
      <p
        dangerouslySetInnerHTML={{
          __html: description,
        }}
        className={styles.text}
      ></p>
    </details>
  );
};

export default CollapsibleItem;
