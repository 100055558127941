/* eslint-disable default-case */
import React, { useState, useContext } from "react";
import { formatDate } from "../../../../utils/helperFunctions";
import { useWindowDimensions } from "../../../../hooks/window-dimensions-hook";

// Components
import CheckBox from "../../../CheckBox/CheckBox";
import TagBox from "../../../TagBox/TagBox";

// Styles
import styles from "./AbsencesListItem.module.scss";
import { AuthContext } from "../../../../context/auth-context";

const AbsencesListItem = ({ id, begin_date, begin_time, end_time, course, reason, onClick }) => {

    // Checkbox state
    const [isChecked, setIsChecked] = useState(false);
    const auth = useContext(AuthContext);


    const { width } = useWindowDimensions()

    let label, dateLabel, slotLabel, textColor, bgColor, checkbox;

    switch (reason) {

        case "absence_retard":
            label = "Absence retards";
            textColor = styles.tx_absence_lateness;
            bgColor = styles.bg_absence_lateness;

            break;

        case "to_justify":
            label = "À Justifier";
            textColor = styles.tx_to_justify;
            bgColor = styles.bg_to_justify;

            break;

        case "processing":
            label = "Traitement en cours";
            textColor = styles.tx_processing;
            bgColor = styles.bg_procession;

            break;

        case "justified":
            label = "Justifiée";
            textColor = styles.tx_justified;
            bgColor = styles.bg_justified;

            break;

        case "unrecevable":
            label = "Non recevable";
            textColor = styles.tx_to_justify;
            bgColor = styles.bg_to_justify;
            break;
    }

    if (reason === "to_justify" && auth.userType !== "tuteur") {
        checkbox = (
            <div className={styles.wrapper_checkBox}>
                <CheckBox
                    id={id}
                    key={id}
                    isChecked={isChecked}
                    onChange={(event) => {
                        setIsChecked(!isChecked);
                        onClick(event, !isChecked);
                    }}
                />
            </div>
        );
    }

    const splitBeginTime = begin_time ? begin_time.split(":") : "00";
    const splitEndTime = end_time ? end_time.split(":") : "00";

    if (width <= 767) {
        dateLabel = width <= 666 ? <b>D : </b> : <b>Date : </b>
        slotLabel = width <= 666 ? <b>C : </b> : <b>Créneau : </b>
    }

    return (
        <div className={styles.container}>
            {checkbox}
            <div className={styles.subwrapper}>
                <div className={styles.wrapper_subject}>
                    <h4 className={styles.title}>{course}</h4>
                </div>
                <div className={styles.subwrapper_responsive}>
                    <div className={styles.wrapper_item}>
                        <p className={styles.text}>{dateLabel}{formatDate(begin_date)}</p>
                    </div>
                    <div className={styles.wrapper_item}>
                        <p className={styles.text}>
                            {slotLabel}{`${splitBeginTime ? splitBeginTime[0] : ""}:${splitBeginTime ? splitBeginTime[1] : ''}h`} -{" "}
                            {`${splitEndTime ? splitEndTime[0] : ''}:${splitEndTime ? splitEndTime[1] : ''}h`}
                        </p>
                    </div>
                </div>
                <div className={styles.wrapper_tag}>
                    <TagBox label={label} textColor={textColor} bgColor={bgColor} />
                </div>
            </div>
        </div>
    );
};

export default AbsencesListItem;
