import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import { useHttpRequest } from "../../hooks/httpRequest-hook";

// Components
import HeaderBanner from "../../components/HeaderBanner/HeaderBanner";
import PostsList from "../../components/PostsList/PostsList";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import HttpMessagePrompt from "../../components/HttpMessagePrompt/HttpMessagePrompt";

// Icons
import newsIcon from "../../assets/images/news.svg";

// Styles
import styles from "./StudentNews.module.scss";

const StudentNews = () => {
    // Authentication context
    const auth = useContext(AuthContext);

    // Request Hook
    const { isLoading, error, sendRequest } = useHttpRequest();

    // Posts State
    const [posts, setPosts] = useState();

    // Fetch Posts
    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const url = `${process.env.REACT_APP_API_STUDENT}/blogs/posts/mygema_news`;
                const postsData = await sendRequest(url, "GET", null, {
                    Authorization: "Bearer " + auth.token,
                });
                setPosts(postsData);
            } catch (err) {
                console.error(err);
            }
        };
        fetchDocuments();
    }, []);

    return (
        <>
            {isLoading && (
                <div className="spinner">
                    <LoadingSpinner />
                </div>
            )}
            {error && (
                <div className="error_wrapper">
                    <HttpMessagePrompt error={error} />
                </div>
            )}
            {!isLoading && posts && (
                <main className="tab_container">
                    <HeaderBanner
                        title="News"
                        icon={newsIcon}
                        iconColor={styles.icon_color}
                        bgColor={styles.banner_color}
                    />
                    <PostsList postList={posts} />
                </main>
            )}
        </>
    );
};

export default StudentNews;
