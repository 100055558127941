import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../../context/auth-context";
import { StudentContext } from "../../../../context/student-context";
import { useHttpRequest } from "../../../../hooks/httpRequest-hook";
import { Link } from 'react-router-dom';


// Components
import LoadingSpinner from "../../../LoadingSpinner/LoadingSpinner";
import HttpMessagePrompt from "../../../HttpMessagePrompt/HttpMessagePrompt";
import NotificationBanner from "../../../NotificationBanner/NotificationBanner";
import DownloadDocBtn from "../../../Buttons/DownloadDocBtn/DownloadDocBtn";

// Icons
import downloadIcon from "../../../../assets/images/download-solid.svg";
import warningIcon from "../../../../assets/images/warning.svg";
import qrCode from "../../../../assets/images/qr_code.png";

// Styles
import styles from "./SchoolReports.module.scss";

const SchoolReports = () => {

    // Authentication context
    const auth = useContext(AuthContext);

    // Student context
    const { studentStatus, studentRestricted } = useContext(StudentContext);

    // History context
    const history = useHistory();

    // HTTP Request Hook
    const { isLoading, error, sendRequest } = useHttpRequest();

    // School Reports State
    const [StudentSchoolReports, SetStudentSchoolReports] = useState();

    const schoolReportsConstructor = async (obj) => {
        let reports = [];
        Object.entries(obj).forEach(([key, value]) => reports.push({ ...value }));  
        return reports;
    };

    useEffect(() => {
        console.log("debug 1");
        const fetchReports = async () => {
            try {
                console.log("debug 2");
                const url = `${process.env.REACT_APP_API_HOST}/profile/reports`;
                const fetchedReports = await sendRequest(url, "GET", null, {
                    Authorization: "Bearer " + auth.token,
                });
                const reports = await schoolReportsConstructor(fetchedReports);
                // const filterReport = reports.filter((r) => r.school_year !== "2023-2024");
                SetStudentSchoolReports(reports);
            } catch (err) {
                console.error(err);
            }
        };
        if ((auth.userType === "student" && studentRestricted == false) || auth.userType === 'tuteur') fetchReports();
    }, []);

    if (studentRestricted == true)
        return (
            <NotificationBanner
                bannerIcon={warningIcon}
                bannerIconColor={styles.warning_icon_color}
                message={"-- Accès restreint -- Veuillez régulariser votre situation en cliquant ici >>>"}
                hasBtn={true}
                btnText={"Dossier Financier"}
                btnFnc={() => history.push("/dossier-financier")}
            />
        );

    return (
        <>
            {isLoading && (
                <div className="spinner">
                    <LoadingSpinner />
                </div>
            )}
            {!isLoading && error && !StudentSchoolReports && (
                <div className={styles.container}>
                    <HttpMessagePrompt error="Aucun bulletin n'est disponible pour le moment !" />
                </div>
            )}
                {/* <div className={styles.qrCodeContainer}>
                    <p className={styles.important}>⚠️ IMPORTANT ⚠️</p>
                    <p>Votre avis compte ! Merci de prendre 5 minutes de votre temps pour remplir ce questionnaire de satisfaction sur l'année écoulée :</p>
                    <p>Via le lien suivant : <a target="_blank" rel="noreferrer" href="https://satisfaction-formation.typeform.com/to/qfNiL4I7">https://satisfaction-formation.typeform.com/to/qfNiL4I7</a></p>
                    <div className={styles.qrCode_container}>
                        <p>Ou en scannant le QR Code : </p>
                        <img alt="qrCode" className={styles.qrCode} src={qrCode}></img>
                    </div>
                </div> */}
            {!isLoading && StudentSchoolReports &&
                StudentSchoolReports.map(({ school_year }, index) => {
                    const link = `${process.env.REACT_APP_API_HOST}/documents/generate-documents/download-report?school_year=${school_year}`;
                    return (
                        
                        <DownloadDocBtn
                            key={index}
                            type="banner"
                            text={`Bulletin Année Scolaire ${school_year}`}
                            link={link}
                            icon={downloadIcon}
                            iconColor={styles.iconColor}
                        />
                        
                    );
                })}
                          
            {/* <div className={styles.container}>
                      
                <div className={styles.legend}>
                                        
                    <div>
                        <p className={styles.legend_titles}>DÉFAILLANT :</p>
                        <p className={styles.legend_defenition}>  
                            <b className={styles.legend_littletitle}>Sur une ou plusieurs  matières</b>
                            <p>J’ai au moins 3 absences dans cette matière.</p>
                            <b className={styles.legend_littletitle}>Sur l’ensemble des matières</b>
                            <p>J’ai au moins 20 absences sur mon semestre.</p>  
                            <i>La mention DÉFAILLANT implique le passage de la ou des matière.s lors de la session de rattrapage de Septembre.</i> 
                        </p>
                    </div>

                    <div>
                        <p className={styles.legend_titles}>RATTRAPAGE :</p>
                        <p className={styles.legend_defenition}>J'ai obtenu une moyenne de F ou Fx, je dois repasser la matière au rattrapage (modalités à voir avec la pédagogie)</p>                         
                    </div>

                    <div>
                        <p className={styles.legend_titles}>À RATTRAPER :</p>
                        <p className={styles.legend_defenition}>Malgré la session de rattrapage, j'ai à nouveau obtenu une note de F ou FX, je n'ai pas acquis la connaissance</p>   
                    </div>

                    <div>
                        <p className={styles.legend_titles}>RATTRAPÉ :</p>
                        <p className={styles.legend_defenition}>Après le rattrapage ma note est supérieure ou égale à E, je valide la matière</p>   
                    </div>

                </div>  
                <div>
                    <p className={styles.link}>Pour plus de détail sur la notation, nous vous invitons à consulter le document sur ce <a href="https://adm.mygema.com/img/ESI/Explications notes en lettres & rattrapages.pdf"> lien</a></p>                
                </div>               
            </div> */}
        </>
    );
};

export default SchoolReports;
