import React from "react";

// Styles
import styles from "./TagBox.module.scss";

/*
Expects ==>

    <TagBox 
        label="text"
        textColor={styles.color_class_name}
        bgColor={styles.bg_class_name}
    />

*/

const TagBox = ({label, bgColor, textColor}) => {

    return (
        <div className={`${styles.label} ${bgColor}`}>
            <p className={`${styles.text} ${textColor}`}>{label}</p>
        </div>
    );
};

export default TagBox;