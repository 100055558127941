import React from "react";
import ActionBtn from "../../../../Buttons/ActionBtn/ActionBtn";

import styles from "./NoteLine.module.scss";

const NoteLine = (props) => {

    const formatDate = (date) => {
        const datePart = date.split("-");
        return `${datePart[2]}/${datePart[1]}/${datePart[0]}`;
    };

    return (
        <div id={props.reportId + "_" + props.noteId} className={styles.link}>
            <span className={`${styles.span} ${styles.date}`}>{formatDate(props.noteDate)}</span>
            <span className={`${styles.span} ${styles.label}`}>{props.noteCategory}</span>
            <ActionBtn
                id="updateModal"
                btnType="contained"
                btnStyle={props.LinkbtnStyles}
                activeBtnStyle={props.LinkbtnStyles}
                icon={props.SearchIcon}
                iconColor={props.loupe}
                textStyle={props.btn_text}
                onClick={props.EvalModalHandler}
            />
        </div>
    );
};

export default NoteLine;
