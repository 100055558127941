import React, { useState } from "react";

// Components
import ActionBtn from "../../../../Buttons/ActionBtn/ActionBtn";

// Styles
import styles from "./ProfileTabs.module.scss";

const ProfileTabs = ({ menuConfig, tabViews }) => {
    
    const [navigation] = useState(menuConfig);
    const [selectedTab, setSelectedTab] = useState(menuConfig[0].id);
    const [tabs] = useState(tabViews);

    const changeTabHandler = (event) => setSelectedTab(event.currentTarget.id);

    return (
        <article className={styles.profilContainer}>
            <nav className={styles.nav_container}>
                {navigation &&
                    navigation.map(({id, btnType, btnText, textStyle}) => {
                        let activeBtn = (id === selectedTab) ? styles.activeBtn : styles.btn;
                        return (
                            <ActionBtn
                                id={id}
                                key={id}
                                btnType={btnType}
                                btnStyle={activeBtn}
                                btnText={btnText}
                                textStyle={textStyle || styles.btn_text}
                                onClick={changeTabHandler}
                            />
                        );
                    })}
            </nav>
            {tabs &&
                tabs.map(({ id, view }, index) => {
                    if (id === selectedTab) return <section key={index}>{view}</section>;
                })}
        </article>
    );
};

export default ProfileTabs;