import React from "react";

import styles from './HardLink.module.scss';

const HardLink = (props) => {
    return(
        <a
            className={styles.link}
            href={props.linkTo}
            target="_blank"
            rel="noreferrer"
        >{props.title}</a>
    )
};

export default HardLink;
