import React from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

// Components
import Backdrop from "../Backdrop/Backdrop";

//Styles
import styles from "./Modal.module.scss";

const ModalOverlay = (props) => {
    const content = (
        <div className={`${styles.container} ${props.className}`}>
            {props.overlay}
        </div>
    );
    return ReactDOM.createPortal(content, document.getElementById("modal-hook"));
};

const Modal = (props) => {
    return (
        <>
            {props.show && <Backdrop onClick={props.onClickFunction} />}
            <CSSTransition
                in={props.show}
                mountOnEnter
                unmountOnExit
                timeout={10}
                classNames={styles.modal}
            >
                <ModalOverlay {...props} />
            </CSSTransition>
        </>
    );
};

export default Modal;
