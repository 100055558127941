import React from "react";

// Styles
import styles from "./CheckBox.module.scss";

const CheckBox = (props) => {

    return (
        <label className={props.checkBoxStyles || styles.new_checkbox}>
            <input
                id={props.id}
                key={props.id}
                type="checkbox"
                name={props.name}
                value={props.checkboxValue}
                onClick={props.onVerification}
                onChange={props.onChange}
                checked={props.isChecked}
                disabled={props.disabled}
            />
            <span className={props.checkmark || styles.checkmark}></span>
        </label>
    );
};

export default CheckBox;
