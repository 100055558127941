import React from "react";

// Components
import ActionBtn from "../Buttons/ActionBtn/ActionBtn";

// Icons
import closeIcon from "../../assets/images/close_icon.svg";

//Styles
import styles from "./ModalHeader.module.scss";

const ModalHeader = (props) => {
    return (
        <div className={`${styles.banner} ${props.headerColor} ${props.furtherInfo ? styles.furtherInfoHeight : "" }`}>
            <p className={styles.banner_text}>{props.headerTitle}</p>
            <p className={styles.furtherInfo}>{props.furtherInfo}</p>
            <ActionBtn
                id="close-btn"
                btnType="text"
                btnStyle={styles.btn_style}
                icon={closeIcon}
                iconColor={styles.btn_icon}
                textStyle={styles.textStyle}
                onClick={props.closeBtn}
            />
        </div>
    );
};

export default ModalHeader;
