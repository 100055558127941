import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/auth-context";
import { TeacherContext } from "../../context/teacher-context";
import { ContractorContext } from "../../context/contractor-context";

// Components
import HeaderBanner from "../../components/HeaderBanner/HeaderBanner";
import TabView from "../../components/TabView/TabView";
import ListHeader from "../../components/ListHeader/ListHeader";
import Invoices from "../../components/Tabs/Teacher/InvoicingTabs/Invoices/Invoices";
import InvoiceHistory from "../../components/Tabs/Teacher/InvoicingTabs/InvoiceHistory/InvoiceHistory";
import ContractorInvoices from "../../components/Tabs/Contractor/ContractorInvoices/ContractorInvoices";
import Iban from "../../components/Tabs/Teacher/InvoicingTabs/Iban/Iban";

// Icons
import invoicingIcon from "../../assets/images/invoice.svg";

// Styles
import styles from "./Invoicing.module.scss";

const Invoicing = () => {
    // Authentication context
    const auth = useContext(AuthContext);

    // Teacher context
    const teacherContext = useContext(TeacherContext);

    // Contractor context
    const contractorContext = useContext(ContractorContext);

    // Tab Views
    const [tabMenus, setTabMenus] = useState();

    // Menu Tabs Buttons
    const [invoicingNav, setInvoicingNav] = useState();

    let menu;
    switch (auth.userType) {
        case "teacher":
            if (teacherContext.teacherCanInvoice) {
                menu = [
                    {
                        id: "create_invoice",
                        btnText: "facturer des créneaux",
                        btnType: "text",
                    },
                    {
                        id: "history_invoice",
                        btnText: "historique",
                        btnType: "text",
                    },
                    // {
                    //     id: "iban",
                    //     btnText: "Mon IBAN",
                    //     btnType: "text",
                    // },
                ];
            } else {
                menu = [
                    {
                        id: "create_invoice",
                        btnText: "facturer des créneaux",
                        btnType: "text",
                    },
                    {
                        id: "history_invoice",
                        btnText: "historique",
                        btnType: "text",
                    },
                ];
            }
            break;

        case "contractor":
            menu = [
                {
                    id: "create_invoice",
                    btnText: "facturer des créneaux",
                    btnType: "text",
                },
                // {
                //     id: "iban",
                //     btnText: "Mon IBAN",
                //     btnType: "text",
                // },
                {
                    id: "history_invoice",
                    btnText: "historique",
                    btnType: "text",
                },
            ];
            break;

        default:
            break;
    }

    const teacherTabs = [
        {
            id: "create_invoice",
            view: <Invoices />,
        },
        {
            id: "history_invoice",
            view: <InvoiceHistory setInvoicingNav={setInvoicingNav}/>,
        },
        {
            id: "iban",
            view: <Iban setInvoicingNav={setInvoicingNav} />,
        },
    ];

    const contractorTabs = [
        {
            id: "create_invoice",
            view: <ContractorInvoices />,
        },
        {
            id: "history_invoice",
            view: <InvoiceHistory setInvoicingNav={setInvoicingNav}/>,
        },
        {
            id: "iban",
            view: <Iban />,
        },
    ];

    useEffect(() => {
        switch (auth.userType) {
            case "teacher":
                setTabMenus(teacherTabs);
                setInvoicingNav(menu);
                break;
            case "contractor":
                setTabMenus(contractorTabs);
                setInvoicingNav(menu);
                break;
        }
    }, []);
    

    // INVOICING Closure for EMERGENCIES ONLY !
    // =========================================

    // let list;
    // if (auth.userRole === "admin") {
    //     list = (
    //         <TabView
    //             menuConfig={invoicingNav}
    //             tabViews={tabMenus}
    //             activeBtnColor={styles.btn_active}
    //         />
    //     );
    // } else
    //     list = (
    //         <section className={styles.subheader_wrapper}>
    //             <ListHeader title="Facturation fermée" />
    //         </section>
    //     );

    return (
        <>
            {tabMenus && invoicingNav && (
                <main className={styles.container}>
                    <HeaderBanner
                        title="Facturation"
                        icon={invoicingIcon}
                        iconColor={styles.icon_color}
                        bgColor={styles.banner_color}
                    />
                    <TabView
                        menuConfig={invoicingNav}
                        tabViews={tabMenus}
                        activeBtnColor={styles.btn_active}
                    />
                    {/* {list} */}
                </main>
            )}
        </>
    );
};

export default Invoicing;
