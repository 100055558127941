import React from "react";
import styles from "./OffresTable.module.scss";

const OffersTable = ({ offers, onOfferClick }) => {
  
  const renderOfferRow = (offer, index) => {
    const { status, type, icon, link, title, iconColor, hashtag, archive, adr_zip } =
      offer;
    const titleArray = title.split("|").map((item) => item.trim());
    const dateMaxArray = titleArray[0].split(" ").map((item) => item.trim());
    const maxVisibilityDate = dateMaxArray[4];
    const isArchived = archive ? true : false;
    return (
      <tr key={index} className={isArchived ? styles.archivedOffer : styles.offer}>
        <td>{titleArray[5]}</td> {/*Date de publication */}
        <td>{titleArray[1]}</td> {/*Entreprise */}
        <td>{titleArray[3]}</td> {/* Type de Contrat*/}
        <td>{titleArray[2]}</td> {/* Titre de l'offre */}
        <td>{titleArray[4]}</td> {/* Ville RH*/}
        <td>{adr_zip}</td> {/* Ville RH*/}
        <td>{maxVisibilityDate}</td> {/*Date max pour postuler */}
        {!isArchived ? (
          <td>
            <button
              className={styles.viewOfferButton}
              onClick={() => onOfferClick(offer)}
            >
              Voir l'offre
            </button>
          </td>
        ) : (
          <td></td>
        )}
      </tr>
    );
  };

  return (
    <table className={styles.offersTable}>
      <thead>
       <tr>
        <th>Date de publication</th> 
        <th>Entreprise</th>
        <th>Type de contrat</th>
        <th>Intitulé du poste</th>
        <th>Lieu</th>
        <th>Code Postal</th>
        <th>Date max pour postuler</th>
        <th></th>
        </tr>
      </thead>
      <tbody>
        {offers.map((offer, index) => renderOfferRow(offer, index))}
      </tbody>
    </table>
  );
};

export default OffersTable;
