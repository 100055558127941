import React, { useState, useContext }  from "react";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { AuthContext } from "../../../context/auth-context";

// Styles
import styles from "./ActionBtn.module.scss";
import { red } from "@mui/material/colors";

/*
Constructor ==>

<ActionBtn 
    id="login_btn"
    form="login_form" 
    type="submit"
    btnType="contained || outlined || text"
    btnStyle={styles.btnStyles}
    activeBtnStyle={styles.btnStyles}
    btnMargins={styles.margins}
    icon={iconName}
    iconColor={styles.iconStyles}
    btnText="Log In"
    textStyle={styles.textStyle}
    onClick={onClickFunction()}
/>

*/

const ActionBtn = (props) => {
    let icon,
        text,
        btnType,
        btnStyle = "",
        activeBtnStyle = "",
        btnMargins = "";

    // Authentication context
    const auth = useContext(AuthContext);

    const [notificationAlert, setNotificationAlert] = useState(props.notificationAlert === "true" ? true : false);

    if (props.icon) {
        icon = (
            <img
                id="icon"
                className={`${styles.icon} ${props.iconColor}`}
                src={props.icon}
                alt=""
            />
        );
    }

    if (props.btnText) {
        text = <span className={props.textStyle}>{props.btnText}</span>;
    }

    if (props.btnStyle) btnStyle = props.btnStyle;
    if (props.activeBtnStyle) activeBtnStyle = props.activeBtnStyle;
    if (props.btnMargins) btnMargins = props.btnMargins;

    switch (props.btnType) {
        case "contained":
            btnType = styles.contained;
            break;
        case "outlined":
            btnType = styles.outlined;
            break;
        case "text":
            btnType = styles.text;
            break;
        default:
            btnType = styles.contained;
            break;
    }

    return (
        <button
            id={props.id}
            className={`${btnType} ${btnStyle} ${activeBtnStyle} ${btnMargins}`}
            type={props.type}
            form={props.form}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            <div className={styles.block}  
                onClick={() => {
                    if (auth.userRole != 'admin') {
                        localStorage.setItem("studentNotificationAlert", false);
                    }
                }}
            >
                {icon}
                {text}
            </div>
            {notificationAlert && (
                <div className={styles.relativeParent}>
                    <NewReleasesIcon
                        className={styles.notificationAlert}
                        fontSize="large"
                        sx={{ color: red[500] }}
                        />
                </div>
            )}
        </button>
    );
};

export default ActionBtn;
