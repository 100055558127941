import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import { useHttpRequest } from "../../hooks/httpRequest-hook";

// Components
import HeaderBanner from "../../components/HeaderBanner/HeaderBanner";
import CollapsibleList from "../../components/CollapsibleList/CollapsibleList";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import HttpMessagePrompt from "../../components/HttpMessagePrompt/HttpMessagePrompt";

// Icons
import faqIcon from "../../assets/images/faq.svg";

// Styles
import styles from "./StudentFAQ.module.scss";

const StudentFAQ = () => {

    // Authentication context
    const auth = useContext(AuthContext);

    // Request Hook
    const { isLoading, error, sendRequest } = useHttpRequest();

    // FAQ State
    const [faq, setFaq] = useState();

    // Fetch Posts
    useEffect(() => {
        const fetchFaq = async () => {

            try {
                const url = `${process.env.REACT_APP_API_STUDENT}/faqs/student/faq/mygema_faq`;
                const faqData = await sendRequest(url, "GET", null, {
                    Authorization: "Bearer " + auth.token,
                });

                setFaq(faqData);
            } catch (err) {
                console.error(err);
            }
        };
        fetchFaq();
    }, []);

    return (
        <>
            {isLoading && (
                <div className="spinner">
                    <LoadingSpinner />
                </div>
            )}
            {error && (
                <div className="error_wrapper">
                    <HttpMessagePrompt error={error} />
                </div>
            )}
            {!isLoading && faq && (
                <main className={styles.container}>
                    <HeaderBanner
                        title="FAQ - Pôle pédagogique"
                        icon={faqIcon}
                        iconColor={styles.icon_color}
                        bgColor={styles.banner_color}
                    />
                    <CollapsibleList CollapsibleList={faq} />
                </main>
            )}
        </>
    );
};

export default StudentFAQ;
