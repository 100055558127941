import React, { useState, useContext, useEffect } from "react";
import { useHttpRequest } from "../../../../hooks/httpRequest-hook";
import { AuthContext } from "../../../../context/auth-context";
import { structureConfigDocuments } from "../../../../utils/helperFunctions";

// Components
import DocumentsListDisplay from "../../../GenericTabs/DocumentsListDisplay/DocumentsListDisplay";
import LoadingSpinner from "../../../LoadingSpinner/LoadingSpinner";

// Icons
import downloadIcon from "../../../../assets/images/download-solid.svg";
import editIcon from "../../../../assets/images/edit-solid.svg";

// Styles
import styles from "./StudentCertifications.module.scss";

const StudentCertifications = () => {
    // Authentication context
    const auth = useContext(AuthContext);

    // Backend Request Hook
    const { isLoading, sendRequest } = useHttpRequest();

    // Fetched Documents
    const [documentLibrary, SetDocumentLibrary] = useState();

    // Fetch Documents
    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const url = `${process.env.REACT_APP_API_STUDENT}/documents/config/studies_management`;
                const documents = await sendRequest(url, "GET", null, {
                    Authorization: "Bearer " + auth.token,
                });

                const structuredDocuments = await structureConfigDocuments(documents, editIcon, downloadIcon, styles.download_icon_color);

                SetDocumentLibrary(structuredDocuments.mygema_certification);
            } catch (err) {
                console.error(err);
            }
        };
        fetchDocuments();
    }, []);

    return (
        <>
            {isLoading && (
                <div className="spinner">
                    <LoadingSpinner />
                </div>
            )}
            {
                !isLoading && documentLibrary && <DocumentsListDisplay id={"mygema_certification"} content={documentLibrary} />
            }
        </>
    );
};

export default StudentCertifications;
