import { useState, useCallback, useEffect } from "react";

export const useStudentContext = () => {

    // UseState variables of the TeacherContext
    const [studentStatus, setStudentStatus] = useState(false);
    const [studentRestricted, setStudentRestricted] = useState(false);
    const [studentCampusId, setStudentCampusId] = useState(false);
    const [studentCurrentPeriod, setStudentCurrentPeriod] = useState(false);
    const [studentProfilePhoto, setStudentProfilePhoto] = useState(false);
    const [studentName, setStudentName] = useState(false);
    const [studentFinStatus, setStudentFinStatus] = useState(false);
    const [studentLevel, setStudentLevel] = useState(false);
    const [studentSchool, setStudentSchool] = useState(false);
    const [studentAttestation, setStudentAttestation] = useState(false);
    const [studentCertificate, setStudentCertificate] = useState(false);
    const [studentNextYearLevel, setStudentNextYearLevel] = useState(false);
    const [studentNextYearCampus, setStudentNextYearCampus] = useState(false);
    const [studentRythme, setStudentRythme] = useState(false);
    const [studentOffresRestriction, setStudentOffresRestriction] = useState(false);
    const [studentNotificationHome, setStudentNotificationHome] = useState(false);

    // setStudentContext use of useCallback for avoiding infinite loops
    const setStudentContext = useCallback(
        (studentStatus, studentRestricted, studentCampusId, studentCurrentPeriod, studentProfilePhoto, studentName, studentFinStatus, studentLevel, studentSchool, studentAttestation, studentCertificate, studentNextYearLevel, studentNextYearCampus, studentRythme, studentOffresRestriction, studentNotificationHome) => {

            // Assign Context State Variables
            setStudentStatus(studentStatus);
            setStudentRestricted(studentRestricted);
            setStudentCampusId(studentCampusId);
            setStudentCurrentPeriod(studentCurrentPeriod);
            setStudentProfilePhoto(studentProfilePhoto);
            setStudentName(studentName);
            setStudentFinStatus(studentFinStatus);
            setStudentLevel(studentLevel);
            setStudentSchool(studentSchool);
            setStudentAttestation(studentAttestation);
            setStudentCertificate(studentCertificate);
            setStudentNextYearLevel(studentNextYearLevel);
            setStudentNextYearCampus(studentNextYearCampus);
            setStudentRythme(studentRythme);
            setStudentOffresRestriction(studentOffresRestriction);
            setStudentNotificationHome(studentNotificationHome);

            // Check Stock context data in the browser's sessionStorage
            const storedContext = JSON.parse(sessionStorage.getItem("studentContext"));
            const localStoredContext = JSON.parse(localStorage.getItem("studentContext"));

            // Stock context data in the browser's sessionStorage
            if (!storedContext) {
                sessionStorage.setItem(
                    "studentContext",
                    JSON.stringify({
                        studentStatus,
                        studentRestricted,
                        studentCampusId,
                        studentCurrentPeriod,
                        studentProfilePhoto,
                        studentName,
                        studentFinStatus,
                        studentLevel,
                        studentSchool,
                        studentAttestation,
                        studentCertificate,
                        studentNextYearLevel,
                        studentNextYearCampus,
                        studentRythme,
                        studentOffresRestriction,
                    })
                );
            }

            const notificationStorage = localStorage.getItem("studentNotificationHome");

            if (!notificationStorage) {
                localStorage.setItem("studentNotificationHome", parseInt(studentNotificationHome));
                localStorage.setItem("studentNotificationAlert", true);
            }

            if (notificationStorage != studentNotificationHome) {
                localStorage.setItem("studentNotificationHome", studentNotificationHome);
                localStorage.setItem("studentNotificationAlert", true);
            }
        }, []);

    // unsetStudentContext remets tout à null et supprime l'objet du sessionStorage
    const unsetStudentContext = useCallback(() => {
        setStudentStatus(null);
        setStudentRestricted(null);
        setStudentCampusId(null);
        setStudentCurrentPeriod(null);
        setStudentProfilePhoto(null);
        setStudentName(null);
        setStudentFinStatus(null);
        setStudentLevel(null);
        setStudentSchool(null);
        setStudentAttestation(null);
        setStudentCertificate(null);
        setStudentNextYearLevel(null);
        setStudentNextYearCampus(null);
        setStudentRythme(null);
        setStudentOffresRestriction(null);
        
        sessionStorage.removeItem("studentContext");
    }, []);

    // Auto-set context to the app using sessionStorage
    useEffect(() => {
        
        // autofetch user context in the sessionStorage
        const storedContext = JSON.parse(sessionStorage.getItem("studentContext"));

        if (
            storedContext &&
            storedContext.studentStatus &&
            storedContext.studentRestricted &&
            storedContext.studentCampusId &&
            storedContext.studentCurrentPeriod &&
            storedContext.studentProfilePhoto &&
            storedContext.studentName &&
            storedContext.studentFinStatus &&
            storedContext.studentLevel &&
            storedContext.studentSchool &&
            storedContext.studentAttestation &&
            storedContext.studentCertificate &&
            storedContext.studentNextYearLevel &&
            storedContext.studentNextYearCampus &&
            storedContext.studentRythme &&
            storedContext.studentOffresRestriction
        ) {
            setStudentContext(
                storedContext.studentStatus,
                storedContext.studentRestricted,
                storedContext.studentCampusId,
                storedContext.studentCurrentPeriod,
                storedContext.studentProfilePhoto,
                storedContext.studentName,
                storedContext.studentFinStatus,
                storedContext.studentLevel,
                storedContext.studentSchool,
                storedContext.studentAttestation,
                storedContext.studentCertificate,
                storedContext.studentNextYearLevel,
                storedContext.studentNextYearCampus,
                storedContext.studentRythme,
                storedContext.studentOffresRestriction
            );
        }
    }, [setStudentContext]);

    return {
        studentStatus,
        studentRestricted,
        studentCampusId,
        studentCurrentPeriod,
        studentProfilePhoto,
        studentName,
        studentFinStatus,
        studentLevel,
        studentSchool,
        studentAttestation,
        studentCertificate,
        studentNextYearLevel,
        studentNextYearCampus,
        studentRythme,
        studentOffresRestriction,
        setStudentContext,
        unsetStudentContext,
    };
};
