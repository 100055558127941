import { verifyIBAN, verifyGemaEmail } from "./helperFunctions";

const BLANK = "BLANK";
const REQUIRED = "REQUIRED";
const MINLENGTH = "MINLENGTH";
const MAXLENGTH = "MAXLENGTH";
const MIN = "MIN";
const MAX = "MAX";
const EMAIL = "EMAIL";
const GEMAEMAIL = "GEMAEMAIL";
const FILE = "FILE";
const TEXT = "TEXT";
const IBAN = "IBAN";
const ALPHANUMERIC = "ALPHANUMERIC";
const NUMBER = "NUMBER";
const CVEC = "CVEC"


export const isBlank = () => ({ type: BLANK });

export const isRequired = () => ({ type: REQUIRED });

export const isFile = () => ({ type: FILE });

export const MinLength = (val) => ({ type: MINLENGTH, val: val });

export const MaxLength = (val) => ({ type: MAXLENGTH, val: val });

export const isMin = (val) => ({ type: MIN, val: val });

export const isMax = (val) => ({ type: MAX, val: val });

export const isText = () => ({ type: TEXT });

export const isAlphanumeric = () => ({ type: ALPHANUMERIC });

export const isCvec = () => ({ type: CVEC });

export const isNumber = () => ({ type: NUMBER });

export const isEmail = () => ({ type: EMAIL });

export const isGemaEmail = () => ({ type: GEMAEMAIL });

export const isIban = () => ({ type: IBAN });

export const validate = (value, validators) => {
    let isValid = true;

    for (const validator of validators) {
        if (validator.type === BLANK) return isValid;

        if (validator.type === REQUIRED) {
            isValid = isValid && value.trim().length > 0;
        }

        if (validator.type === MINLENGTH) {
            isValid = isValid && value.trim().length >= validator.val;
        }

        if (validator.type === MAXLENGTH) {
            isValid = isValid && value.trim().length <= validator.val;
        }

        if (validator.type === MIN) {
            isValid = isValid && +value >= validator.val;
        }

        if (validator.type === MAX) {
            isValid = isValid && +value <= validator.val;
        }

        if (validator.type === TEXT) {
            isValid = isValid && /^[A-ZÀàÂâÆÇÉéÈèÊêËëÏïÎîÔôŒÙùÛûÜüŸÿ!?:; '-.]+$/i.test(value);
        }

        if (validator.type === ALPHANUMERIC) {
            isValid = isValid && /^[A-ZÀàÂâÆÇÉéÈèÊêËëÏïÎîÔôŒÙùÛûÜüŸÿ 0-9 '-.]+$/i.test(value);
        }

        if (validator.type === CVEC) {
            isValid = isValid && /^[A-Z]{3}\d[A-Z]{6}\d{2}$/i.test(value);
        }

        if (validator.type === NUMBER) {
            isValid = isValid && /^[0-9]+$/i.test(value);
        }

        if (validator.type === EMAIL) {
            isValid = isValid && /^\S+@\S+\.\S+$/.test(value);
        }
        
        if (validator.type === GEMAEMAIL) {
            isValid = isValid && verifyGemaEmail(value);
        }

        if (validator.type === IBAN) {
            isValid = isValid && verifyIBAN(value);
        }
    }

    return isValid;
};
