import React, { useState, useEffect, useContext } from "react";
import { useHttpRequest } from "../../hooks/httpRequest-hook";
import { AuthContext } from "../../context/auth-context";
import { StudentContext } from "../../context/student-context";
import { structureConfigDocuments } from "../../utils/helperFunctions";

// Components
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import HttpMessagePrompt from "../../components/HttpMessagePrompt/HttpMessagePrompt";
import HeaderBanner from "../../components/HeaderBanner/HeaderBanner";
import TabView from "../../components/TabView/TabView";
import DocumentListDisplay from "../../components/GenericTabs/DocumentsListDisplay/DocumentsListDisplay";
import MyGemaContact from "../MyGemaContact/MyGemaContact";

// Icons
import studentAdminResourcesIcon from "../../assets/images/ressource_administrative.svg";
import teacherAdminResourcesIcon from "../../assets/images/manager.svg";
import downloadIcon from "../../assets/images/download-solid.svg";
import editIcon from "../../assets/images/edit-solid.svg";

// Styles
import styles from "./Resources.module.scss";

const Resources = () => {
    // Authentication context
    const auth = useContext(AuthContext);

    // Student context
    const studentContext = useContext(StudentContext);

    // Backend Request Hook
    const { isLoading, error, sendRequest } = useHttpRequest();

    const forbiddenStatus = ['alumni', 'sortant'];

    // Navs
    const StudentNav = [
        { id: "mygema_calendrier", btnText: "Calendrier", btnType: "text", disabled: (forbiddenStatus.includes(studentContext.studentStatus) ? true : false)},
        { id: "mygema_reglement", btnText: "Règlement Intérieur", btnType: "text", disabled: (forbiddenStatus.includes(studentContext.studentStatus) ? true : false)},
        { id: "mygema_programme", btnText: "Programme Pédagogique", btnType: "text", disabled: (forbiddenStatus.includes(studentContext.studentStatus) ? true : false)},
        { id: "mygema_consigne_projet", btnText: "Consignes et Projets", btnType: "text", disabled: (forbiddenStatus.includes(studentContext.studentStatus) ? true : false)},
        { id: "mygema_certificates", btnText: "Certificat de Scolarité", btnType: "text" },
        { id: "mygema_attestations", btnText: "Attestation d'inscription", btnType: "text", disabled: (forbiddenStatus.includes(studentContext.studentStatus) ? true : false)},
        { id: "mygema_contact", btnText: "Contacts", btnType: "text" },
    ];

    const TeacherNav = [
        { id: "contrat", btnText: "Contrat", btnType: "text" },
        { id: "dates_cles", btnText: "Dates Clés", btnType: "text" },
        { id: "programmes_pedagogiques", btnText: "Programme", btnType: "text" },
        { id: "documents", btnText: "Documents", btnType: "text" },
        { id: "kit_comm", btnText: "Kit Comm", btnType: "text" },
    ];

    const poleLabels = useState([
        {
            label: "pedagogie",
            default: "PÔLE PÉDAGOGIQUE",
        },
        {
            label: "cre",
            default: "PÔLE CARRIÈRES ET ENTREPRISES",
        },
        {
            label: "admissions",
            default: "PÔLE ADMISSIONS",
        },
        {
            label: "admin",
            default: "PÔLE ADMINISTRATIF ET FINANCIER",
        },
        {
            label: "marketing",
            default: "PÔLE MARKETING ET COMMUNICATION",
        },
        {
            label: "direction",
            default: "DIRECTION GÉNÉRALE",
        },
        {
            label: "accueil",
            default: "ACCUEIL",
        },
    ]);

    const loadAndStructure = async (content) => {
        // Bout de code qui va restructurer le content reçu en tableau de tableaux pour chaque pôle ainsi que modifier le nom des pôles avec poleLabels.
        content.map((item) => {
            poleLabels[0].forEach((label) => {
                if (item.chapter === label.label) {
                    item.chapter = label.default;
                }
            });
        });
        return content;
    };

    const [resourcesNav] = useState(auth.userType === "student" ? StudentNav : TeacherNav);
    const [documentLibrary, SetDocumentLibrary] = useState();
    const [tabViews, SetTabViews] = useState();

    // Fetch Documents
    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const url = `${process.env.REACT_APP_API_STUDENT}/documents/config/admin_resources`;
                const documents = await sendRequest(url, "GET", null, {
                    Authorization: "Bearer " + auth.token,
                });

                let myGemaContact;
                if (documents.mygema_contact) {
                    myGemaContact = await loadAndStructure(documents.mygema_contact);
                    delete documents.mygema_contact;
                }

                let tabViewArray = [];

                const structuredDocuments = await structureConfigDocuments(
                    documents,
                    editIcon,
                    downloadIcon,
                    styles.download_icon_color,
                    studentContext.studentAttestation
                );

                // Create View Tabs
                Object.entries(structuredDocuments).forEach(([key, value]) => {
                    if (key !== "mygema_contact") {
                        tabViewArray.push({
                            id: key,
                            view: <DocumentListDisplay id={key} content={value} />,
                        });
                    }
                });

                tabViewArray.push({
                    id: "mygema_contact",
                    view: (
                        <MyGemaContact
                            id="mygema_contact"
                            content={myGemaContact}
                            poles={poleLabels}
                        />
                    ),
                });
                SetDocumentLibrary(structuredDocuments);
                SetTabViews(tabViewArray);
            } catch (err) {
                console.error(err);
            }
        };
        fetchDocuments();
    }, []);

    return (
        <>
            {isLoading && (
                <div className="spinner">
                    <LoadingSpinner />
                </div>
            )}
            {error && (
                <div className="error_wrapper">
                    <HttpMessagePrompt error={error} />
                </div>
            )}
            {!isLoading && resourcesNav && documentLibrary && tabViews && (
                <main className={styles.container}>
                    <HeaderBanner
                        title="Ressources Administratives"
                        icon={
                            auth.userType === "student"
                                ? studentAdminResourcesIcon
                                : teacherAdminResourcesIcon
                        }
                        bgColor={
                            auth.userType === "student"
                                ? styles.student_banner_color
                                : styles.teacher_banner_color
                        }
                    />
                    <TabView
                        menuConfig={resourcesNav}
                        tabViews={tabViews}
                        activeBtnColor={
                            auth.userType === "student"
                                ? styles.student_btn_active
                                : styles.teacher_btn_active
                        }
                    />
                </main>
            )}
        </>
    );
};

export default Resources;
