import React, { useState, useContext } from "react";
import { useHttpRequest } from "../../../../../hooks/httpRequest-hook";
import { AuthContext } from "../../../../../context/auth-context";
import { TeacherContext } from "../../../../../context/teacher-context";

// Components
import ModalHeader from "../../../../ModalHeader/ModalHeader";
import AttendanceListHeader from "../AttendanceListHeader/AttendanceListHeader";
import ActionBtn from "../../../../Buttons/ActionBtn/ActionBtn";

// Styles
import styles from "./DetailPlanning.module.scss";

const DetailPlanning = (props) => {
    // Authentication context
    const auth = useContext(AuthContext);

    // App context
    const context = useContext(TeacherContext);

    // Course information
    const [courseSlot] = useState(props.data);

    // Course Labels
    const [courseLabels, setCourseLabels] = useState();

    // Student Attendace list for respective Course
    const [attendanceList, setAttendanceList] = useState();

    return (
        <article className={styles.modal_container}>
            {courseSlot && (
                <>
                    <ModalHeader
                        headerColor={styles.banner_color}
                        headerTitle="Détail du créneau"
                        closeBtn={props.modalFunction}
                    />
                    <AttendanceListHeader
                        dayLabel={courseSlot.dayLabel}
                        monthLabel={courseSlot.monthLabel}
                        title={courseSlot.title}
                        campus={courseSlot.campus}
                        hourLabel={courseSlot.hourLabel}
                        courseLabel={courseSlot.course_label}
                        location={courseSlot.location}
                        groups={courseSlot.groups}
                    />
                </>
            )}
        </article>
    );
};

export default DetailPlanning;