import React from "react";
import TagBox from "../../../../TagBox/TagBox";
import { useWindowDimensions } from "../../../../../hooks/window-dimensions-hook";

// Styles
import styles from "../InvoiceHistoryListItem/InvoiceHistoryListItem.module.scss"

const InvoiceHistoryListItem = ( { commitment_invoice_identifier, place_caption, name, due_date, status, amount  } ) => {
   
   const displayedStatus= {
        'expected': {
            'value': 'Validée, en attente de paiement',
            'textColor': styles.tx_expected,
            'bgColor': styles.bg_expected,
        },
        'validate': {
            'value': 'En attente de validation',
            'textColor': styles.tx_validate,
            'bgColor': styles.bg_validate,
    },
        'collected': {
            'value': 'Payée',
            'textColor': styles.tx_collected,
            'bgColor': styles.bg_collected,
    },
        'pending': {
            'value': 'En attente, veuillez contacter le pôle financier',
            'textColor': styles.tx_pending,
            'bgColor': styles.bg_pending,
    },
        'registered': {'value': ''},
   }

    // Window Size Hook
      const { width } = useWindowDimensions();
   let nameLabel,placeCaptionLabel,dueDateLabel,amountLabel;
      if (width <= 767) {
        nameLabel = width <= 400 ? <b>I : </b> : <b>Intervenant : </b>
        placeCaptionLabel = width <= 400 ? <b>C : </b> : <b>Campus : </b>
        dueDateLabel = width <= 400 ? <b>D : </b> : <b>Date d'échance : </b>
        amountLabel = width <= 400 ? <b>M : </b> : <b>Montant : </b>
    }
   
    return (
        <div className={styles.container}>
            <div className={styles.subwrapper}>
                <div className={styles.wrapper_subject}>
                    <h4 className={styles.title}>{commitment_invoice_identifier}</h4>
                </div>
                <div className={styles.subwrapper_responsive}>
                    <div className={styles.wrapper_item}>
                        <p className={styles.text}>{nameLabel}{name}</p>
                    </div>
                    <div className={styles.wrapper_item}>
                        <p className={styles.text}>{placeCaptionLabel}{place_caption}</p>
                    </div>
                    <div className={styles.wrapper_tag}>
                        <TagBox label={displayedStatus[status].value} textColor={displayedStatus[status].textColor} bgColor={displayedStatus[status].bgColor} />
                    </div>
                    <div className={styles.wrapper_item}>
                        <p className={styles.text}>{dueDateLabel}{due_date}</p>
                    </div>
                    <div className={styles.wrapper_item}>
                        <p className={styles.text}>{amountLabel}{parseFloat(Math.abs(amount)).toFixed(2)} €</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InvoiceHistoryListItem;
