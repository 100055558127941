import React from "react";

//Styles
import styles from "./WebsiteMenu.module.scss";

const WebsiteMenu = (props) => {
    return (
        <a id={props.id} className={styles.wrapper} href={props.linkTo} target="_blank" rel="noreferrer">
            <div className={props.logoSize || styles.img_wrapper}>
                <img className={styles.img} src={props.logo} alt={props.altText} />
            </div>
            <p className={styles.text}>{props.anchorLabel}</p>
        </a>
    );
};

export default WebsiteMenu;
