import React, { useState } from "react";

// Components
import HeaderBanner from "../../components/HeaderBanner/HeaderBanner";
import TabView from "../../components/TabView/TabView";
import DocumentListDisplay from "../../components/GenericTabs/DocumentsListDisplay/DocumentsListDisplay";

// Icons
import schoolActivityIcon from "../../assets/images/book.svg";
import download from "../../assets/images/download-solid.svg";

// Styles
import styles from "./StudentActivity.module.scss";

const StudentActivity = () => {
    const [invoicingNav] = useState([
        {
            id: "syllabus",
            btnText: "Syllabus",
            btnType: "text",
        },
        {
            id: "courses_sheet",
            btnText: "Fiche des cours",
            btnType: "text",
        },
    ]);

    const [syllabusDocs] = useState([
        {
            title: "syllabus 1",
            icon: download,
            iconColor: styles.download_icon_color,
            link: "/test",
        },
        {
            title: "syllabus 2",
            icon: download,
            iconColor: styles.download_icon_color,
            link: "/test",
        },
        {
            title: "syllabus 3",
            icon: download,
            iconColor: styles.download_icon_color,
            link: "/test",
        },
        {
            title: "syllabus 4",
            icon: download,
            iconColor: styles.download_icon_color,
            link: "/test",
        },
        {
            title: "syllabus 5",
            icon: download,
            iconColor: styles.download_icon_color,
            link: "/test",
        },
    ]);

    const [assignmentDocs] = useState([
        {
            title: "Fiche de cours 1",
            icon: download,
            iconColor: styles.download_icon_color,
            link: "/test",
        },
        {
            title: "Fiche de cours 2",
            icon: download,
            iconColor: styles.download_icon_color,
            link: "/test",
        },
        {
            title: "Fiche de cours 3",
            icon: download,
            iconColor: styles.download_icon_color,
            link: "/test",
        },
        {
            title: "Fiche de cours 4",
            icon: download,
            iconColor: styles.download_icon_color,
            link: "/test",
        },
        {
            title: "Fiche de cours 5",
            icon: download,
            iconColor: styles.download_icon_color,
            link: "/test",
        },
    ]);


    let tabViews = [
        {
            id: "syllabus",
            view: <DocumentListDisplay content={syllabusDocs} />,
        },
        {
            id: "courses_sheet",
            view: <DocumentListDisplay content={assignmentDocs} />,
        },
    ];

    return (
        <main className={styles.container}>
            <HeaderBanner
                title="Vie Scolaire"
                icon={schoolActivityIcon}
                iconColor={styles.icon_color}
                bgColor={styles.banner_color}
            />
            <TabView
                menuConfig={invoicingNav}
                tabViews={tabViews}
                activeBtnColor={styles.btn_active}
            />
        </main>
    );
};

export default StudentActivity;