/****************************
 *     HELPER FUNCTIONS     *
 ****************************/

// Imports
import { gemaSchools, AcademicLevels, campusIds, studentRythme, campusData, monthsLabel } from "./dictionaries";
import previousMonday from "date-fns/previousMonday";
import nextSunday from "date-fns/nextSunday";
import { StudentContext } from "../context/student-context";

/** 
 * Fix modal window
 * ===================================================
 * Fix the position for the modal window to avoid scrolling of the background
 * @param {boolean} status Boolean | Current modal status
 */
export const fixWindowPosition = (status) => {
    if (status == false) {
        const scrollPosition = `${window.scrollY}px`;
        const body = document.body;
        body.style.position = "fixed";
        body.style.top = `-${scrollPosition}`;
    } else {
        const body = document.body;
        const scrollY = body.style.top;
        body.style.position = "";
        body.style.top = "";
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
};

/** 
 * French date format function
 * ===================================================
 * @description Date display formating in french layout
 * @param {string} date "String" | (yyyy-mm-dd)
 * @returns {string} "String" | (dd-mm-yyyy)
 */
export const formatDate = (date) => {
    const datePart = date.split("-");
    return `${datePart[2]}/${datePart[1]}/${datePart[0]}`;
};

/** 
 * Compress File Name
 * ===================================================
 * Shortens file names that are too long
 * @param {Number} charNumb Number | number of chararcters to preserve on each side of the ... joint. (3 => xxx...xxx.pdf)
 * @param {*} string "String" | original filename
 * @param {*} mimeType "String" | .pdf, .doc
 * @returns {String} "String" | string...lessLong.mimeType (pdf...)
 */
export const compressFilenameStr = (charNumb, string, mimeType) => {
    if (string.length < 24) return string;

    const onlyNameStr = string.replaceAll(mimeType, "").replaceAll(" ", "");
    const strLength = onlyNameStr.length;
    const firstHalfStr = onlyNameStr.substring(0, charNumb);
    const secondHalfStr = onlyNameStr.substring(strLength - charNumb, strLength);

    return firstHalfStr + "..." + secondHalfStr + mimeType;
};

/** 
 * Shorten Long strings
 * ===================================================
 * Shortens strings that are too long
 * @param {Number} charNumb Number | number of chararcters to preserve XXX...
 * @param {*} string "String" | string to be shorten
 * @returns {String} "String" | stringLessLon...
 */
 export const shortenStr = (charNumb, string) => {
    if (string.length < charNumb) return string;
    return string.substring(0, charNumb) + "...";
};

/**
 * Structure Document Data
 * ===================================================
 * @param {{}} docs { Object } | Document's Config Object from backend
 * @param {String} editIcon "String" | Specific icon to be used by the document banner
 * @param {String} downloadIcon "String" | Specific icon to be used by the document banner
 * @param {String} iconColor "String" | Specific icon color to be used by the icons
 * @returns {[]} [ Array ] | [{ status, type, icon, link, title, iconColor, hashta }... ]
 */
export const structureConfigDocuments = async (docs, editIcon, downloadIcon, iconColor, studentAttestation) => {
    // New object to return to state
    let newStructure = {};
    
    // Restructure server response
    Object.entries(docs).forEach(([key, value]) => {

        newStructure[key] = [];
        
        // Add new properties to object
        value.forEach(({ id, status, type, name, labels, href, hashtag, archive, contrat, date, property_1, identifier, category, adr_zip, place_id }) => {

            
            // Initialize dynamic data variables
            let link = href,
            icon = downloadIcon,
            title = labels['default'] ? labels['default'] : name;

                link = `${process.env.REACT_APP_API_HOST}/documents/see-document/${id}`;

                if (identifier === 'mygema_relation_entreprise' && type === "structured") link = `${process.env.REACT_APP_API_HOST}/documents/generate-document/${name}`;
                if (type === "structured") {
                if (status === "active") {
                    icon = editIcon;
                    link = `/student-eval-form/${name}`;
                }
            }

            if (identifier === 'mygema_attestations') link = `${process.env.REACT_APP_API_HOST}/documents/generate-document/${category}?commitmentId=${studentAttestation}`
            if (identifier === 'mygema_certificates') link = `${process.env.REACT_APP_API_HOST}/documents/generate-document/${category}`;
            if (adr_zip) link = href;

            // Push new object
            newStructure[key].push({
                status,
                type: type || "document",
                icon,
                link,
                title,
                iconColor,
                hashtag: Object.values(hashtag),
                archive,
                contrat,
                adr_zip,
                place_id,
                date,
                property_1
            });
        });
    });

    return newStructure;
};

/**
 * Filter Config. Documents
 * ===================================================
 * Filter documents based on the assigned hastags vs. the user context data
 * @param {[]} hashtag Array of hashtags to be considered on the filter result
 * @param {{}} context User context data
 * @returns Boolean
 */
export const filterDocument = (hashtag, context) => {
    let hasPlace = false,
        keepPlace = false,
        hasLevel = false,
        keepLevel = false,
        hasCampus = false,
        keepCampus = false,
        hasTime = false,
        keepTime = false,
        keep = false;
        
        hashtag.forEach((tag) => {
        if (gemaSchools.includes(tag)) {
            hasPlace = true;
            if (context.studentSchool === tag) keepPlace = true;
            if (context.tuteurSchool === tag) keepPlace = true;
            return;
        }
        if (AcademicLevels.includes(tag)) {
            hasLevel = true;
            if (context.studentLevel === tag) keepLevel = true;
            if (context.tuteurLevel === tag) keepLevel = true;
            return;
        }
        if (campusIds.includes(tag)) {
            hasCampus = true;
            if (context.studentCampusId === tag) keepCampus = true;
            if (context.tuteurCampusId === tag) keepCampus = true;
            return;
        }
        if (studentRythme.includes(tag)) {
            hasTime = true;
            if (context.studentRythme === tag) keepTime = true;
            if (context.tuteurRythme === tag) keepTime = true;
            return;
        }
    });

    if ((!hasPlace || keepPlace) && (!hasLevel || keepLevel) && (!hasCampus || keepCampus) && (!hasTime || keepTime))
        keep = true;

    return keep;
};

/**
 * Login API URL
 * =====================================
 * Resolves Login URL based on the email type (.edu, .int, .tuteur, .fdp)
 * @param {String} email {String} email received from the front end
 * @returns {String} {String} Returns the API URL
 */
 export const loginEndpoint = (email) => {

    // Login Route
    const route = "/" + process.env.REACT_APP_AUTH_URL;

    // Resolve Server HOST
    if (email.includes(".edu")) return process.env.REACT_APP_API_STUDENT + route;
    else if (email.includes(".int")) return process.env.REACT_APP_API_TEACHER + route;
    else if (email.includes(".presta")) return process.env.REACT_APP_API_TEACHER + route;
    else if (email.includes(".tuteur")) return process.env.REACT_APP_API_TUTORS + route;
    else return process.env.REACT_APP_API_HOST + route;
};

/**
 * Download Base64 Document
 * =====================================
 * Creates an <a> tag to compile and download the document based on the MIME Type
 * @param {String} file String | Compiled Base64 file string
 * @param {String} name String | name of the document (xxx.pdf)
 * @param {String} contentType String | Content Type of the document
 */
export function downloadBase64Document(file, name, contentType) {

    // Create a link element on the DOM
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL based on MIME type
    link.href = `data:${contentType};base64,${file}`;
    
    // Set the document's name
    link.download = name;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    link.dispatchEvent(
        new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
        })
    );

    // Remove link from body
    document.body.removeChild(link);
}

/**
 * Planning Constructor
 * =====================================
 * Creates the front end array for react-big-calanedar library
 * @param {String} userType String | Context user 
 * @param {[]} events String | Array of events
 * @param {{}} styles Object | styles for the html event block
 * @returns {[]} Returns Array
 */
export const planningConstructor = async (userType, events, styles) => {

    const today = new Date();
    let newEvents = [];

    if (events.length > 0) {
        if (userType === "student" || userType === "tuteur") {

            /* ===============
            *  Student Case  *
            =============== */
            events.forEach((event) => {

                // Skip all courses with New status
                if (event.status === "new" /*|| event.status === "completed"*/) return;
                
                // Variables
                const endDate = new Date(`${event.end_date}T${event.end_time}`);
                const splitedBeginDate = event.begin_date.split("-");
                const splitedBeginTime = event.begin_time.split(":");
                const splitedEndTime = event.end_time.split(":");
                let eventLocation = "";

                let titleColor = styles.realized;
                switch (event.status) {
                    case "scheduled":
                        if (endDate < today) titleColor = styles.realized;
                        else titleColor = styles.scheduled;
                        break;
                    
                    case "completed":
                        if (endDate < today) titleColor = styles.realized;
                        else titleColor = styles.realized;
                        break;

                    case "realized":
                        titleColor = styles.realized;
                        break;

                    case "canceled":
                        titleColor = styles.canceled;
                        break;

                    case "replaced":
                        titleColor = styles.replaced;
                        break;
                    
                    default:
                        if (endDate < today) titleColor = styles.realized;
                        else titleColor = styles.scheduled;
                        break;
                };

                if (event.location) eventLocation = (event.location.search("http") !== -1) ? "Visioconférence" : event.location;

                let description = (
                    <div className={styles.card_content}>
                        <h4 className={`${styles.card_title} ${titleColor}`}>
                            {event.course_label ? shortenStr(31, event.course_label) : "No subject"}
                        </h4>
                        <p className={`${styles.card_teacher} ${event.status == "canceled" ? styles.canceled_teacher : ""}`}>
                            {`${(event.n_last === "Professeur.e non attribué.e") ? "" : "M.Mme. " + event.n_last}`}</p>
                        <p className={`${styles.card_location} ${event.status == "canceled" ? styles.canceled_loc : ""}`}>
                            {eventLocation}
                        </p>
                    </div>
                );

                newEvents.push({
                    allDay: false,
                    dbKey: event.property_3,
                    begin_time: event.begin_time,
                    campus: campusData[event.place_id].planningLabel,
                    date: event.begin_date,
                    dayLabel: splitedBeginDate[2],
                    end: endDate,
                    end_time: event.end_time,
                    groups: event.groupLabel,
                    hourLabel: `${splitedBeginTime[0]}:${splitedBeginTime[1]}h - ${splitedEndTime[0]}:${splitedEndTime[1]}h`,
                    location: event.location,
                    monthLabel: monthsLabel.find(({ month }) => month === splitedBeginDate[1]).value,
                    rythme: event.subcategory,
                    start: new Date(`${event.begin_date}T${event.begin_time}`),
                    status: event.status,
                    title: description,
                    teacher_name: `${event.n_first}, ${event.n_last}`,
                    teacher_email: event.email_work,
                    photo_link_id: event.photo_link_id,
                    name: event.course_label,
                });
            });
        } else {

            /* ===============
            *  Teacher Case  *
            =============== */

            let eventsIndex = [];
            for (let i = 0; i < events.length; i++) {

                // Skip all courses with New status
                if (events[i].status === "new" || events[i].status === "completed") continue;

                // Variables
                let description, location;
                let dateIndex = new Date(`${events[i].begin_date}T${events[i].begin_time}`).toISOString();
                let eventsIndexNumber = eventsIndex.indexOf(dateIndex);
                const splitedBeginDate = events[i].begin_date.split("-");
                const splitedBeginTime = events[i].begin_time.split(":");
                const splitedEndTime = events[i].end_time.split(":");

                if (events[i].location == null) events[i].location = "Non renseigné";
                description = (
                    <div className={styles.card_content}>
                        <h4 className={`${styles.card_title} ${styles.card_title}`}>
                            {events[i].course_label}
                        </h4>
                        <p className={styles.card_location}>
                            {events[i].location.search("http") !== -1
                                ? "Visioconférence"
                                : events[i].location}
                        </p>
                    </div>
                );

                // if event doesn't exist in newEvents, create and add dateIndex and push object to array
                if (eventsIndexNumber == -1) {
                    eventsIndex.push(dateIndex);
                    newEvents.push({
                        account_id: events[i].account_id,
                        allDay: false,
                        begin_time: events[i].begin_time,
                        campus: campusData[events[i].place_id].planningLabel,
                        course_label: events[i].course_label,
                        date: events[i].begin_date,
                        dayLabel: splitedBeginDate[2],
                        dbKey: events[i].property_3,
                        end: new Date(`${events[i].end_date}T${events[i].end_time}`),
                        end_time: events[i].end_time,
                        groupLabel: events[i].groupLabel,
                        groups: [{ groupId: events[i].groups, courseId: events[i].id }],
                        hourLabel: `${splitedBeginTime[0]}:${splitedBeginTime[1]}h - ${splitedEndTime[0]}:${splitedEndTime[1]}h`,
                        location: events[i].location,
                        monthLabel: monthsLabel.find(({ month }) => month === splitedBeginDate[1]).value,
                        rythme: events[i].subcategory,
                        start: new Date(`${events[i].begin_date}T${events[i].begin_time}`),
                        status: (events[i].property_5 === 'oui') ? 'invoiced' : events[i].status,
                        title: description,
                    });
                } else {
                    newEvents[eventsIndexNumber].groups.push({
                        groupId: events[i].groups,
                        courseId: events[i].id,
                    });
                }
            }
        }
    }
    return newEvents;
};

/**
 * Verify Gema Account Types
 * =====================================
 * Verifies Gema Domain and Account Types for passed emails
 * @param {String} email "String" | email to be verified
 * @returns {Boolean} Boolean | styles for the html event block
 */
export const verifyGemaEmail = (email) => {
    if (email.toLowerCase().includes(".edu@groupe-gema.com")) return true;
    else if (email.toLowerCase().includes(".int@groupe-gema.com")) return true;
    else if (email.toLowerCase().includes(".presta@groupe-gema.com")) return true;
    else if (email.toLowerCase().includes(".tuteur@groupe-gema.com")) return true;
    else return false
};

/**
 * IBAN Verification
 * =====================================
 * Verifies IBAN Account Numbers
 * @param {String} iban "String" | Account number (FR76....)
 * @returns 
 */
export const verifyIBAN = (iban) => {
    if (iban.length < 5) return false;
    let work = iban.substring(4, iban.length), work2;
    work2 = work + iban.substring(0, 4);
    work = "";
    for (let i = 0; i < work2.length; i++) {
        let ascii = work2.charAt(i).charCodeAt();
        if (ascii < 48) return false;
        if (ascii > 57 && ascii < 65) return false;
        if (ascii > 90) return false;
        if (ascii <= 57) {
            ascii -= 48;
            ascii = ascii.toString();
        }
        else if (ascii >= 65) {
            ascii -= 55;
            ascii = ascii.toString();
            if (ascii.length === 1) ascii = "0" + ascii;
        }
        work += ascii;
    }
    let modulo = "";
    for (let i = 0; i < work.length; i++) {
        modulo = parseInt(modulo + work.charAt(i)) % 97;
        modulo = modulo.toString();
    }
    modulo = parseInt(modulo);
    if (modulo !== 1) return false;
    return true;
};