/* eslint-disable eqeqeq */
import React, { useReducer, useEffect, useState, useRef } from "react";
import { validate } from "../../utils/validators";

// Styles
import styles from "./Input.module.scss";

// En fonction de l'etat de l'objet html (change = ecriture / touch = utilisateur change de champ)
const inputReducer = (state, action) => {
    switch (action.type) {
        case "CHANGE":
            return {
                // copier tous les valeurs d'avant et ajoute le nouveau événement (une lettre par exemple)
                ...state,
                value: action.val,
                // changer la validation en fonction des validateurs de l'objet html
                isValid: validate(action.val, action.validators),
            };
        case "CLICK_MULTIPLE":
            let valueForm;
            if (state.value.includes(action.val)) {
                valueForm = state.value.filter((val) => val != action.val)
            } else {
                valueForm = [...state.value, action.val]
            }
            return {
                ...state,
                value: valueForm,
                isValid: validate(action.val, action.validators),
            }
        case "TOUCH": {
            return {
                ...state,
                isTouched: true,
            };
        }
        default:
            return state;
    }
};

// Component
const Input = (props) => {

    const [selectedValues, setSelectedValues] = useState([]); // État pour les options sélectionnées
    const [showDropdown, setShowDropdown] = useState(false); // Contrôler la visibilité de la liste déroulante
    const dropdownRef = useRef(null); // Référence pour le dropdown

    /*
    ##### IMPORTANT #####
    To bind the input component to the formstate, always use the same formState object key in the id and name of the input component!
    */

    // état initial du composant
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: props.initialValue || "",
        isTouched: false,
        isValid: props.initialValid || false,
    });

    // déconstruction de props et inputState
    const { id, onInput } = props;
    const { value, isValid } = inputState;

    // id du composant, la valeur capturée et sa validation de donnés
    // Appel des fonctions passées en props du form-hook
    useEffect(() => {
        onInput(id, value, isValid);
    }, [id, value, isValid, onInput]);

    // Fonction pour capturer le changement de l'état du composant (écriture)
    const changeHandler = (event) => {
        dispatch({
            type: "CHANGE",
            val: event.target.value,
            validators: props.validators,
        });

        // Add onCount possibility if you want to count any change event.target.value
        if (props.onCountFctn) props.onCountFctn(event);
        if (props.onMulitpleOptions) props.onMulitpleOptions(event);
    };

    const changeMultipleSelectHandler = (value) => {
        dispatch({
            type: "CLICK_MULTIPLE",
            val: value,
            validators: props.validators,
        });
    }

    // Fonction pour capturer le changement de l'état du composant (changer de champ)
    const touchHandler = () => {
        dispatch({
            type: "TOUCH",
        });
        if (props.onBlurAltFctn) props.onBlurAltFctn();
    };

    const toggleSelection = (option) => {
        if (selectedValues.includes(option)) {
          setSelectedValues(selectedValues.filter((val) => val != option));
        } else {
          setSelectedValues([...selectedValues, option]);
        }
    };

    const isSelected = (option) => selectedValues.includes(option);

    let input, label;

    if (props.label) {
        label = (
            <label className={styles.label} htmlFor={props.id}>
                {props.label} {props.asterisque && (
                    <span style={{color: "red"}}>*</span>
                )}
            </label>
        );
    }

        // Fonction pour gérer le clic à l'extérieur du composant
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false); // Fermer la liste si le clic est à l'extérieur
        }
    };

     // Utiliser useEffect pour attacher un écouteur d'événements au document
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        // Nettoyage de l'écouteur d'événements quand le composant est démonté
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // UseEffect to retrieve values of the input from the backend as an array for multipleselect and to load it on screen.
    useEffect(() => {
        if (Array.isArray(inputState.value)) {
            const selectedLabels = [];
            inputState.value.forEach(val => {
                props.content.forEach(dict => {
                    if (val == dict.label) selectedLabels.push(dict.default)
                })
            });
            setSelectedValues(selectedLabels)
        }
    }, [inputState.value])

    if (props.typeOfInput) {
        switch (props.typeOfInput) {
            case "input":
                input = (
                    <div className={`${styles.container} ${props.styles}`}>
                        {label}
                        <input
                            id={props.id}
                            className={`${props.boxStyling || styles.box} ${
                                !inputState.isValid && inputState.isTouched && styles.invalid
                            }`}
                            name={props.name}
                            type={props.type}
                            pattern={props.pattern}
                            maxLength={props.maxLength}
                            minLength={props.minLength}
                            placeholder={props.placeholder}
                            autoComplete={props.autocomplete}
                            value={inputState.value}
                            onChange={changeHandler}
                            onBlur={touchHandler}
                            disabled={props.disabled}
                        ></input>
                        {!inputState.isValid && inputState.isTouched && (
                            <p className={styles.input_error}>{props.errorText}</p>
                        )}
                    </div>
                );
                break;

            case "select":
                input = (
                  <div className={`${styles.container} ${props.styles}`}>
                    {label}
                    <select
                      id={props.id}
                      className={`${styles.box} ${props.selectInput} ${
                        !inputState.isValid &&
                        inputState.isTouched &&
                        styles.invalid
                      }`}
                      name={props.name}
                      type={props.type}
                      maxLength={props.maxLength}
                      minLength={props.minLength}
                      placeholder={props.placeholder}
                      autoComplete={props.autocomplete}
                      multiple={props.multiple}
                      value={inputState.value}
                      onChange={changeHandler}
                      onBlur={touchHandler}
                      disabled={props.disabled}
                    >
                      {props.content.map((prop) => {
                        if (inputState.value == prop.label)
                          return (
                            <option
                              value={prop.label}
                              key={prop.label}
                              defaultValue={prop.label}
                            >
                              {prop.default}
                            </option>
                          );
                        else
                          return (
                            <option value={prop.label} key={prop.label}>
                              {prop.default}
                            </option>
                          );
                      })}
                    </select>
                    {!inputState.isValid && inputState.isTouched && (
                      <p className={styles.input_error}>{props.errorText}</p>
                    )}
                  </div>
                );
                break;

            case "multiselect":
                input = (
                <div 
                    className={`${styles.container} ${props.styles}`}
                    onClick={() => {
                        setShowDropdown(!showDropdown);
                    }}
                    ref={dropdownRef}
                >
                    {label}
                    <input
                        type="text"
                        readOnly
                        value={selectedValues.join(", ")}
                        className={styles.multiInput}
                        placeholder="Sélectionner une ou plusieurs options"
                    />
                    {showDropdown && (
                     <ul className={styles.ulDropDown}>
                        {props.content.map((prop) => (
                            <li
                                key={prop.label}
                                onClick={() => {
                                    toggleSelection(prop.default)
                                    changeMultipleSelectHandler(prop.label)
                                }}
                            >
                                {prop.default} {isSelected(prop.default) && <span style={{color: "green", fontWeight:"bold"}}>✓</span>}
                            </li>
                        ))}
                     </ul>
                    )}
                </div>
                );
                break;

            case "textArea":
                input = (
                    <div className={`${styles.container} ${props.styles}`}>
                        {label}
                        <textarea
                            id={props.id}
                            className={`${props.boxStyles || styles.box} ${
                                !inputState.isValid && inputState.isTouched && styles.invalid
                            }`}
                            name={props.name}
                            type={props.type}
                            rows={props.rows}
                            cols={props.cols}
                            maxLength={props.maxLength}
                            minLength={props.minLength}
                            placeholder={props.placeholder}
                            autoComplete={props.autocomplete}
                            value={inputState.value}
                            onChange={changeHandler}
                            onBlur={touchHandler}
                            disabled={props.disabled}
                        />
                        {!inputState.isValid && inputState.isTouched && (
                            <p className={styles.input_error}>{props.errorText}</p>
                        )}
                    </div>
                );
                break;

            default:
                break;
        }
    }

    return input;
};

export default Input;
