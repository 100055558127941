import React, { useState, useEffect, useContext } from "react";
import { isRequired, isBlank, isText, isEmail, isNumber, MinLength } from "../../utils/validators";
import { ToastContainer, toast } from 'react-toastify';
import { useHttpRequest } from "../../hooks/httpRequest-hook";
import { AuthContext } from "../../context/auth-context";
import { useHistory } from "react-router-dom";
import CheckBox from "../CheckBox/CheckBox";

// Components
import ActionBtn from "../../components/Buttons/ActionBtn/ActionBtn";
import Input from "../../components/Input/Input";

// Styles
import styles from "./EvalStyles.module.scss";
import 'react-toastify/dist/ReactToastify.css';

const EvalPartOne = ({ formState, inputHandler, docType, formContext }) => {
    const { isLoading, error, okHttp, sendRequest } = useHttpRequest();

    const initialFormState = useState(formState);
    const [finalMessage, setFinalMessage] = useState(false);
    const [finalWarning, setFinalWarning] = useState(false);

    const auth = useContext(AuthContext);

    const history = useHistory();

    const selectOptions = [
        { default: "Veuillez sélectionner une option", label: "" },
        { default: "Atteints", label: "atteints" },
        { default: "En cours", label: "en_cours" },
        { default: "Non atteints", label: "non_atteints" },
    ];
    const contratType = [
        { default: "Veuillez sélectionner une option", label: "" },
        { default: "Apprentissage/profesionnalisation", label: "Apprentissage" },
        { default: "Stage", label: "Stage" },
    ];
    const notesOptions = [
        { default: "Veuillez sélectionner une option", label: "" },
        { default: "A - Excellent (4/4)", label: "A" },
        { default: "B - Très Bien (3,5/4)", label: "B" },
        { default: "C - Bien (3/4)", label: "C" },
        { default: "D - Assez bien (2,5/4)", label: "D" },
        { default: "E - Passable (2/4)", label: "E" },
        { default: "F - Insuffisant (1/4)", label: "F" },
        { default: "FX - Très Insuffisant (0/4)", label: "FX" },
    ];

    const yesNoOptions = [
        { default: "Veuillez choisir une option", label: ""},
        { default: "Oui", label: "oui"},
        { default: "Non", label: "non"},
    ];

    const [checkbox_1, setCheckbox_1] = useState(formState.inputs["4_choix_stagiaire_1"].value);
    const [checkbox_2, setCheckbox_2] = useState(formState.inputs["4_choix_stagiaire_2"].value);
    const [checkbox_3, setCheckbox_3] = useState(formState.inputs["4_choix_stagiaire_3"].value);
    const [checkbox_4, setCheckbox_4] = useState(formState.inputs["4_choix_stagiaire_4"].value);
    const [checkbox_5, setCheckbox_5] = useState(formState.inputs["4_choix_stagiaire_5"].value);
    const [checkbox_6, setCheckbox_6] = useState(formState.inputs["4_choix_tuteur_1"].value);
    const [checkbox_7, setCheckbox_7] = useState(formState.inputs["4_choix_tuteur_2"].value);
    const [checkbox_8, setCheckbox_8] = useState(formState.inputs["4_choix_tuteur_3"].value);
    const [checkbox_9, setCheckbox_9] = useState(formState.inputs["4_choix_tuteur_4"].value);
    const [checkbox_10, setCheckbox_10] = useState(formState.inputs["4_choix_tuteur_5"].value);
    const [checkbox_11, setCheckbox_11] = useState(formState.inputs["4_choix_tuteur_6"].value);

    // SUPPORT LEGACY BUG
    for (const [key, value] of Object.entries(formState.inputs)) {
        if (key === '1_difficultes' || key === '1_evolutions' || key === '2_atteinte_objectifs' || key === '2_resultats') {
            formState.inputs[key].isValid = true;
        }
    }
    //  function checkNumberOfInvalideNotes ()  {
    //     let numberOfNotes=0;
    //     let numberOfInvalidInput=0;
    //     for(const [key, value] of Object.entries(formState.inputs))
    //     {
    //         if(value.isValid==false)
    //         {
    //             numberOfInvalidInput++;
    //             if(key.indexOf("_note_")>0 && value.isValid==false)
    //             {
    //                 numberOfNotes++;
    //             }

    //         }
            
    //     }
    //     return [numberOfNotes, numberOfInvalidInput];
    // };

    function checkNotValidInput() {
    // const[numberOfInvalideNote,numberOfInvalideInput] = checkNumberOfInvalideNotes();
    // if ((!formState.isValid && numberOfInvalideNote>4) || (!formState.isValid && numberOfInvalideInput> numberOfInvalideNote)) {
        if(!formState.isValid){
            toast.error("Merci de compléter les champs manquants afin de finaliser l'évaluation.", {
                position: "top-right",
                autoClose: 4500,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });

            for (const [key, value] of Object.entries(formState.inputs)) {
                for (const [keyInput, valueInput] of Object.entries(value)) {
                    if (keyInput == 'isValid' && valueInput != true) {
                        document.getElementsByName(key)[0].previousElementSibling.scrollIntoView();
                        document.getElementsByName(key)[0].style.outline=4;
                        document.getElementsByName(key)[0].style.borderColor="red";
                        return false;
                    }
                }
            }
    } else {
            setFinalWarning(true);
            return true;
    }
    }

    const formSubmitHandler = () => {
        try {
                const result = checkNotValidInput();
                if (result) {
                const url = `${process.env.REACT_APP_API_HOST}/documents/close-eval-support/${formContext.type}`;
                const studentEval = sendRequest(url, "GET", null, {
                    Authorization: "Bearer " + auth.token,
                });
                setFinalMessage(true);
            }
        } catch (err) {
          console.error(err);
        }    
    };

    const uploadDocument = async (value, key) => {
        const formData = new FormData();

        if (initialFormState[0].inputs[key].value != value || key.slice(2, 7) === "choix") {

            initialFormState[0].inputs[key].value = value
            formData.append(key, value);
            try {  
                const url = `${process.env.REACT_APP_API_HOST}/documents/evaluation-support-part/${formContext.type}`;
                const documentUploadRequest = await sendRequest(url, "POST", formData, {
                Authorization: "Bearer " + auth.token,
                });
                if (documentUploadRequest.RC === 'OK') {
                    toast.success('Informations enregistrées.', {
                        position: "top-right",
                        autoClose: 4500,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    toast.error('Erreur serveur lors de l\'enregistrement des données.', {
                        position: "top-right",
                        autoClose: 4500,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            } catch (err) {
                console.err(err);
                toast.error('Erreur serveur. Veuillez réesayer ultérieurement.', {
                    position: "top-right",
                    autoClose: 4500,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }
    }
    
    return (
        <article className={styles.container}>
            <div className={styles.wrapper}>
                <h1 className={styles.title}>SUPPORT D’ÉVALUATION</h1>
                    <section className={styles.section}>
                        <Input
                            id="date_of_evaluation"
                            name="date_of_evaluation"
                            type="text"
                            typeOfInput="input"
                            label="Date de l'évaluation"
                            disabled={true}
                            styles={styles.input}
                            validators={[isRequired()]}
                            errorText="Veuillez choisir un type"
                            initialValue={formState.inputs["date_of_evaluation"].value}
                            initialValid={formState.inputs["date_of_evaluation"].isValid}
                            onInput={inputHandler}
                        />

                        <Input
                            id="commitment_caption"
                            name="commitment_caption"
                            type="text"
                            typeOfInput="input"
                            label="Année scolaire"
                            disabled={true}
                            styles={styles.input}
                            validators={[isRequired()]}
                            errorText="Veuillez choisir un type"
                            initialValue={formState.inputs["commitment_caption"].value}
                            initialValid={formState.inputs["commitment_caption"].isValid}
                            onInput={inputHandler}
                        />

                        <Input
                            id="commitment_level"
                            name="commitment_level"
                            type="text"
                            typeOfInput="input"
                            label="Niveau de l'étudiant"
                            disabled={true}
                            styles={styles.input}
                            validators={[isRequired()]}
                            errorText="Veuillez choisir un type"
                            initialValue={formState.inputs["commitment_level"].value}
                            initialValid={formState.inputs["commitment_level"].isValid}
                            onInput={inputHandler}
                        />
                        <Input
                            id="type_contrat"
                            name="type_contrat"
                            type="text"
                            typeOfInput="select"
                            label="Type de contrat"
                            asterisque="true"
                            disabled={  isLoading || finalMessage ? true : false}
                            styles={styles.input}
                            validators={[isRequired()]}
                            errorText="Veuillez choisir un type"
                            initialValue={formState.inputs["type_contrat"].value}
                            initialValid={formState.inputs["type_contrat"].isValid}
                            content={contratType}
                            onInput={inputHandler}
                            onBlurAltFctn={() => uploadDocument(formState.inputs["type_contrat"].value, "type_contrat")}
                        />
                    </section>

                <h3 className={`${styles.subtitle} ${styles.left_align}`}>
                    CE SUPPORT SERA REMPLI PAR :
                </h3>
                <h5 className={styles.section_title}>Le Tuteur ou la Tutrice</h5>
                <section className={styles.section}>
                    <Input
                        id="tuteur_n_last"
                        name="tuteur_n_last"
                        type="text"
                        typeOfInput="input"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        label="Nom"
                        asterisque="true"
                        placeholder="votre nom"
                        autocomplete="new-password"
                        styles={styles.textArea}
                        boxStyling={isLoading ? styles.boxStylingDisabled : ""}
                        validators={[isRequired()]}
                        errorText="Texte uniquement"
                        initialValue={formState.inputs.tuteur_n_last.value}
                        initialValid={formState.inputs.tuteur_n_last.isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs.tuteur_n_last.value, "tuteur_n_last")}
                    />
                    <Input
                        id="tuteur_n_first"
                        name="tuteur_n_first"
                        type="text"
                        typeOfInput="input"
                        label="Prénom"
                        asterisque="true"
                        placeholder="votre prénom"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        boxStyling={isLoading ? styles.boxStylingDisabled : ""}
                        styles={styles.textArea}
                        validators={[isRequired()]}
                        autocomplete="new-password"
                        errorText="Texte uniquement"
                        initialValue={formState.inputs.tuteur_n_first.value}
                        initialValid={formState.inputs.tuteur_n_first.isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs.tuteur_n_first.value, "tuteur_n_first")}
                    />
                    <Input
                        id="tuteur_company"
                        name="tuteur_company"
                        type="text"
                        typeOfInput="input"
                        label="Nom de l'entreprise"
                        asterisque="true"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        styles={styles.textArea}
                        boxStyling={isLoading ? styles.boxStylingDisabled : ""}
                        validators={[isRequired()]}
                        errorText="Texte uniquement"
                        autocomplete="new-password"
                        initialValue={formState.inputs.tuteur_company.value}
                        initialValid={formState.inputs.tuteur_company.isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs.tuteur_company.value, "tuteur_company")}
                    />
                    <Input
                        id="tuteur_position"
                        name="tuteur_position"
                        type="text"
                        typeOfInput="input"
                        asterisque="true"
                        label="Poste occupé"
                        autocomplete="new-password"
                        placeholder="directeur.trice de..."
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        styles={styles.textArea}
                        boxStyling={isLoading ? styles.boxStylingDisabled : ""}
                        validators={[isRequired()]}
                        errorText="Texte uniquement"
                        initialValue={formState.inputs.tuteur_position.value}
                        initialValid={formState.inputs.tuteur_position.isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs.tuteur_position.value, "tuteur_position")}
                    />
                    <Input
                        id="tuteur_email"
                        name="tuteur_email"
                        type="text"
                        typeOfInput="input"
                        asterisque="true"
                        label="E-mail"
                        autocomplete="new-password"
                        placeholder="votre-email@domine.com"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        styles={styles.textArea}
                        validators={[isRequired(), isEmail()]}
                        boxStyling={isLoading ? styles.boxStylingDisabled : ""}
                        errorText="Le format est incorrect. Ex : www_xxx-yyy.zzz@domaine.cxx"
                        initialValue={formState.inputs.tuteur_email.value}
                        initialValid={formState.inputs.tuteur_email.isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs.tuteur_email.value, "tuteur_email")}
                    />
                    <Input
                        id="tuteur_tel_work"
                        name="tuteur_tel_work"
                        type="text"
                        typeOfInput="input"
                        label="Numéro de téléphone"
                        autocomplete="new-password"
                        asterisque="true"
                        placeholder="0600000000"
                        styles={styles.textArea}
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        boxStyling={isLoading ? styles.boxStylingDisabled : ""}
                        validators={[MinLength(10), isNumber()]}
                        errorText="Chiffres sans espaces uniquement. (Min 10 chiffres)"
                        initialValue={formState.inputs.tuteur_tel_work.value}
                        initialValid={formState.inputs.tuteur_tel_work.isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs.tuteur_tel_work.value, "tuteur_tel_work")}
                    />
                </section>
                <h5 className={styles.section_title}>L'étudiant.e</h5>
                <section className={styles.section}>
                    <Input
                        id="alternant_n_last"
                        name="alternant_n_last"
                        type="text"
                        typeOfInput="input"
                        label="Nom"
                        placeholder="nom de l'étudiant"
                        styles={styles.textArea}
                        validators={[isText()]}
                        asterisque="true"
                        errorText="Texte uniquement"
                        initialValue={formState.inputs.alternant_n_last.value}
                        initialValid={formState.inputs.alternant_n_last.isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs.alternant_n_last.value, "alternant_n_last")}
                        disabled={true}
                    />
                    <Input
                        id="alternant_n_first"
                        name="alternant_n_first"
                        type="text"
                        typeOfInput="input"
                        label="Prénom"
                        placeholder="prénom de l'étudiant.e"
                        styles={styles.textArea}
                        validators={[isText()]}
                        asterisque="true"
                        errorText="Texte uniquement"
                        initialValue={formState.inputs.alternant_n_first.value}
                        initialValid={formState.inputs.alternant_n_first.isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs.alternant_n_first.value, "alternant_n_first")}
                        disabled={true}
                    />
                    <Input
                        id="alternant_position"
                        name="alternant_position"
                        type="text"
                        typeOfInput="input"
                        label="Poste occupé"
                        asterisque="true"
                        placeholder="Assistant.e ..."
                        autocomplete="new-password"
                        disabled={isLoading || finalMessage ? true : false}
                        boxStyling={isLoading ? styles.boxStylingDisabled : ""}
                        styles={styles.textArea}
                        validators={[isRequired()]}
                        errorText="Texte uniquement"
                        initialValue={formState.inputs.alternant_position.value}
                        initialValid={formState.inputs.alternant_position.isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs.alternant_position.value, "alternant_position")}
                    />
                    <Input
                        id="alternant_ecole"
                        name="alternant_ecole"
                        type="text"
                        typeOfInput="input"
                        label="École"
                        placeholder="école de l'étudiant"
                        styles={styles.textArea}
                        validators={[isText()]}
                        asterisque="true"
                        errorText="Texte uniquement"
                        initialValue={formState.inputs.alternant_ecole.value}
                        initialValid={formState.inputs.alternant_ecole.isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs.alternant_ecole.value, "alternant_ecole")}
                        disabled={true}
                    />
                    <Input
                        id="alternant_promo"
                        name="alternant_promo"
                        type="text"
                        typeOfInput="input"
                        label="Promo"
                        placeholder="promo de l'étudiant"
                        styles={styles.textArea}
                        validators={[isText()]}
                        asterisque="true"
                        errorText="Texte uniquement"
                        initialValue={formState.inputs.alternant_promo.value}
                        initialValid={formState.inputs.alternant_promo.isValid}
                        onInput={inputHandler}
                        disabled={true}
                        onBlurAltFctn={() => uploadDocument(formState.inputs.alternant_promo.value, "alternant_promo")}
                    />
                </section>
                <span className={styles.spanErrorInfos}>*Si des informations sont erronées, merci d’envoyer un mail à carrieres.tuteurs@groupe-gema.com</span>

                <h1 className={styles.title}>
                    1. Faits marquants, résultats par rapport aux objectifs fixés
                </h1>
                <h3 className={styles.part_subtitle}>à remplir par l’étudiant.e</h3>
                <section className={styles.section_alt}>
                    <Input
                        id="1_objectifs"
                        name="1_objectifs"
                        type="text"
                        typeOfInput="textArea"
                        rows="4"
                        disabled={(auth.userRole == "tuteur") || isLoading || finalMessage ? true : false}
                        label="Quels sont vos missions et objectifs au sein de l’entreprise ?"
                        boxStyles={isLoading || finalMessage ? styles.textArea_borderDisabled : styles.textArea_border}
                        validators={[isRequired()]}
                        asterisque="true"
                        errorText="Texte uniquement"
                        autocomplete="new-password"
                        initialValue={formState.inputs["1_objectifs"].value}
                        initialValid={formState.inputs["1_objectifs"].isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["1_objectifs"].value, "1_objectifs")}
                    />
                    <Input
                        id="1_realisations"
                        name="1_realisations"
                        type="text"
                        typeOfInput="textArea"
                        disabled={(auth.userRole == "tuteur") || isLoading || finalMessage ? true : false}
                        boxStyles={isLoading || finalMessage ? styles.textArea_borderDisabled : styles.textArea_border}
                        rows="4"
                        label="Quelles sont vos principales réalisations au cours du semestre ?"
                        placeholder=""
                        validators={[isRequired()]}
                        autocomplete="new-password"
                        asterisque="true"
                        errorText="Texte uniquement"
                        initialValue={formState.inputs["1_realisations"].value}
                        initialValid={formState.inputs["1_realisations"].isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["1_realisations"].value, "1_realisations")}
                    />
                    <Input
                        id="1_bilan"
                        name="1_bilan"
                        type="text"
                        typeOfInput="textArea"
                        autocomplete="new-password"
                        rows="4"
                        label="Quel bilan faîtes-vous de ce semestre ?"
                        asterisque="true"
                        disabled={(auth.userRole == "tuteur") || isLoading || finalMessage ? true : false}
                        placeholder=""
                        boxStyles={isLoading || finalMessage ? styles.textArea_borderDisabled : styles.textArea_border}
                        validators={[isRequired()]}
                        errorText="Texte uniquement"
                        initialValue={formState.inputs["1_bilan"].value}
                        initialValid={formState.inputs["1_bilan"].isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["1_bilan"].value, "1_bilan")}
                    />
                    <Input
                        id="1_souhaits"
                        name="1_souhaits"
                        type="text"
                        autocomplete="new-password"
                        typeOfInput="textArea"
                        rows="4"
                        label="Quels sont vos souhaits / remarques pour la suite ?"
                        placeholder=""
                        disabled={(auth.userRole == "tuteur") || isLoading || finalMessage ? true : false}
                        boxStyles={isLoading || finalMessage ? styles.textArea_borderDisabled : styles.textArea_border}
                        validators={[isRequired()]}
                        asterisque="true"
                        errorText="Texte uniquement"
                        initialValue={formState.inputs["1_souhaits"].value}
                        initialValid={formState.inputs["1_souhaits"].isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["1_souhaits"].value, "1_souhaits")}
                    />
                </section>
                    <h3 className={`${styles.subtitle} ${styles.left_align}`}>
                        Étudiants bénéficiant du statut RQTH :
                    </h3>
                    <Input
                        id="1_rqth_access"
                        name="1_rqth_access"
                        type="text"
                        typeOfInput="select"
                        label="L’accessibilité à l’entreprise est-t-elle adaptée ?"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        styles={styles.input}
                        validators={[isBlank()]}
                        errorText="Veuillez choisir une catégorie"
                        initialValue={formState.inputs["1_rqth_access"].value}
                        initialValid={true}
                        content={yesNoOptions}
                        autocomplete="new-password"
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["1_rqth_access"].value, "1_rqth_access")}
                    />
                    <Input
                        id="1_rqth_amenagement"
                        name="1_rqth_amenagement"
                        type="text"
                        typeOfInput="select"
                        label="Votre environnement de travail est-il aménagé ?"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        styles={styles.input}
                        validators={[isBlank()]}
                        errorText="Veuillez choisir une catégorie"
                        initialValue={formState.inputs["1_rqth_amenagement"].value}
                        initialValid={true}
                        content={yesNoOptions}
                        autocomplete="new-password"
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["1_rqth_amenagement"].value, "1_rqth_amenagement")}
                    />
                    <Input
                        id="1_rqth_delay"
                        name="1_rqth_delay"
                        type="text"
                        typeOfInput="select"
                        label="Un délai est-il proposé pour les missions ?"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        styles={styles.input}
                        validators={[isBlank()]}
                        errorText="Veuillez choisir une catégorie"
                        initialValue={formState.inputs["1_rqth_delay"].value}
                        initialValid={true}
                        content={yesNoOptions}
                        autocomplete="new-password"
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["1_rqth_delay"].value, "1_rqth_delay")}
                    />
                <h1 className={styles.title}>
                    2. Évaluation de l’activité professionnelle et des compétences
                </h1>
                <h3 className={styles.part_subtitle}>à remplir par le tuteur ou tutrice</h3>
                <section className={styles.section_alt}>
                    <Input
                        id="2_objectifs"
                        name="2_objectifs"
                        type="text"
                        typeOfInput="textArea"
                        rows="4"
                        label="Quels sont les objectifs et les actions mises en place pour garantir l'atteinte des résultats de votre étudiant.e ?"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        boxStyles={isLoading || finalMessage ? styles.textArea_borderDisabled : styles.textArea_border}
                        validators={[isRequired()]}
                        asterisque="true"
                        autocomplete="new-password"
                        errorText="Texte uniquement"
                        initialValue={formState.inputs["2_objectifs"].value}
                        initialValid={formState.inputs["2_objectifs"].isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_objectifs"].value, "2_objectifs")}
                    />
                    <Input
                        id="2_evolutions"
                        name="2_evolutions"
                        type="text"
                        typeOfInput="textArea"
                        rows="4"
                        label="Quelles sont les évolutions attendues et/ou constatées dans son travail/comportement par rapport aux objectifs fixés ensemble ?"
                        placeholder=""
                        boxStyles={isLoading || finalMessage ? styles.textArea_borderDisabled : styles.textArea_border}
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        validators={[isRequired()]}
                        asterisque="true"
                        autocomplete="new-password"
                        errorText="Texte uniquement"
                        initialValue={formState.inputs["2_evolutions"].value}
                        initialValid={formState.inputs["2_evolutions"].isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_evolutions"].value, "2_evolutions")}
                    />
                    <Input
                        id="2_bilan"
                        name="2_bilan"
                        type="text"
                        typeOfInput="textArea"
                        rows="4"
                        label="Quel bilan faites-vous de ce semestre ?"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        placeholder=""
                        boxStyles={isLoading || finalMessage ? styles.textArea_borderDisabled : styles.textArea_border}
                        validators={[isRequired()]}
                        autocomplete="new-password"
                        asterisque="true"
                        errorText="Texte uniquement"
                        initialValue={formState.inputs["2_bilan"].value}
                        initialValid={formState.inputs["2_bilan"].isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_bilan"].value, "2_bilan")}
                    />
                    <Input
                        id="2_souhaits"
                        name="2_souhaits"
                        type="text"
                        autocomplete="new-password"
                        rows="4"
                        typeOfInput="textArea"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        label="Quels sont vos souhaits/remarques pour la suite ?"
                        boxStyles={isLoading || finalMessage ? styles.textArea_borderDisabled : styles.textArea_border}
                        validators={[isRequired()]}
                        asterisque="true"
                        errorText="Veuillez choisir une catégorie"
                        initialValue={formState.inputs["2_souhaits"].value}
                        initialValid={formState.inputs["2_souhaits"].isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_souhaits"].value, "2_souhaits")}
                    />
                </section>
                <h3 className={`${styles.subtitle} ${styles.left_align}`}>
                    Savoir-être
                </h3>
                <section className={styles.section}>
                    <Input
                        id="2_note_courtoisie"
                        name="2_note_courtoisie"
                        type="text"
                        typeOfInput="select"
                        label="Courtoisie, ponctualité"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        styles={styles.input}
                        validators={[isRequired()]}
                        asterisque="true"
                        errorText="Veuillez choisir une catégorie"
                        initialValue={formState.inputs["2_note_courtoisie"].value}
                        initialValid={formState.inputs["2_note_courtoisie"].isValid}
                        content={notesOptions}
                        autocomplete="new-password"
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_note_courtoisie"].value, "2_note_courtoisie")}
                    />
                    <Input
                        id="2_note_autonomie"
                        name="2_note_autonomie"
                        type="text"
                        typeOfInput="select"
                        label="Autonomie"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        styles={styles.input}
                        validators={[isRequired()]}
                        asterisque="true"
                        errorText="Veuillez choisir une catégorie"
                        autocomplete="new-password"
                        initialValue={formState.inputs["2_note_autonomie"].value}
                        initialValid={formState.inputs["2_note_autonomie"].isValid}
                        content={notesOptions}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_note_autonomie"].value, "2_note_autonomie")}
                    />
                    <Input
                        id="2_note_implication"
                        name="2_note_implication"
                        type="text"
                        typeOfInput="select"
                        label="Esprit d’initiative, implication"
                        styles={styles.input}
                        autocomplete="new-password"
                        validators={[isRequired()]}
                        asterisque="true"
                        errorText="Veuillez choisir une catégorie"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        initialValue={formState.inputs["2_note_implication"].value}
                        initialValid={formState.inputs["2_note_implication"].isValid}
                        content={notesOptions}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_note_implication"].value, "2_note_implication")}
                    />
                    <Input
                        id="2_note_adaptation"
                        name="2_note_adaptation"
                        type="text"
                        typeOfInput="select"
                        label="Adaptation"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        styles={styles.input}
                        autocomplete="new-password"
                        validators={[isRequired()]}
                        asterisque="true"
                        errorText="Veuillez choisir une catégorie"
                        initialValue={formState.inputs["2_note_adaptation"].value}
                        initialValid={formState.inputs["2_note_adaptation"].isValid}
                        content={notesOptions}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_note_adaptation"].value, "2_note_adaptation")}
                    />
                    <Input
                        id="2_note_force_proposition"
                        name="2_note_force_proposition"
                        type="text"
                        typeOfInput="select"
                        label="Force de proposition"
                        styles={styles.input}
                        validators={[isRequired()]}
                        asterisque="true"
                        autocomplete="new-password"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        errorText="Veuillez choisir une catégorie"
                        initialValue={formState.inputs["2_note_force_proposition"].value}
                        initialValid={formState.inputs["2_note_force_proposition"].isValid}
                        content={notesOptions}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_note_force_proposition"].value, "2_note_force_proposition")}
                    />
                    <Input
                        id="2_note_connaissances"
                        name="2_note_connaissances"
                        type="text"
                        typeOfInput="select"
                        label="Connaissances"
                        styles={styles.input}
                        validators={[isRequired()]}
                        autocomplete="new-password"
                        asterisque="true"
                        errorText="Veuillez choisir une catégorie"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        initialValue={formState.inputs["2_note_connaissances"].value}
                        initialValid={formState.inputs["2_note_connaissances"].isValid}
                        content={notesOptions}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_note_connaissances"].value, "2_note_connaissances")}
                    />
                </section>
                <div className={styles.comments_section}>
                    <Input
                        id="2_comportement_commentaire"
                        name="2_comportement_commentaire"
                        type="text"
                        typeOfInput="textArea"
                        rows="4"
                        label="Commentaires"
                        placeholder=""
                        validators={[isBlank()]}
                        boxStyles={isLoading || finalMessage ? styles.textArea_borderDisabled : styles.textArea_border}
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        initialValue={formState.inputs["2_comportement_commentaire"].value}
                        initialValid={true}
                        onInput={inputHandler}
                        autocomplete="new-password"
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_comportement_commentaire"].value, "2_comportement_commentaire")}
                    />
                </div>
                <h3 className={`${styles.subtitle} ${styles.left_align}`}>
                    Son intérêt au travail
                </h3>
                <section className={styles.section}>
                    <Input
                        id="2_note_rigueur"
                        name="2_note_rigueur"
                        type="text"
                        typeOfInput="select"
                        label="Rigueur et suivi des dossiers"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        styles={styles.input}
                        validators={[isRequired()]}
                        asterisque="true"
                        errorText="Veuillez choisir une catégorie"
                        autocomplete="new-password"
                        initialValue={formState.inputs["2_note_rigueur"].value}
                        initialValid={formState.inputs["2_note_rigueur"].isValid}
                        content={notesOptions}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_note_rigueur"].value, "2_note_rigueur")}
                    />
                    <Input
                        id="2_note_motivation"
                        name="2_note_motivation"
                        type="text"
                        typeOfInput="select"
                        multiple={false} 
                        label="Motivation"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        styles={styles.input}
                        autocomplete="new-password"
                        validators={[isRequired()]}
                        asterisque="true"
                        errorText="Veuillez choisir une catégorie"
                        initialValue={formState.inputs["2_note_motivation"].value}
                        initialValid={formState.inputs["2_note_motivation"].isValid}
                        content={notesOptions}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_note_motivation"].value, "2_note_motivation")}
                    />
                    <Input
                        id="2_note_qualite"
                        name="2_note_qualite"
                        type="text"
                        typeOfInput="select"
                        autocomplete="new-password"
                        label="Qualité"
                        styles={styles.input}
                        validators={[isRequired()]}
                        asterisque="true"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        errorText="Veuillez choisir une catégorie"
                        initialValue={formState.inputs["2_note_qualite"].value}
                        initialValid={formState.inputs["2_note_qualite"].isValid}
                        content={notesOptions}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_note_qualite"].value, "2_note_qualite")}
                    />
                    <Input
                        id="2_note_quantite"
                        name="2_note_quantite"
                        type="text"
                        typeOfInput="select"
                        label="Quantité"
                        styles={styles.input}
                        autocomplete="new-password"
                        validators={[isRequired()]}
                        asterisque="true"
                        errorText="Veuillez choisir une catégorie"
                        initialValue={formState.inputs["2_note_quantite"].value}
                        initialValid={formState.inputs["2_note_quantite"].isValid}
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        content={notesOptions}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_note_quantite"].value, "2_note_quantite")}
                    />
                    <Input
                        id="2_note_ecoute"
                        name="2_note_ecoute"
                        type="text"
                        typeOfInput="select"
                        label="Écoute, expression écrite et orale"
                        styles={styles.input}
                        autocomplete="new-password"
                        validators={[isRequired()]}
                        asterisque="true"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        errorText="Veuillez choisir une catégorie"
                        initialValue={formState.inputs["2_note_ecoute"].value}
                        initialValid={formState.inputs["2_note_ecoute"].isValid}
                        content={notesOptions}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_note_ecoute"].value, "2_note_ecoute")}
                    />
                    <Input
                        id="2_note_disponibilite"
                        name="2_note_disponibilite"
                        type="text"
                        typeOfInput="select"
                        label="Disponibilité"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        styles={styles.input}
                        validators={[isRequired()]}
                        asterisque="true"
                        autocomplete="new-password"
                        errorText="Veuillez choisir une catégorie"
                        initialValue={formState.inputs["2_note_disponibilite"].value}
                        initialValid={formState.inputs["2_note_disponibilite"].isValid}
                        content={notesOptions}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_note_disponibilite"].value, "2_note_disponibilite")}
                    />
                </section>
                <div className={styles.comments_section}>
                    <Input
                        id="2_interet_commentaire"
                        name="2_interet_commentaire"
                        type="text"
                        typeOfInput="textArea"
                        rows="4"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        label="Commentaires"
                        validators={[isBlank()]}
                        autocomplete="new-password"
                        placeholder=""
                        boxStyles={isLoading || finalMessage ? styles.textArea_borderDisabled : styles.textArea_border}
                        initialValue={formState.inputs["2_interet_commentaire"].value}
                        initialValid={true}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_interet_commentaire"].value, "2_interet_commentaire")}
                    />
                </div>
                <h3 className={`${styles.subtitle} ${styles.left_align}`}>
                    Savoir-faire
                </h3>
                <section className={styles.section}>
                    <Input
                        id="2_note_bureautique"
                        name="2_note_bureautique"
                        type="text"
                        typeOfInput="select"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        label="Bureautique"
                        styles={styles.input}
                        validators={[isRequired()]}
                        autocomplete="new-password"
                        asterisque="true"
                        errorText="Veuillez choisir une catégorie"
                        initialValue={formState.inputs["2_note_bureautique"].value}
                        initialValid={formState.inputs["2_note_bureautique"].isValid}
                        content={notesOptions}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_note_bureautique"].value, "2_note_bureautique")}
                    />
                    <Input
                        id="2_note_outil_travail"
                        name="2_note_outil_travail"
                        type="text"
                        typeOfInput="select"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        label="Outils de travail"
                        styles={styles.input}
                        validators={[isRequired()]}
                        asterisque="true"
                        autocomplete="new-password"
                        errorText="Veuillez choisir une catégorie"
                        initialValue={formState.inputs["2_note_outil_travail"].value}
                        initialValid={formState.inputs["2_note_outil_travail"].isValid}
                        content={notesOptions}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_note_outil_travail"].value, "2_note_outil_travail")}
                    />
                    <Input
                        id="2_note_expertise_metier"
                        name="2_note_expertise_metier"
                        type="text"
                        typeOfInput="select"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        label="Expertise spécifique métier"
                        styles={styles.input}
                        validators={[isRequired()]}
                        asterisque="true"
                        autocomplete="new-password"
                        errorText="Veuillez choisir une catégorie"
                        initialValue={formState.inputs["2_note_expertise_metier"].value}
                        initialValid={formState.inputs["2_note_expertise_metier"].isValid}
                        content={notesOptions}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_note_expertise_metier"].value, "2_note_expertise_metier")}
                    />
                </section>
                <div className={styles.comments_section}>
                    <Input
                        id="2_technique_commentaire"
                        name="2_technique_commentaire"
                        type="text"
                        autocomplete="new-password"
                        typeOfInput="textArea"
                        rows="4"
                        label="Commentaires"
                        validators={[isBlank()]}
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        placeholder=""
                        boxStyles={isLoading || finalMessage ? styles.textArea_borderDisabled : styles.textArea_border}
                        initialValue={formState.inputs["2_technique_commentaire"].value}
                        initialValid={true}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_technique_commentaire"].value, "2_technique_commentaire")}
                    />
                </div>
                <h3 className={`${styles.subtitle} ${styles.left_align}`}>
                    Aptitudes organisationnelles
                </h3>
                <section className={styles.section}>
                    <Input
                        id="2_note_organisation_travail"
                        name="2_note_organisation_travail"
                        type="text"
                        typeOfInput="select"
                        label="Organisation du travail"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        styles={styles.input}
                        validators={[isRequired()]}
                        asterisque="true"
                        autocomplete="new-password"
                        errorText="Veuillez choisir une catégorie"
                        initialValue={formState.inputs["2_note_organisation_travail"].value}
                        initialValid={formState.inputs["2_note_organisation_travail"].isValid}
                        content={notesOptions}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_note_organisation_travail"].value, "2_note_organisation_travail")}
                    />
                    <Input
                        id="2_note_respect_delai"
                        name="2_note_respect_delai"
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        type="text"
                        typeOfInput="select"
                        label="Respect des délais"
                        styles={styles.input}
                        validators={[isRequired()]}
                        asterisque="true"
                        errorText="Veuillez choisir une catégorie"
                        autocomplete="new-password"
                        initialValue={formState.inputs["2_note_respect_delai"].value}
                        initialValid={formState.inputs["2_note_respect_delai"].isValid}
                        content={notesOptions}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_note_respect_delai"].value, "2_note_respect_delai")}
                    />
                </section>
                <h3 className={`${styles.subtitle} ${styles.left_align}`}>
                    Pour les managers d’étudiant.e.s à l’IA et à la Cyber Management School uniquement
                </h3>
                <div className={styles.comments_section}>
                    <Input
                        id="2_ia_langage"
                        name="2_ia_langage"
                        type="text"
                        typeOfInput="textArea"
                        autocomplete="new-password"
                        rows="4"
                        label="Quel(s) langage(s) et outil(s) technique(s) utilisez-vous ?"
                        placeholder=""
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        boxStyles={isLoading || finalMessage ? styles.textArea_borderDisabled : styles.textArea_border}
                        validators={[isBlank()]}
                        errorText="Texte uniquement"
                        initialValue={formState.inputs["2_ia_langage"].value}
                        initialValid={true}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_ia_langage"].value, "2_ia_langage")}
                    />
                    <Input
                        id="2_ia_commentaire"
                        name="2_ia_commentaire"
                        type="text"
                        typeOfInput="textArea"
                        rows="4"
                        label="Commentez la montée en compétences de votre alternant.e"
                        autocomplete="new-password"
                        validators={[isBlank()]}
                        placeholder=""
                        boxStyles={isLoading || finalMessage ? styles.textArea_borderDisabled : styles.textArea_border}
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        initialValue={formState.inputs["2_ia_commentaire"].value}
                        initialValid={true}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["2_ia_commentaire"].value, "2_ia_commentaire")}
                    />
                </div>
                <h1 className={styles.title}>3. Perspectives d'évolution</h1>
                <h3 className={styles.part_subtitle}>à remplir ensemble</h3>
                <section className={styles.section_alt}>
                    <Input
                        id="3_objectifs"
                        name="3_objectifs"
                        type="text"
                        typeOfInput="textArea"
                        disabled={isLoading || finalMessage ? true : false}
                        rows="4"
                        label="Quels sont les objectifs et/ou perspectives pour le semestre à venir ?"
                        placeholder=""
                        boxStyles={isLoading || finalMessage ? styles.textArea_borderDisabled : styles.textArea_border}
                        validators={[isRequired()]}
                        asterisque="true"
                        errorText="Texte uniquement"
                        initialValue={formState.inputs["3_objectifs"].value}
                        autocomplete="new-password"
                        initialValid={formState.inputs["3_objectifs"].isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["3_objectifs"].value, "3_objectifs")}
                    />
                    <Input
                        id="3_moyens"
                        name="3_moyens"
                        type="text"
                        disabled={isLoading || finalMessage ? true : false}
                        typeOfInput="textArea"
                        rows="4"
                        label="Que mettre en place pour atteindre ces objectifs ? (Délais, moyens, etc...)"
                        placeholder=""
                        boxStyles={isLoading || finalMessage ? styles.textArea_borderDisabled : styles.textArea_border}
                        validators={[isRequired()]}
                        autocomplete="new-password"
                        asterisque="true"
                        errorText="Texte uniquement"
                        initialValue={formState.inputs["3_moyens"].value}
                        initialValid={formState.inputs["3_moyens"].isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["3_moyens"].value, "3_moyens")}
                    />
                    <Input
                        id="3_souhait_stagiaire"
                        name="3_souhait_stagiaire"
                        disabled={isLoading || finalMessage ? true : false}
                        type="text"
                        typeOfInput="textArea"
                        rows="4"
                        label="Proposition de l'étudiant.e : Quelles évolutions envisagez-vous, en termes de responsabilités, à court, moyen et long terme ?"
                        placeholder=""
                        boxStyles={isLoading || finalMessage ? styles.textArea_borderDisabled : styles.textArea_border}
                        validators={[isRequired()]}
                        asterisque="true"
                        errorText="Texte uniquement"
                        initialValue={formState.inputs["3_souhait_stagiaire"].value}
                        autocomplete="new-password"
                        initialValid={formState.inputs["3_souhait_stagiaire"].isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["3_souhait_stagiaire"].value, "3_souhait_stagiaire")}
                    />
                    <Input
                        id="3_proposition_tuteur"
                        name="3_proposition_tuteur"
                        type="text"
                        disabled={isLoading || finalMessage ? true : false}
                        typeOfInput="textArea"
                        rows="4"
                        label="Proposition du tuteur : Quelles évolutions envisagez-vous pour votre étudiant.e, à court, moyen et long terme ?"
                        placeholder=""
                        boxStyles={isLoading || finalMessage ? styles.textArea_borderDisabled : styles.textArea_border}
                        validators={[isRequired()]}
                        asterisque="true"
                        errorText="Texte uniquement"
                        autocomplete="new-password"
                        initialValue={formState.inputs["3_proposition_tuteur"].value}
                        initialValid={formState.inputs["3_proposition_tuteur"].isValid}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["3_proposition_tuteur"].value, "3_proposition_tuteur")}
                    />
                </section>
                <h1 className={styles.title}>4. Synthèse de l’entretien</h1>
                <div className={styles.comments_section}>
                    <p>
                        Veuillez choisir une ou plusieurs des options ci-dessous (étudiant.e) <span style={{color : 'red'}}>*</span>
                    </p>
                    <div className={styles.radioSection}>
                        <div className={styles.radio}>
                            <CheckBox
                                id={"4_choix_stagiaire_1"}
                                key={"4_choix_stagiaire_1"}
                                name={"4_choix_stagiaire_1"}
                                checkBoxStyles={styles.checkBox}
                                checkmark={styles.checkmark}
                                disabled={(auth.userRole == "tuteur") || isLoading || finalMessage ? true : false}
                                isChecked={checkbox_1}
                                onChange={() => {
                                    setCheckbox_1(!checkbox_1);
                                    formState.inputs["4_choix_stagiaire_1"].value = !checkbox_1 ? "Conscient.e des points d'amélioration à mettre en place" : "";
                                    uploadDocument(formState.inputs["4_choix_stagiaire_1"].value, "4_choix_stagiaire_1");
                                }}
                            />
                                <p>Conscient.e des points d'amélioration à mettre en place</p>
                        </div>
                        <div className={styles.radio}>
                            <CheckBox
                                id={"4_choix_stagiaire_2"}
                                key={"4_choix_stagiaire_2"}
                                name={"4_choix_stagiaire_2"}
                                checkBoxStyles={styles.checkBox}
                                checkmark={styles.checkmark}
                                disabled={(auth.userRole == "tuteur") || isLoading || finalMessage ? true : false}
                                isChecked={checkbox_2}
                                onChange={() => {
                                    setCheckbox_2(!checkbox_2);
                                    formState.inputs["4_choix_stagiaire_2"].value = !checkbox_2 ? "Propositions d'actions à mettre en place pour atteindre le niveau attendu" : "";
                                    uploadDocument(formState.inputs["4_choix_stagiaire_2"].value, "4_choix_stagiaire_2");
                                }}
                            />
                                <p>Propositions d'actions à mettre en place pour atteindre le niveau attendu</p>
                        </div>
                        <div className={styles.radio}>
                            <CheckBox
                                id={"4_choix_stagiaire_3"}
                                key={"4_choix_stagiaire_3"}
                                name={"4_choix_stagiaire_3"}
                                checkBoxStyles={styles.checkBox}
                                checkmark={styles.checkmark}
                                isChecked={checkbox_3}
                                disabled={(auth.userRole == "tuteur") || isLoading || finalMessage ? true : false}
                                onChange={() => {
                                    setCheckbox_3(!checkbox_3);
                                    formState.inputs["4_choix_stagiaire_3"].value = !checkbox_3 ? "Très épanoui.e en entreprise" : "";
                                    uploadDocument(formState.inputs["4_choix_stagiaire_3"].value, "4_choix_stagiaire_3");
                                }}
                            />
                                <p>Très épanoui.e en entreprise</p>
                        </div>
                        <div className={styles.radio}>
                            <CheckBox
                                id={"4_choix_stagiaire_4"}
                                key={"4_choix_stagiaire_4"}
                                disabled={(auth.userRole == "tuteur") || isLoading || finalMessage ? true : false}
                                name={"4_choix_stagiaire_4"}
                                checkBoxStyles={styles.checkBox}
                                checkmark={styles.checkmark}
                                isChecked={checkbox_4}
                                onChange={() => {
                                    setCheckbox_4(!checkbox_4);
                                    formState.inputs["4_choix_stagiaire_4"].value = !checkbox_4 ? "Attentes particulières vis à vis de l'organisation et du tuteur.trice" : "";
                                    uploadDocument(formState.inputs["4_choix_stagiaire_4"].value, "4_choix_stagiaire_4");
                                }}
                            />
                                <p>Attentes particulières vis à vis de l'organisation et du tuteur.trice</p>
                        </div>
                        <div className={styles.radio}>
                            <CheckBox
                                id={"4_choix_stagiaire_5"}
                                key={"4_choix_stagiaire_5"}
                                disabled={(auth.userRole == "tuteur") || isLoading || finalMessage ? true : false}
                                name={"4_choix_stagiaire_5"}
                                checkBoxStyles={styles.checkBox}
                                checkmark={styles.checkmark}
                                isChecked={checkbox_5}
                                onChange={() => {
                                    setCheckbox_5(!checkbox_5);
                                    formState.inputs["4_choix_stagiaire_5"].value = !checkbox_5 ? "Souhaite être recruté.e à l'issue du diplôme" : "";
                                    uploadDocument(formState.inputs["4_choix_stagiaire_5"].value, "4_choix_stagiaire_5");
                                }}
                            />
                                <p>Souhaite être recruté.e à l'issue du diplôme</p>
                        </div>
                    </div>
                    <Input
                        id="4_commentaire_stagiaire"
                        name="4_commentaire_stagiaire"
                        type="text"
                        autocomplete="new-password"
                        typeOfInput="textArea"
                        rows="10"
                        disabled={(auth.userRole == "tuteur") || isLoading || finalMessage ? true : false}
                        label="Commentaires de l'étudiant.e sur cet entretien"
                        validators={[isBlank()]}
                        placeholder=""
                        boxStyles={isLoading || finalMessage ? styles.textArea_borderDisabled : styles.textArea_border}
                        initialValue={formState.inputs["4_commentaire_stagiaire"].value}
                        initialValid={true}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["4_commentaire_stagiaire"].value, "4_commentaire_stagiaire")}
                    />
                </div>
                <div className={styles.comments_section}>
                <p>
                        Veuillez choisir une ou plusieurs des options ci-dessous (tuteur ou tutrice) <span style={{color : 'red'}}>*</span>
                    </p>
                    <div className={styles.radioSection}>
                        <div className={styles.radio}>
                            <CheckBox
                                id={"4_choix_tuteur_1"}
                                key={"4_choix_tuteur_1"}
                                disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                                name={"4_choix_tuteur_1"}
                                isChecked={checkbox_6}
                                checkBoxStyles={styles.checkBox}
                                checkmark={styles.checkmark}
                                onChange={() => {
                                    setCheckbox_6(!checkbox_6);
                                    formState.inputs["4_choix_tuteur_1"].value = !checkbox_6 ? "Point.s d'amélioration prioritaire.s" : "";
                                    uploadDocument(formState.inputs["4_choix_tuteur_1"].value, "4_choix_tuteur_1");
                                }}
                            />
                                <p>Point.s d'amélioration prioritaire.s</p>
                        </div>
                        <div className={styles.radio}>
                            <CheckBox
                                id={"4_choix_tuteur_2"}
                                key={"4_choix_tuteur_2"}
                                checkBoxStyles={styles.checkBox}
                                checkmark={styles.checkmark}
                                name={"4_choix_tuteur_2"}
                                disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                                isChecked={checkbox_7}
                                onChange={() => {
                                    setCheckbox_7(!checkbox_7);
                                    formState.inputs["4_choix_tuteur_2"].value = !checkbox_7 ? "En dessous du niveau attendu" : "";
                                    uploadDocument(formState.inputs["4_choix_tuteur_2"].value, "4_choix_tuteur_2");
                                }}
                            />
                                <p>En dessous du niveau attendu</p>
                        </div>
                        <div className={styles.radio}>
                            <CheckBox
                                id={"4_choix_tuteur_3"}
                                key={"4_choix_tuteur_3"}
                                name={"4_choix_tuteur_3"}
                                checkBoxStyles={styles.checkBox}
                                checkmark={styles.checkmark}
                                disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                                isChecked={checkbox_8}
                                onChange={() => {
                                    setCheckbox_8(!checkbox_8);
                                    formState.inputs["4_choix_tuteur_3"].value = !checkbox_8 ? "Atteinte du niveau attendu" : "";
                                    uploadDocument(formState.inputs["4_choix_tuteur_3"].value, "4_choix_tuteur_3");
                                }}
                            />
                                <p>Atteinte du niveau attendu</p>
                        </div>
                        <div className={styles.radio}>
                            <CheckBox
                                id={"4_choix_tuteur_4"}
                                key={"4_choix_tuteur_4"}
                                name={"4_choix_tuteur_4"}
                                checkBoxStyles={styles.checkBox}
                                checkmark={styles.checkmark}
                                disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                                isChecked={checkbox_9}
                                onChange={() => {
                                    setCheckbox_9(!checkbox_9);
                                    formState.inputs["4_choix_tuteur_4"].value = !checkbox_9 ? "Au dessus du niveau attendu" : "";
                                    uploadDocument(formState.inputs["4_choix_tuteur_4"].value, "4_choix_tuteur_4");
                                }}
                            />
                                <p>Au dessus du niveau attendu</p>
                        </div>
                        <div className={styles.radio}>
                            <CheckBox
                                id={"4_choix_tuteur_5"}
                                key={"4_choix_tuteur_5"}
                                name={"4_choix_tuteur_5"}
                                checkBoxStyles={styles.checkBox}
                                checkmark={styles.checkmark}
                                disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                                isChecked={checkbox_10}
                                onChange={() => {
                                    setCheckbox_10(!checkbox_10);
                                    formState.inputs["4_choix_tuteur_5"].value = !checkbox_10 ? "Excellence" : "";
                                    uploadDocument(formState.inputs["4_choix_tuteur_5"].value, "4_choix_tuteur_5");
                                }}
                            />
                                <p>Excellence</p>
                        </div>
                        <div className={styles.radio}>
                            <CheckBox
                                id={"4_choix_tuteur_6"}
                                key={"4_choix_tuteur_6"}
                                name={"4_choix_tuteur_6"}
                                checkBoxStyles={styles.checkBox}
                                checkmark={styles.checkmark}
                                disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                                isChecked={checkbox_11}
                                onChange={() => {
                                    setCheckbox_11(!checkbox_11);
                                    formState.inputs["4_choix_tuteur_6"].value = !checkbox_11 ? "Perspective d'embauche à l'issue du diplôme" : "";
                                    uploadDocument(formState.inputs["4_choix_tuteur_6"].value, "4_choix_tuteur_6");
                                }}
                            />
                                <p>Perspective d'embauche à l'issue du diplôme</p>
                        </div>
                    </div>          
                    <Input
                        id="4_commentaire_tuteur"
                        name="4_commentaire_tuteur"
                        type="text"
                        typeOfInput="textArea"
                        autocomplete="new-password"
                        rows="10"
                        label="Commentaires du tuteur sur cet entretien"
                        placeholder=""
                        validators={[isBlank()]}
                        disabled={(auth.userRole == "student") || isLoading || finalMessage ? true : false}
                        boxStyles={isLoading || finalMessage ? styles.textArea_borderDisabled : styles.textArea_border}
                        initialValue={formState.inputs["4_commentaire_tuteur"].value}
                        initialValid={true}
                        onInput={inputHandler}
                        onBlurAltFctn={() => uploadDocument(formState.inputs["4_commentaire_tuteur"].value, "4_commentaire_tuteur")}
                    />
                </div>
        <div className={styles.wrapper}>
          <h1 className={styles.title}>5. Clôture de l'évaluation</h1>
          <h3 className={styles.closure_subtitle}>
          Attention, quand vous finaliserez ce formulaire, il ne sera plus modifiable ni par l'étudiant ni par le tuteur. Merci de veiller à ce que l'ensemble des champs soient complétés.
          </h3>
          {!finalWarning && (
            <div className={styles.closure_btn_section}>
                <ActionBtn
                id="closure"
                btnType="contained"
                activeBtnStyle={styles.btn_active_closure}
                btnText="Êtes-vous sûr.e de valider définitivement le formulaire ?"
                iconColor={styles.btn_icon}
                textStyle={styles.btn_text}
                onClick={() => {
                        checkNotValidInput();
                    }
                }
                />
            </div>
          )}
        </div>
      {finalWarning && !finalMessage && (
          <ActionBtn
          id="closure"
          btnType="contained"
          activeBtnStyle={styles.btn_active_closure}
          btnText="Valider définitivement le formulaire"
          iconColor={styles.btn_icon}
          textStyle={styles.btn_text}
          onClick={formSubmitHandler}
          />
      )}
      {finalMessage && (
          <div className={styles.wrapper}>
            <h3 className={styles.closure_subtitle}>
            Le support d'évaluation a bien été rempli. Merci.
          </h3>
          <ActionBtn
              id="5"
              btnType="outlined"
              activeBtnStyle={styles.btn_active_outlined}
              btnText="Retourner au menu principal"
              iconColor={styles.outlined_btn_icon}
              textStyle={styles.btn_text_outlined}
              onClick={() => {
                history.push('/');
              }}
            />
          </div>
          )}
                <div>
                    <p className={styles.disclaimer}>
                    Les informations saisies sont uniquement destinées à répondre aux besoins administratifs et pédagogiques du Groupe GEMA. Elles ne peuvent en aucun cas être communiquées à des tiers sans votre consentement. Pour en savoir plus, contactez-nous à l’adresse suivante :{" "}
                        <a href="mailto:carrieres.tuteurs@groupe-gema.com?subject=Question concernant le passeport apprentissage">
                        carrieres.tuteurs@groupe-gema.com
                        </a>
                    </p>
                </div>
            </div>
        </article>
    );
};

export default EvalPartOne;
