import React from "react";
import { useWindowDimensions } from "../../../../hooks/window-dimensions-hook";

// Components
import ListHeader from "../../../ListHeader/ListHeader";
import GradesList from "../GradesList/GradesList";
import DownloadDocBtn from "../../../Buttons/DownloadDocBtn/DownloadDocBtn";

// Icons
import pdfIcon from "../../../../assets/images/file-pdf.svg";

// Styles
import styles from "./GradesByYearBlock.module.scss";

const GradesByYearBlock = ({ year, grades }) => {

    // Window Size Hook
    const { width } = useWindowDimensions();

    const link = `${process.env.REACT_APP_API_HOST}/documents/generate-document/grades?currentYear=${year}&semester=Q1`;
    return (
        <section className={styles.container}>
            <div className={styles.wrapper}>
                <h3 className={styles.subtitle}>Année scolaire {year}</h3>
                <div className={styles.btn_container}>
                    <DownloadDocBtn
                        type="link"
                        text="Télécharger au format PDF"
                        link={link}
                        icon={pdfIcon}
                        iconColor={styles.iconColor}
                    />
                </div>
            </div>
            {width >= 666 && <GradesList grades={grades} />}
        </section>
    );
};

export default GradesByYearBlock;
