import React from "react";
// Styles
import styles from "./Post.module.scss";

const Post = ({ title, image, content }) => {
    const imageId = image.split("/")[5];
    return (
        <article className={styles.container}>
            <div className={styles.img_wrapper}>
                <img className={styles.img} src={`${process.env.REACT_APP_API_HOST}/documents/get-image-contact/${imageId}`} alt="Post img" />
            </div>
            <div className={styles.text_container}>
                <div className={styles.text_wrapper}>
                    <h2
                        className={styles.title}
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    ></h2>
                    <p
                        className={styles.content}
                        dangerouslySetInnerHTML={{
                            __html: content,
                        }}
                    ></p>
                </div>
            </div>
        </article>
    );
};

export default Post;
