import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/auth-context";
import { StudentContext } from "../../context/student-context";
import { useHttpRequest } from "../../hooks/httpRequest-hook";

// Components
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import HttpMessagePrompt from "../../components/HttpMessagePrompt/HttpMessagePrompt";
import HeaderBanner from "../../components/HeaderBanner/HeaderBanner";
import BiblioCard from "../../components/BiblioCard/BiblioCard";

// Images & Icons
import Ecologie from "../../assets/images/bibliotheque_dvpt_durable.png";
import Business from "../../assets/images/bibliotheque_business.png";
import Digital from "../../assets/images/bibliotheque_digital.png";
import IA from "../../assets/images/bibliotheque_ia.png";
import SoftSkill from "../../assets/images/bibliotheque_soft_skill.png";
import Langue from "../../assets/images/bibliotheque_langue.png";
import biblioIcon from "../../assets/images/bibliotheque_digitale.svg";

// Styles
import styles from "./DigitalResources.module.scss";

const DigitalResources = () => {
    const auth = useContext(AuthContext);

    const [isOpen, setIsOpen] = useState(true);
    const { isLoading, error, okHttp, sendRequest } = useHttpRequest();
    const [setBddError] = useState(false);

    const [contentCategory, setContentCategory] = useState(false);
    const [currentContent, setCurrentContent] = useState(false);

    const context = useContext(StudentContext);

    const [category] = useState([
        {
            default: "business",
            label: "BUSINESS",
        },
        {
            default: "dvpt_durable",
            label: "RSE ET ENVIRONNEMENT",
        },
        {
            default: "digital",
            label: "DIGITAL",
        },
        {
            default: "ia",
            label: "IA / BIG DATA",
        },
        {
            default: "langue",
            label: "LANGUES",
        },
        {
            default: "soft_skill",
            label: "SOFT SKILLS",
        },
    ]);

    const fnct = (event) => {
        // Fonction qui va servir à nettoyer la vue, fetch les vidéos correspondantes au Card lequel on vient de cliquer, puis les afficher.
        setIsOpen(false);
        setCurrentContent(contentCategory[event.target.innerHTML]);
    };

    const arrayConstructor = async (obj) => {
        let newArray = [];

        // Fonction pour remplacer le label par défaut des catégories
        for (let key of Object.entries(obj.categories)) {
            category.forEach((cat) => {
                if (key[0] === cat.default) {
                    newArray[cat.label] = [];
                }
            });
        }

        // Fonction pour associer les items dans chaque catégories en mappant également sur les labels en uppercase pour formater le tableau.
        for (const [key, value] of Object.entries(obj.items)) {
            category.forEach((cat) => {
                if (value.hashtag[0] === cat.default) {
                    if (newArray[cat.label]) {
                        newArray[cat.label].push(value);
                    }
                }
            });
        }
        return newArray;
    };

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const url = `${process.env.REACT_APP_API_HOST}/bibliotheque/get-content`;
                const contentResponse = await sendRequest(url, "GET", null, {
                    Authorization: "Bearer " + auth.token,
                });

                const data = await arrayConstructor(contentResponse);
                setContentCategory(data);
            } catch (err) {
                console.error(err);
            }
        };
        fetchContent();
    }, []);

    return (
        <>
            {isLoading && (
                <div className="spinner">
                    <LoadingSpinner />
                </div>
            )}
            {error && (
                <div className="error_wrapper">
                    <HttpMessagePrompt error={error} />
                </div>
            )}
            <main className={styles.container}>
                <HeaderBanner
                    title="Bibliothèque Digitale"
                    icon={biblioIcon}
                    iconColor={styles.icon_color}
                    bgColor={styles.banner_color}
                />
                {isOpen && !isLoading && contentCategory && (
                    <div className={styles.cardContainer}>
                        <BiblioCard
                            img={Ecologie}
                            text="RSE ET ENVIRONNEMENT"
                            style={styles.biblioCard}
                            onClick={fnct}
                            content={contentCategory["RSE ET ENVIRONNEMENT"]}
                        />
                        <BiblioCard
                            img={Business}
                            text="BUSINESS"
                            style={styles.biblioCard}
                            onClick={fnct}
                            content={contentCategory["BUSINESS"]}
                        />
                        <BiblioCard
                            img={Digital}
                            text="DIGITAL"
                            style={styles.biblioCard}
                            onClick={fnct}
                            content={contentCategory["DIGITAL"]}
                        />
                        <BiblioCard
                            img={IA}
                            text="IA / BIG DATA"
                            style={styles.biblioCard}
                            onClick={fnct}
                            content={contentCategory["IA / BIG DATA"]}
                        />
                        <BiblioCard
                            img={SoftSkill}
                            text="SOFT SKILLS"
                            style={styles.biblioCard}
                            onClick={fnct}
                            content={contentCategory["SOFT SKILLS"]}
                        />
                        <BiblioCard
                            img={Langue}
                            text="LANGUES"
                            style={styles.biblioCard}
                            onClick={fnct}
                            content={contentCategory["LANGUES"]}
                        />
                    </div>
                )}
                {!isOpen && currentContent && (
                    <>
                        <button
                            className={styles.backButton}
                            onClick={() => {
                                setIsOpen(true);
                            }}
                        >
                            REVENIR A LA BIBLIOTÈQUE
                        </button>
                        <p className={styles.itemContainer}>MOOCS</p>
                        <section className={styles.moocsSection}>
                            {currentContent.map((item) => {
                                if (item.type === "video")
                                    return (
                                        <div key={item.href} className={styles.itemVideo}>
                                            <iframe
                                                width="100%"
                                                height="200"
                                                src={`https://www.youtube.com/embed/${item.href}`}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                                title="Embedded youtube"
                                            />
                                            <p className={styles.videoTitle}>
                                                {item.labels.default}
                                            </p>
                                        </div>
                                    );
                            })}
                        </section>
                        <p className={styles.itemContainer}>ARTICLES</p>
                        <section className={styles.moocsSection}>
                            {currentContent.map((item) => {
                                if (item.type === "link")
                                    return (
                                        <div key={item.href} className={styles.itemLink}>
                                            <a
                                                href={item.href}
                                                rel="noreferrer"
                                                target="_blank"
                                                className={styles.anchor}
                                            >
                                                {item.labels.default}
                                            </a>
                                        </div>
                                    );
                            })}
                        </section>
                        <p className={styles.itemContainer}>PODCASTS</p>
                        <section className={styles.moocsSection}>
                            {currentContent.map((item) => {
                                if (item.type === "podcast")
                                    return (
                                        <div key={item.href} className={styles.itemLink}>
                                            <a
                                                href={item.href}
                                                rel="noreferrer"
                                                target="_blank"
                                                className={styles.anchor}
                                            >
                                                {item.labels.default}
                                            </a>
                                        </div>
                                    );
                            })}
                        </section>
                    </>
                )}
            </main>
        </>
    );
};

export default DigitalResources;
