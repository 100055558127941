import React from "react";

// Components
import ActionBtn from "../../components/Buttons/ActionBtn/ActionBtn";

// Styles
import styles from "./NotificationBanner.module.scss";

const NotificationBanner = ({ bannerStyle, bannerIcon, bannerIconColor, message, link, btnIcon, btnIconColor, hasBtn, btnText, btnFnc }) => {
    
    let btn;
    if (hasBtn) {
        btn = (
            <ActionBtn
                btnType="contained"
                activeBtnStyle={styles.btn_active}
                iconColor={btnIconColor}
                btnText={btnText}
                textStyle={styles.btn_text}
                onClick={btnFnc}
            />
        );
    }

    return (
        <div className={`${styles.container} ${bannerStyle}`}>
            <div className={styles.wrapper}>
                <img className={`${styles.icon} ${bannerIconColor}`} src={bannerIcon} alt="" />
                <h4 className={styles.text}>{message} 
                {/* // Add link if there is on props */}
                {link && (
                    <a href={link} target="_blank" rel="noreferrer">{link}</a>
                )}</h4>
                {btn}
            </div>
        </div>
    );
};

export default NotificationBanner;
