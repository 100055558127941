import React, { useState, useEffect, useContext } from "react";
import { useHttpRequest } from "../../hooks/httpRequest-hook";
import { AuthContext } from "../../context/auth-context";

// Components
import HeaderBanner from "../../components/HeaderBanner/HeaderBanner";
import TabView from "../../components/TabView/TabView";
// import DocumentListDisplay from "../../components/GenericTabs/DocumentsListDisplay/DocumentsListDisplay";

// Icons
import contactsIcon from "../../assets/images/address-book.svg";
import contacts from "../../assets/images/contacts.png";
import MyGemaContact from "../MyGemaContact/MyGemaContact";

// Styles
import styles from "./Contacts.module.scss";

const Contacts = () => {
    // Authentication context
    const auth = useContext(AuthContext);
    
    const { isLoading, error, sendRequest } = useHttpRequest();

    const [invoicingNav] = useState([
        {
            id: "mygema_contact",
            btnText: "CONTACTS",
            btnType: "text",
        },
    ]);

    const poleLabels = useState([
        {
            label: "pole_pedagogique",
            default: "PÔLE PÉDAGOGIQUE",
        },
    ]);

    const loadAndStructure = async (content) => {
        // Bout de code qui va restructurer le content reçu en tableau de tableaux pour chaque pôle ainsi que modifier le nom des pôles avec poleLabels.
        content.map((item) => {
            poleLabels[0].forEach((label) => {
                if (item.chapter === label.label) {
                    item.chapter = label.default;
                }
            });
        });
        return content;
    };


    const [tabViews, SetTabViews] = useState();

    // Fetch Documents
    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const url = `${process.env.REACT_APP_API_STUDENT}/documents/config/admin_resources`;
                const documents = await sendRequest(url, "GET", null, {
                    Authorization: "Bearer " + auth.token,
                });

                const myGemaContact = await loadAndStructure(documents.mygema_contact);

                let tabViewArray = [];

                tabViewArray.push({
                    id: "mygema_contact",
                    view: (
                        <MyGemaContact
                            id="mygema_contact"
                            content={myGemaContact}
                            poles={poleLabels}
                        />
                    ),
                });
                SetTabViews(tabViewArray);
            } catch (err) {
                console.error(err);
            }
        };
        fetchDocuments();
    }, []);

    return (
        <main className={styles.container}>
            <HeaderBanner
                title="Contacts"
                icon={contactsIcon}
                iconColor={styles.icon_color}
                bgColor={styles.banner_color}
            />
             {!isLoading && invoicingNav && tabViews && (
                 <TabView
                     menuConfig={invoicingNav}
                     tabViews={tabViews}
                     activeBtnColor={styles.btn_active}
                 />
             )}
        </main>
    );
};

export default Contacts;
