import React from "react";

// Styles
import styles from "./CalendarLeyend.module.scss";

const CalendarLeyend = ({ user }) => {
    let leyend;
    if (user == "student") {
        leyend = (
            <div className={styles.legend_container}>
                <div className={styles.wrapper}>
                    <div className={`${styles.color_box} ${styles.student_scheduled}`}></div>
                    <p className={styles.legend_text}>Planifié</p>
                </div>
                <div className={styles.wrapper}>
                    <div className={`${styles.color_box} ${styles.student_realized}`}></div>
                    <p className={styles.legend_text}>Complété</p>
                </div>
                <div className={styles.wrapper}>
                    <div className={`${styles.color_box} ${styles.student_realized}`}></div>
                    <p className={styles.legend_text}>Réalisé</p>
                </div>
                <div className={styles.wrapper}>
                    <div className={`${styles.color_box} ${styles.student_cancelled}`}></div>
                    <p className={styles.legend_text}>Annulé</p>
                </div>
            </div>
        );
    } else {
        leyend = (
            <div className={styles.legend_container}>
                <div className={styles.wrapper}>
                    <div className={`${styles.color_box} ${styles.scheduled}`}></div>
                    <p className={styles.legend_text}>Planifié</p>
                </div>
                <div className={styles.wrapper}>
                    <div className={`${styles.color_box} ${styles.realized}`}></div>
                    <p className={styles.legend_text}>Réalisé</p>
                </div>
                <div className={styles.wrapper}>
                    <div className={`${styles.color_box} ${styles.invoiced}`}></div>
                    <p className={styles.legend_text}>Facturé</p>
                </div>
                <div className={styles.wrapper}>
                    <div className={`${styles.color_box} ${styles.toCallList}`}></div>
                    <p className={styles.legend_text}>À émarger</p>
                </div>
                <div className={styles.wrapper}>
                    <div className={`${styles.color_box} ${styles.cancelled}`}></div>
                    <p className={styles.legend_text}>Annulé</p>
                </div>
                {/* <div className={styles.wrapper}>
                    <div className={`${styles.color_box} ${styles.replaced}`}></div>
                    <p className={styles.legend_text}>Remplacé</p>
                </div> */}
            </div>
        );
    }
    return leyend;
};

export default CalendarLeyend;
