import { useState, useCallback, useEffect } from "react";

export const useContractorContext = () => {
    // UseState variables of the TeacherContext
    const [contractorMainAccount, setContractorMainAccount] = useState(false);
    const [contractorCampus, setContractorCampus] = useState(false);
    const [contractorTeachersIds, setContractorTeachersIds] = useState(false);
    const [contractorTeachers, setContractorTeachers] = useState(false);
    const [contractorCurrentAccountYear, setCurrentAccountYear] = useState(false);
    const [contractorIds, setContractorIds] = useState(false);


    // teacherSetContext use of useCallback for avoiding infinite loops
    const setContractorContext = useCallback(
        (contractorMainAccount, contractorCampus, contractorTeachersIds, contractorTeachers, contractorCurrentAccountYear, contractorIds) => {

            // Assign Context State Variables
            setContractorMainAccount(contractorMainAccount);
            setContractorCampus(contractorCampus);
            setContractorTeachersIds(contractorTeachersIds);
            setContractorTeachers(contractorTeachers);
            setCurrentAccountYear(contractorCurrentAccountYear);
            setContractorIds(contractorIds);

            // Check Stock context data in the browser's sessionStorage
            const storedContext = JSON.parse(sessionStorage.getItem("contractorContext"));

            // Stock context data in the browser's sessionStorage
            if (!storedContext) {
                sessionStorage.setItem(
                    "contractorContext",
                    JSON.stringify({
                        contractorMainAccount,
                        contractorCampus,
                        contractorTeachersIds,
                        contractorTeachers,
                        contractorCurrentAccountYear,
                        contractorIds
                    })
                );
            }
        }, []);

    // Unset Contractor Context
    const unsetContractorContext = useCallback(() => {
        setContractorMainAccount(null);
        setContractorCampus(null);
        setContractorTeachersIds(null);
        setContractorTeachers(null);
        setCurrentAccountYear(null);
        setContractorIds(null);

        sessionStorage.removeItem("contractorContext");
    }, []);

    // Auto-set context to the app using sessionStorage
    useEffect(() => {
        // autofetch user context in the sessionStorage
        const storedContext = JSON.parse(sessionStorage.getItem("contractorContext"));

        if (
            storedContext &&
            storedContext.contractorMainAccount &&
            storedContext.contractorCampus &&
            storedContext.contractorTeachersIds &&
            storedContext.contractorTeachers &&
            storedContext.contractorCurrentAccountYear &&
            storedContext.contractorIds
        ) {
            setContractorContext(
                storedContext.contractorMainAccount,
                storedContext.contractorCampus,
                storedContext.contractorTeachersIds,
                storedContext.contractorTeachers,
                storedContext.contractorCurrentAccountYear,
                storedContext.contractorIds
            );
        }
    }, [setContractorContext]);

    return {
        contractorMainAccount,
        contractorCampus,
        contractorTeachersIds,
        contractorTeachers,
        contractorCurrentAccountYear,
        contractorIds,
        setContractorContext,
        unsetContractorContext,
    };
};
