import React from "react";

// Components
import InvoiceHistoryListItem from "../InvoiceHistoryListItem/InvoiceHistoryListItem";
import { useWindowDimensions } from "../../../../../hooks/window-dimensions-hook";

// Styles
import styles from "./InvoiceHistoryList.module.scss";

const InvoiceHistoryList = ({ invoices }) => {
    
    // Window Size Hook
    const { width } = useWindowDimensions();

    let header;
    if (width > 667) {
        header = (
            <>
                <div className={styles.wrapper_subject}>
                    <h4 className={styles.header_item}>Numéro de facture</h4>
                </div>
                <div className={styles.wrapper_item}>
                    <h4 className={styles.header_item}>Intervenant</h4>
                </div>
                <div className={styles.wrapper_item}>
                    <h4 className={styles.header_item}>Campus</h4>
                </div>
                <div className={styles.wrapper_status}>
                    <h4 className={styles.header_item}>Statut</h4>
                </div>
                <div className={styles.wrapper_item}>
                    <h4 className={styles.header_item}>Date d'échéance</h4>
                </div>
                <div className={styles.wrapper_item}>
                    <h4 className={styles.header_item}>Montant</h4>
                </div>
            </>
        );
    } else {
        header = (
            <div className={styles.wrapper_subject_alt}>
                <h4 className={styles.header_item}>Numéro de facture</h4>
            </div>
        );
    }

    return (
        <section className={styles.container}>
            <div className={styles.header}>{header}</div>
            {invoices.map(
                ({ id, commitment_invoice_identifier, place_caption, name, due_date, status, amount }) => {
                    return (
                        <InvoiceHistoryListItem
                            key={id}
                            commitment_invoice_identifier={commitment_invoice_identifier}
                            name={name}
                            place_caption={place_caption}
                            due_date={due_date}
                            status={status}
                            amount={amount}
                        />
                    );
                }
            )}
        </section>
    );
};

export default InvoiceHistoryList;
