import { createContext } from "react";

export const StudentContext = createContext({
    studentStatus: null,
    studentRestricted: null,
    studentCampusId: null,
    studentCurrentPeriod: null,
    studentProfilePhoto: null,
    studentName: null,
    studentFinStatus: null,
    studentLevel: null,
    studentSchool: null,
    studentAttestation: null,
    studentCertificate: null,
    studentNextYearLevel: null,
    studentNextYearCampus: null,
    studentRythme: null,
    studentOffresRestriction: null,
    studentNotificationHome: null,
    setStudentContext: () => {},
    unsetStudentContext: () => {},
});
