import React, { useState } from "react";

// Components
import GradesListItem from "../GradesListItem/GradesListItem";

// Styles
import styles from "./GradesList.module.scss";

const GradesList = ({ grades }) => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.wrapper_title}>
                    <h4 className={styles.header_item}>Matière</h4>
                </div>
                <div className={styles.wrapper_eval}>
                    <h4 className={styles.header_item}>Evaluation</h4>
                </div>
                <div className={styles.wrapper_item}>
                    <h4 className={styles.header_item}>Note</h4>
                </div>
                <div className={styles.wrapper_item}>
                    <h4 className={styles.header_item}>Coef.</h4>
                </div>
            </div>
            {grades.map((grade) => {
                return (
                    <GradesListItem
                        key={grade.id}
                        course={grade.course}
                        date={grade.date}
                        campus={grade.place_caption}
                        grade={grade.value}
                        reference_value={grade.reference_value}
                        weight={grade.weight}
                        specific_weight={grade.specific_weight}
                        teacher={grade.teacher}
                        evaluation_type={grade.evaluation_type}
                        type={grade.type}
                        category={grade.category}
                        group_id={grade.group_id}
                        subject_entry={grade.subject_entry}
                    />
                );
            })}
        </div>
    );
};

export default GradesList;
