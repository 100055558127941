import React, { useContext } from "react";
import { AuthContext } from "../../context/auth-context";

// Components
import HeaderBanner from "../../components/HeaderBanner/HeaderBanner";
import PlanningCalendar from "../../components/Tabs/General/PlanningTabs/PlanningCalendar/PlanningCalendar";

// Icons
import scheduleIcon from "../../assets/images/calendar.svg";

// Styles
import styles from "./Planning.module.scss";

const Planning = () => {
    
    // Auth Context
    const auth = useContext(AuthContext);

    const title = auth.userType === "student" ? "MON EMPLOI DU TEMPS" : "PLANNING & ÉMARGEMENT"

    return (
        <>
            <main className={styles.container}>
                <HeaderBanner
                    title={title}
                    icon={scheduleIcon}
                    iconColor={styles.icon_color}
                    bgColor={styles.banner_color}
                />
                <PlanningCalendar />
            </main>
        </>
    );
};

export default Planning;
