import { createContext } from "react";

export const TeacherContext = createContext({
    teacherAccounts: null,
    teacherSelectedAccount: null,
    teacherPlaceId: null,
    teacherCampusId: null,
    teacherCanInvoice: null,
    teacherIsFreelancer: null,
    teacherVcard: null,
    teacherCurrentAccountYear: null,
    teacherCampuses: null,
    teacherMultipleCampuses: null,
    teacherCurrentMultipleCampuses: null,
    teacherName: null,
    switchTeacherAccount: () => {},
    setTeacherContext: () => {},
    setTeacherInvoiceDetails: () => {},
    unsetTeacherContext: () => {},
});