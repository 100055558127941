/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../context/auth-context";
import { useHttpRequest } from "../../../hooks/httpRequest-hook";

import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import styles from "./Timeline.module.scss";
import HttpMessagePrompt from "../../HttpMessagePrompt/HttpMessagePrompt";
import ActionBtn from "../../Buttons/ActionBtn/ActionBtn";
import DownloadDocBtn from "../../Buttons/DownloadDocBtn/DownloadDocBtn";

import downloadIcon from "../../../assets/images/file-pdf.svg";

const Timeline = (props) => {
  // Authentication context
  const auth = useContext(AuthContext);

  const { isLoading, error, sendRequest } = useHttpRequest();
  const [bddError] = useState(false);

  const [stringDoc, SetStringDoc] = useState();

  // App context
  const [commitments] = useState(props.commitments);
  const [terms, setTerms] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const [canPayByCard, setCanPayByCard] = useState(true);

  const [finalArray, setFinalArray] = useState(false);

  const timelineStatus = {
    expected: "Attendu OPCO",
    to_invoice: "À facturer",
    settled: "Attendu",
    regle: "Réglé",
    collected: "Encaissé",
    invoiced: "Facturé",
    facture_avoir: "Facturé Avoir",
    rejected: "À régulariser",
    start_proceedings: "Procédure Élève à Lancer",
    proceedings: "Procédure Élève",
    procedure_entreprise: "Procédure Entreprise",
    refund: "À rembourser",
    refunded: "Remboursé",
    remboursement_demande: "Remboursement demandé",
  };

  const timelineStatusForbidden = [
    "proceedings",
    "refund",
    "start_proceedings",
  ];

  const forbidenStatusForPaiement = ["collected", "invoiced", "regle"];

  // fonction qui va changer le statut attendu en réglé si le paiement de l'échéance est inf. à today -15.

  // Fonction qui permet d'ajouter un nombre x de jours à la date du jour.
  const todayDate = new Date().toISOString().slice(0, 10);

  const removeDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  };

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  function downloadDoc(doc, name) {
    // Create a link element
    const linkSource = `data:application/pdf;base64,${doc}`;

    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = linkSource;
    link.download = name;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  }

  // Fonction qui transforme les objets Commitment et Terms en array d'objets.
  const objectToArray = (obj) => {
    const newArray = Object.keys(obj).map((item) => {
      return obj[item];
    });
    return newArray;
  };

  const commitmentsToArray = objectToArray(commitments);

  // Code qui va extirper les id de chaque commitment
  let ids = [];
  commitmentsToArray.map((commitment) => {
    ids.push(commitment.id);
  });

  // Boucler sur les deux arrays pour affilier chaque term au bon commitment via le commitment_id
  const arrayTransform = (commitmentsToArray, termsToArray) => {
    let newArray;

    commitmentsToArray.forEach((item) => {
      item["terms"] = [];
      termsToArray.forEach((term) => {
        if (term.status === "settled" && term.collection_date <= todayDate) {
          term.status = "regle";
        }
        if (
          item.id == term.commitment_id &&
          term.invoice_account_id == 0 &&
          term.amount > 0
        ) {
          item["terms"][term.id] = term;
        }

        if (
          // On ajoute un jour à la date de collection et on lui retire 3 jours, (équivalent -2j +1j)
          (removeDays(addDays(term.collection_date, 1), 3).toISOString().slice(0, 10) <= todayDate &&
          term.amount > 0 && term.status != 'rejected' && !forbidenStatusForPaiement.includes(term.status))
        ) {
          setCanPayByCard(false);
        }
      });
    });

    // Fonction pour trier les terms sur "collection_date"
    commitmentsToArray.map((item) => {
      item.terms.sort((a, b) => {
        return Date.parse(a.collection_date) - Date.parse(b.collection_date);
      });
    });
    newArray = commitmentsToArray;
    setFinalArray(newArray);
    setIsReady(true);
  };

  let closeBtn = (
    <ActionBtn
      id="close_err"
      btnType="contained"
      btnStyle={styles.btnStyles}
      activeBtnStyle={styles.btn_active}
      btnText="Fermer la fenêtre"
      textStyle={styles.btn_text}
    />
  );

  // Effet de bord pour récupérer les terms avec les id de chaque commitment.
  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const termsUrl = `${
          process.env.REACT_APP_API_HOST
        }/df/dossier-financier/terms/${ids.join(",")}`;
        const termsFetch = await sendRequest(termsUrl, "GET", null, {
          Authorization: "Bearer " + auth.token,
        });
        // Formatage des terms qui nous retourne un tableau de terms.
        const termsToArray = objectToArray(termsFetch);

        // On envoit les array des commitment et des terms pour en faire un seul array.
        arrayTransform(commitmentsToArray, termsToArray);
        setTerms(termsToArray);
      } catch (err) {
        console.error(err);
      }
    };
    fetchTerms();
  }, []);

  // Fetch to download the link for the download of the invoice, called by the OnClick of the button.
  const fetchInvoice = async (commitmentId) => {
    try {
      const invoiceUrl = `${process.env.REACT_APP_API_HOST}/documents/generate-documents/download-invoice-rest?commitmentId=${commitmentId}`;
      const invoicesFetch = await sendRequest(invoiceUrl, "GET", null, {
        Authorization: "Bearer " + auth.token,
      });
      SetStringDoc(invoicesFetch);
    } catch (err) {
      console.error(err);
    }
  };

  // UseEffect for setting the invoice's name.
  useEffect(() => {
    if (stringDoc) downloadDoc(stringDoc, `facture-${"test"}.pdf`);
  }, [stringDoc]);

  // Fonction qui va appeler l'action Zend qui va générer le lien de paiement paytweak pour une échéance sélectionnée.
  const generatePaytweakLink = async (term) => {
    try {
      const paytweakLinkUrl = `${process.env.REACT_APP_API_HOST}/df/dossier-financier/paytweakLink/${term.id}`;
      // eslint-disable-next-line no-unused-vars
      const paytweakFetch = await sendRequest(paytweakLinkUrl, "GET", null, {
        Authorization: "Bearer " + auth.token,
      });
      if (paytweakFetch.url) window.location.href = paytweakFetch.url;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.timelineContainer}>
      {isLoading && (
        <div className="spinner">
          <LoadingSpinner textColor={styles.spinner_text_color} />
        </div>
      )}
      {(error || bddError) && (
        <HttpMessagePrompt
          error={
            "Une erreur a été détectée sur le serveur. Merci de réessayer ultèrieurement."
          }
          btn={closeBtn}
        />
      )}
      {!isLoading &&
        isReady &&
        finalArray &&
        finalArray.map((commitment) => {
          return (
            <div
              className={styles.timelineCaptionContainer}
              key={commitment.id}
            >
              <p className={styles.timelineCaption}>
                ÉCHÉANCIER ANNÉE {commitment.caption}
              </p>

              {/* Button for downloading the invoice  */}
              <DownloadDocBtn
                style={styles.downloadBtn}
                iconStyle={styles.downloadIcon}
                type="link"
                text="Télécharger au format PDF"
                link={`${process.env.REACT_APP_API_HOST}/documents/generate-documents/download-invoice-rest?commitmentId=${commitment.id}`}
                icon={downloadIcon}
                iconColor={styles.iconColor}
              />

              {/* Maping on terms to display them  */}
              {commitment.terms &&
                commitment.terms.map((term) => {
                  return (
                    <>
                      {/* TERM SECTION */}
                      {!timelineStatusForbidden.includes(term.status) &&
                        isReady && (
                          <div className={styles.termContainer} key={term.id}>
                            {/* TERM SENTENCE  */}
                            <p
                              className={
                                term.status === "rejected"
                                  ? styles.timelineRejected
                                  : styles.timelineTerm
                              }
                            >
                              {term.caption} de{" "}
                              <strong>
                                {(term.amount * 100) / 100}€
                              </strong>{" "}
                              pour le{" "}
                              {new Date(
                                term.collection_date
                              ).toLocaleDateString("fr")}{" "}
                              | Statut:{" "}
                              <strong>{timelineStatus[term.status]}</strong>
                            </p>

                            {/* PAYTWEAK BUTTON SECTION AND RULES  */}
                            {((canPayByCard && !forbidenStatusForPaiement.includes(term.status)) || term.status == 'rejected') && term.amount > 0
                               && (
                                <ActionBtn
                                  btnStyle={
                                    term.status == "rejected"
                                      ? styles.termPaytweakLinkRejected
                                      : styles.termPaytweakLink
                                  }
                                  id="paytweakLink"
                                  btnType="contained"
                                  btnText="RÉGLER PAR CB"
                                  onClick={() => {
                                    generatePaytweakLink(term);
                                  }}
                                />
                              )}
                          </div>
                        )}
                    </>
                  );
                })}
            </div>
          );
        })}
    </div>
  );
};

export default Timeline;
