import React from "react";
import { Link } from "react-router-dom";

// Styles
import styles from "./DocLinkBanner.module.scss";

const LinkBanner = ({ title, link, iconColor, icon, bgColor }) => {
    return (
        <Link to={link} className={styles.link}>
            <div className={`${styles.container} ${bgColor}`}>
                <img className={`${styles.icon} ${iconColor}`} src={icon} alt="" />
                <h4 className={styles.text}>{title}</h4>
            </div>
        </Link>
    );
};

export default LinkBanner;
