import React from "react";

// Components
import CollapsibleItem from "../../components/CollapsibleItem/CollapsibleItem";

// Styles
import styles from "./CollapsibleList.module.scss";

const CollapsibleList = ({ CollapsibleList }) => {
    return (
        <div className={styles.container}>
            {CollapsibleList.map((item, index) => {
                return (
                    <CollapsibleItem
                        key={index}
                        status={item.status}
                        title={item.title}
                        description={item.description}
                        hashtag={item.hashtag}
                    />
                );
            })}
        </div>
    );
};

export default CollapsibleList;
