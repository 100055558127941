import React, { useState } from "react";

// Components
import CheckBox from "../../../../CheckBox/CheckBox";
import TagLabel from "../../../../TagLabel/TagLabel";

// Styles
import styles from "./InvoiceListItem.module.scss";

const InvoiceListItem = (props) => {

    // Checkbox state
    const [isChecked, setIsChecked] = useState(false);

    let selfEmployedItems;
    let labelsContainer = styles.labels_container_alt;

    if (props.selfEmployed) {
        labelsContainer = styles.labels_container_alt;
        selfEmployedItems = (
            <>
                <p className={styles.text}>{props.item.rate} €/h</p>
                <p className={styles.text}>{parseFloat(props.item.total / 100).toFixed(2)} €</p>
            </>
        );
    }

    let itemType;
    if (props.item.status === "invoiced") {
        itemType = (
            <section className={styles.container}>
                <p className={styles.name}>{props.item.title}</p>
                <div className={labelsContainer}>
                    <p className={styles.text}>{props.item.date}</p>
                    <p className={styles.text}>{props.item.hourLabel}</p>
                    <TagLabel 
                        label={props.status}
                        bgColor={styles.tagBgColor}
                        textColor={styles.tagTextColor}
                    />
                </div>
            </section>
        );
    } else {
        itemType = (
            <section className={styles.container}>
                <CheckBox
                    id={props.id}
                    key={props.id}
                    name={props.item.title}
                    isChecked={isChecked}
                    checkboxValue={props.item.composed_id}
                    onChange={(event) => {
                        setIsChecked(!isChecked);
                        props.onChange(event, !isChecked);
                    }}
                />
                <p className={styles.name}>{props.item.title}</p>
                <p className={styles.name}>{props.item.teacherName}</p>
                <div className={labelsContainer}>
                    <p className={styles.text}>{props.item.date}</p>
                    <p className={styles.text}>{props.item.hourLabel}</p>
                    <p className={styles.text}>{props.item.durationLabel}</p>
                    {selfEmployedItems}
                </div>
            </section>
        );
    }

    return itemType;
};

export default InvoiceListItem;
