import React from "react";
import { Link } from "react-router-dom";

// Icons
import arrowRightIcon from "../../assets/images/chevron-right-solid.svg"
import menuIcon from "../../assets/images/th-solid.svg"

// Styles
import styles from "./HeaderBanner.module.scss";

const HeaderBanner = (props) => {
    return (
        <header className={`${styles.container} ${props.bgColor}`}>
            <img className={`${styles.icon} ${props.iconColor}`} src={props.icon} alt="" />
            <h3 className={styles.title}>{props.title}</h3>
            <Link to="/" className={styles.btn}>
                <img className={styles.btn_icon} src={arrowRightIcon} alt="" />
                <img className={styles.btn_icon} src={menuIcon} alt="" />
            </Link>
        </header>
    );
};

export default HeaderBanner;