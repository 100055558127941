import React, { useState, useContext, useEffect } from "react";
import { useHttpRequest } from "../../../../../hooks/httpRequest-hook";
import { AuthContext } from "../../../../../context/auth-context";
import { TeacherContext } from "../../../../../context/teacher-context";
import { ContractorContext } from "../../../../../context/contractor-context";

// Components
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import HttpMessagePrompt from "../../../../HttpMessagePrompt/HttpMessagePrompt";
import ListHeader from "../../../../ListHeader/ListHeader";
import InvoiceHistoryList from "../InvoiceIHistoryList/InvoiceHistoryList";

// Styles
import styles from "./InvoiceHistory.module.scss";

const InvoiceHistory = () => {
    // Auth Context
    const auth = useContext(AuthContext);

    // App context
    const context = useContext(TeacherContext);

    const contractorContext = useContext(ContractorContext)


    // Backend Request Hook
    const { isLoading, error, sendRequest } = useHttpRequest();

    const [invoices, setInvoices] = useState([]);

    useEffect(() => {
        const fetchInvoiceHistory = async () => {
        let accountId, currentYear;
        if ( auth.userType === "contractor") {
            accountId = contractorContext.contractorMainAccount;
            currentYear = contractorContext.contractorCurrentAccountYear;
        } else {
            accountId = context.teacherSelectedAccount;
            currentYear = context.teacherCurrentAccountYear;
        }

        const url = `${process.env.REACT_APP_API_HOST}/invoicing/invoice-history?&account_id=${accountId}&current_year=${currentYear}`;
            try {
                const events = await sendRequest(url, "GET", null, {
                    Authorization: "Bearer " + auth.token,
                });
                setInvoices(Object.values(events).sort((firstItem, secondItem) => firstItem.due_date < secondItem.due_date));
            } catch (err) {
                console.error(err);
            }
        }
        fetchInvoiceHistory();
    },[])

    return(<>
        {isLoading && (
            <div className="spinner">
                <LoadingSpinner />
            </div>
        )}
        {error && (
            <div className={styles.subheader_wrapper}>
                <HttpMessagePrompt error={error} />
            </div>
        )}
        {!isLoading && !error && invoices && invoices.length === 0 && (
                <div className={styles.subheader_wrapper}>
                    <HttpMessagePrompt error={false} message="Vous n'avez pas de facture!" />
                </div>
            )}
            {!isLoading && !error && invoices && invoices.length !== 0 && (
        <div className={styles.subheader_wrapper}>
            <div className={styles.pargraph_text}> 
                <p>Les paiements peuvent être effectifs entre 3 à 5 jours ouvrés après la date de virement, dû aux délais de traitements bancaires. Merci de votre compréhension.</p>
            </div>
            <ListHeader title="Votre historique de factures" />
            <InvoiceHistoryList invoices={invoices} />
        </div>
        )}
        </>
    )
}

export default InvoiceHistory;
