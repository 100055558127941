import React, { useState, useEffect, useContext } from "react";
import { useHttpRequest } from "../../../hooks/httpRequest-hook";
import { AuthContext } from "../../../context/auth-context";
import { downloadBase64Document } from "../../../utils/helperFunctions";
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
//import warningIcon from "../../assets/images/warning.svg";
// Components
import InlineLoadingSpinner from "../../LoadingSpinner/InlineLoadingSpinner";

// Styles
import styles from "./DownloadDocBtn.module.scss";

const DownloadDocBtn = ({type, text, link, iconColor, icon, bgColor, style }) => {

    // Authentication context
    const auth = useContext(AuthContext);
    // HTTP Request Hook
    const { isLoading, sendRequest } = useHttpRequest();
    const history = useHistory();
    // Base64 downloaded document

    const [document, setDocument] = useState();
    const [count, setCount] = useState(1);
    const arrayLink = link.split("/");
    const documentName = arrayLink.slice(-1);
    const TypeOfComponent = documentName[0].split("_")[0];

    const getDocument = async () => {
        if (document) {
            downloadBase64Document(document.file, document.name, document.contentType);
        } else {
            const { file, name, contentType } = await sendRequest(link, "GET", null, {
                Authorization: "Bearer " + auth.token,
            });
            // Set Document
            setDocument({ file, name, contentType });
        }
    };

    const deverouiller = async (event) => {
        event.stopPropagation();
        setCount(0);
        const link_deverouiller = `${process.env.REACT_APP_API_HOST}/documents/generate-document/${documentName}`;
        try { 
                await sendRequest(link_deverouiller, "POST", null, {
                Authorization: "Bearer " + auth.token,
            });
          
            history.push("/");
            history.push("/careers");
        } catch(e) {
            console.error(e);
            toast.error("Un problème est survenu lors de déverouillage du formulaire.", {
                position: "top-right",
                autoClose: 4500,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
        }
    };
  

    useEffect(() => {
        if (document) downloadBase64Document(document.file, document.name, document.contentType);
    }, [document]);

    const spinner = (
        <div className="spinner">
            <InlineLoadingSpinner />
        </div>
    );
    if (type === "banner")
        return (
            <div>
                <ToastContainer />
            <button
                className={`${styles.banner_btn} ${styles.btn_alt}`}
                type="button"
                onClick={getDocument}
            >
                <div className={styles.banner_btn_wrapper}>
                    <img className={`${styles.banner_btn_icon} ${iconColor}`} src={icon} alt="" />
                    <h4 className={styles.banner_btn_text}>{text}</h4>
                    {isLoading && spinner}
                    {count == 1  && TypeOfComponent == "support" && auth.userRole == 'admin' &&
                        <h3  className={styles.btn_deverouiller}  onClick={deverouiller}>Déverrouiller </h3>
                    }
                </div>
            </button>
           </div> 
        );
   
    else
        return (
            <button className={style ? style : styles.link_btn} type="button" onClick={getDocument}>
                <div className={styles.link_btn_wrapper}>
                    <img className={`${styles.link_btn_icon} ${iconColor}`} src={icon} alt="" />
                    <p className={styles.link_btn_text}>{text}</p>
                    {isLoading && spinner}
                </div>
            </button>
        );
};

export default DownloadDocBtn;
