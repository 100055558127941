import React from "react";

import styles from "./BiblioCard.module.scss";

const BiblioCard = (props) => {
  return (
    <div 
        className={props.style}
    >
      <img src={props.img} alt="" className={styles.img} />
      <p onClick={props.onClick} className={styles.pCard}>{props.text}</p>
    </div>
  );
};

export default BiblioCard;
